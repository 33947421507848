import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IDisplaySequence {
  type: string
  name: string
  percentageBase: string
  isGoodIfLessThanBudget: boolean
  isGoodIfLessThanLastYear: boolean
}

export interface IPlConfig {
  budgetPercentageVarianceType?: string
  yoyPercentageVarianceType?: string
  summaries: {
    name: string
    positiveCategorySections?: string[]
    negativeCategorySections?: string[]
  }[]
  displaySequence: IDisplaySequence[]
}

const usePlConfig = (): IPlConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'rmg': // RMG
      case 'rmg_nso': // RMG NSO
      case 'sullivans': // Sullivan's
      case 'sullivans_nso': // Sullivan's NSO
      case 'eddiemerlots': // Eddie Merlot's
        return {
          budgetPercentageVarianceType: 'DRG',
          yoyPercentageVarianceType: 'DRG',
          summaries: [
            {
              name: 'Gross Sales',
              positiveCategorySections: ['Gross Sales'],
            },
            {
              name: 'Net Sales',
              positiveCategorySections: ['Gross Sales', 'Comps'],
            },
            {
              name: 'Restaurant-Controllable Profit (RCP)',
              positiveCategorySections: ['Gross Sales', 'Comps'],
              negativeCategorySections: [
                'Cost of Goods Sold',
                'Cost of Labor',
                'Contollable Expenses',
              ],
            },
          ],
          displaySequence: [
            {
              type: 'categorySection',
              name: 'Gross Sales',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
            {
              type: 'categorySection',
              name: 'Comps',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
            {
              type: 'summary',
              name: 'Net Sales',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
            {
              type: 'categorySection',
              name: 'Cost of Goods Sold',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: true,
              isGoodIfLessThanLastYear: true,
            },
            {
              type: 'categorySection',
              name: 'Cost of Labor',
              percentageBase: 'Net Sales',
              isGoodIfLessThanBudget: true,
              isGoodIfLessThanLastYear: true,
            },
            {
              type: 'categorySection',
              name: 'Contollable Expenses',
              percentageBase: 'Net Sales',
              isGoodIfLessThanBudget: true,
              isGoodIfLessThanLastYear: true,
            },
            {
              type: 'summary',
              name: 'Restaurant-Controllable Profit (RCP)',
              percentageBase: 'Net Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
          ],
        }
      default:
        return {
          summaries: [
            {
              name: 'Gross Sales',
              positiveCategorySections: ['Gross Sales'],
            },
            {
              name: 'Net Sales',
              positiveCategorySections: ['Gross Sales', 'Comps'],
            },
            {
              name: 'Restaurant-Controllable Profit (RCP)',
              positiveCategorySections: ['Gross Sales', 'Comps'],
              negativeCategorySections: [
                'Cost of Goods Sold',
                'Cost of Labor',
                'Contollable Expenses',
              ],
            },
          ],
          displaySequence: [
            {
              type: 'categorySection',
              name: 'Gross Sales',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
            {
              type: 'categorySection',
              name: 'Comps',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
            {
              type: 'summary',
              name: 'Net Sales',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
            {
              type: 'categorySection',
              name: 'Cost of Goods Sold',
              percentageBase: 'Gross Sales',
              isGoodIfLessThanBudget: true,
              isGoodIfLessThanLastYear: true,
            },
            {
              type: 'categorySection',
              name: 'Cost of Labor',
              percentageBase: 'Net Sales',
              isGoodIfLessThanBudget: true,
              isGoodIfLessThanLastYear: true,
            },
            {
              type: 'categorySection',
              name: 'Contollable Expenses',
              percentageBase: 'Net Sales',
              isGoodIfLessThanBudget: true,
              isGoodIfLessThanLastYear: true,
            },
            {
              type: 'summary',
              name: 'Restaurant-Controllable Profit (RCP)',
              percentageBase: 'Net Sales',
              isGoodIfLessThanBudget: false,
              isGoodIfLessThanLastYear: false,
            },
          ],
        }
    }
  }, [brand])
}

export default usePlConfig
