import {
  Chart as BaseChart,
  ChartType as BaseChartType,
  ChartDataset,
} from 'chart.js'
import * as _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import ChartComponent, { IPropsType as IChartsPropsType } from 'pared/charts'
import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../variables'
import useApi, { IApiKeyType, configs } from './hooks/useApi'

interface IBaseDatasetType {
  value?: unknown
}

type IDatasetType =
  | (IBaseDatasetType & {
      type: 'price'
      decimal?: number
    })
  | (IBaseDatasetType & {
      type: 'string'
    })
  | (IBaseDatasetType & {
      type: 'percent'
      decimal?: number
    })

type IMixedDatasetType<
  V extends Record<string, Pick<IDatasetType, 'type'>['type']>,
> = {
  [K in keyof V]: Omit<
    Extract<
      IDatasetType,
      {
        type: V[K]
      }
    >,
    'type' | 'value'
  > & {
    key: K
    label?: string
    chartType?: BaseChartType
    xAxisID?: string
    yAxisID?: string
    borderColor?: string
    backgroundColor?: string
    order?: number
  }
}

type IDatasetsType = {
  [K in keyof typeof configs]: IMixedDatasetType<typeof configs[K]>
}

export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends Omit<IChartsPropsType, 'type' | 'data'> {
  type: `${IChartsPropsType['type']}-chart`
  api: K
  label: keyof typeof configs[K]
  datasets: IDatasetsType[K][keyof IDatasetsType[K]][]
  scaleTypes: Record<string, IDatasetType>
  margin?: string
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const format = ({ value, ...data }: IDatasetType) => {
  switch (data.type) {
    case 'price':
      return toUsdString(parseFloat(value as string) / 100, data.decimal)
    case 'percent':
      return toPercentString(parseFloat(value as string), data.decimal)
    default:
      return ''
  }
}

const Root = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`

const useDatasets = <K extends IApiKeyType>(
  datasets: IDatasetsType[K][keyof IDatasetsType[K]][],
) => {
  const { template } = useVariables()

  return useMemo(
    () =>
      datasets.reduce((result, dataset) => {
        const key: IDatasetsType[K][keyof IDatasetsType[K]][] =
          template(dataset.key as string) || ''

        if (key instanceof Array)
          return [
            ...result,
            ...key.map((k) => ({
              ...dataset,
              ...k,
              configKey: dataset.key,
            })),
          ]

        return [...result, dataset]
      }, [] as IDatasetsType[K][keyof IDatasetsType[K]][]),
    [datasets, template],
  )
}

const Chart = ({
  type,
  api,
  label,
  datasets: originDatasets,
  scaleTypes,
  margin = '20px 0px 0px',
  ...props
}: IPropsType) => {
  const { data, loading } = useApi(api)
  const datasets = useDatasets(originDatasets)
  const chartConfigs = useMemo(() => {
    const config = configs[api]
    const maxLabelAmount = [...(data || [])]
      .reverse()
      .findIndex((d) =>
        datasets.some(
          ({ key }) =>
            ![null, undefined].includes(_.get(d, key) as null | undefined),
        ),
      )
    const filteredData = data?.slice(0, data.length - maxLabelAmount)

    return {
      data: {
        labels: filteredData?.map((d) => _.get(d, label)),
        datasets: datasets.map(
          (
            {
              key,
              borderColor,
              backgroundColor,
              chartType,
              ...dataset
            }: { key: keyof typeof config; chartType?: BaseChartType } & Pick<
              ChartDataset,
              'borderColor' | 'backgroundColor'
            >,
            index,
          ) => ({
            ...dataset,
            type: chartType,
            data: filteredData?.map((d) => _.get(d, key)) || [],
            borderColor: borderColor || COLORS.STACKED_BAR_COLOR_HUE[index],
            backgroundColor:
              backgroundColor || COLORS.STACKED_BAR_COLOR_HUE[index],
          }),
        ) as ChartDataset[],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: ({
                dataset: { label },
                chart,
                datasetIndex,
                dataIndex,
                formattedValue,
              }: {
                dataset: { label?: string }
                chart: BaseChart
                datasetIndex: number
                dataIndex: number
                formattedValue: string
              }) => {
                const dataset = datasets[datasetIndex] as IDatasetType & {
                  configKey?: keyof typeof config
                  key: keyof typeof config
                }
                const value = chart.data.datasets[datasetIndex].data[dataIndex]

                return `${label}: ${
                  format({
                    ...dataset,
                    type: config[dataset.configKey || dataset.key],
                    value,
                  }) || formattedValue
                }`
              },
            },
            itemSort: (
              a: { datasetIndex: number },
              b: { datasetIndex: number },
            ) => a.datasetIndex - b.datasetIndex,
          },
          legend: {
            labels: {
              sort: (
                a: { datasetIndex: number },
                b: { datasetIndex: number },
              ) => a.datasetIndex - b.datasetIndex,
            },
          },
        },
        scales:
          scaleTypes &&
          Object.keys(scaleTypes).reduce(
            (result, key) => ({
              ...result,
              [key]: {
                ticks: {
                  callback: (value: unknown) =>
                    format({
                      ...scaleTypes[key],
                      value,
                    }),
                },
              },
            }),
            {},
          ),
      },
    }
  }, [configs, api, data, label, datasets, scaleTypes])

  return (
    <Root margin={margin}>
      <Spin spinning={loading}>
        <ChartComponent
          {..._.merge({}, chartConfigs, props)}
          type={type.replace(/-chart/, '') as IChartsPropsType['type']}
        />
      </Spin>
    </Root>
  )
}

export default Chart
