import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'

const query = gql`
  query systemwideOverviewKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $iInputParams: JSON!
    $useCustom: Boolean!
  ) {
    summaryLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        netSales
        netSalesLy: yoyNetSales
        netSalesBudgetVariance
        netSalesSssPercent
        grossSales
        checkCount
        checkCountLy: yoyTotalCheckCount
        checkAverage
        checkAverageLy: yoyCheckAverage
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogsPercent
        cogsBudgetVariance
        avtPercent
        rcpPercent
      }
    }

    getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_GROUP_FINANCIAL_KPIS"
      iInputParams: $iInputParams
    ) @include(if: $useCustom) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const systemwideOverviewKpisConfigs = {
  netSales: 'price',
  netSalesLy: 'price',
  netSalesBudgetVariance: 'percent',
  netSalesSssPercent: 'percent',
  grossSales: 'price',
  checkCount: 'number',
  checkCountLy: 'number',
  checkCountVsLyPercent: 'percent',
  checkAverage: 'price',
  checkAverageLy: 'price',
  checkAverageLyPercent: 'percent',
  hourlyLaborCostPercent: 'percent',
  hourlyLaborBudgetVariance: 'percent',
  cogsPercent: 'percent',
  cogsBudgetVariance: 'percent',
  avtPercent: 'percent',
  rcpPercent: 'percent',

  // customized bibibop
  fAndPPercent: 'percent',
  laborPercent: 'percent',
  fplPercent: 'percent',
  ebitdaBeforeBonusPercent: 'percent',
  ebitdaAfterBonusPercent: 'percent',
  grossProfitPercent: 'percent',
  restaurantLevelProfitPercent: 'percent',

  // customized mwb
  foodCost: 'percent',
  paperCost: 'percent',
  variableLaborCost: 'percent',
  primeCost: 'percent',
  avtFbDollars: 'price',
  avtFbPercent: 'percent',
  avtPaperDollars: 'price',
  avtPaperPercent: 'percent',
} as const

const USE_CUSTOM = ['bibibop', 'mwb']

const useSystemwideOverviewKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
      iInputParams: {
        startDate: startDate,
        endDate: endDate,
        locationGroupIds: groupFilter?.ids,
      },
      useCustom: USE_CUSTOM.includes(brand),
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const kpis = {
        ...(data?.summaryLocationGroupFinancialKpis.nodes[0] || {}),
      }
      const netSalesLy = parseInt(kpis.netSalesLy, 10)
      const checkCount = parseInt(kpis.checkCount, 10)
      const checkCountLy = parseInt(kpis.checkCountLy, 10)
      const customizedData = data?.getCustomizedReport?.nodes[0]?.reportResult
        ?.tableData?.[0] as Record<string, unknown> | undefined

      if (checkCount && checkCountLy)
        kpis.checkCountVsLyPercent =
          (100.0 * (checkCount - checkCountLy)) / checkCountLy

      if (netSalesLy && checkCountLy) {
        if (kpis.checkAverage) {
          kpis.checkAverageLyPercent =
            (100.0 * (kpis.checkAverage - kpis.checkAverageLy)) /
            kpis.checkAverage
        }
      }

      if (Object.keys(kpis).length === 0) return null

      return { ...kpis, ...customizedData }
    }, [data]),
    loading,
  }
}

export default useSystemwideOverviewKpis
