import { useMemo } from 'react'

import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useGroupFilter } from '../../../groupFilter'
import { IPropsType as ILinkPropsType } from '../../../tableV2/Link'

export interface IDataType {
  corporateGroup?: {
    tableColumns:
      | [
          {
            key: 'location'
            title: string
            onClick: 'track.locationClicked'
          },
          {
            key: 'groupBy'
            title: string
            onClick: 'track.groupByClicked'
          },
        ]
      | [
          {
            key: 'locationGroup'
            title: string
          },
        ]
    locationGroupIds: number[]
    locations?: Record<
      number,
      {
        id: number
        displayName: string
        link: string
        groupBy?: {
          id: number
          displayName: string
          link?: string
        }
        tableRow: {
          location: ILinkPropsType & { id: number }
          groupBy: ILinkPropsType & { id: number }
        }
      }
    >
    locationGroups?: Record<
      number,
      {
        id: number
        displayName: string
        link: string
        tableRow: {
          locationGroup: ILinkPropsType
        }
      }
    >
  }
}

export const tableConfigs = {
  '<%- JSON(corporateGroup?.tableColumns) %>': 'link',
} as const

const WIDTHS = {
  bibibop: ['250px', '150px', '250px'],
} as Record<string, [string, string, string]>

const useCorporateGroup = (): IDataType => {
  const { hasGroupBy, groupFilter } = useGroupFilter()
  const groupByLabel = getBusinessLabel('director')
  const { brand } = useBrands()

  return {
    corporateGroup: useMemo(() => {
      if (!groupFilter) return

      return {
        tableColumns: hasGroupBy
          ? [
              {
                key: 'location',
                title: 'Stores',
                onClick: 'track.locationClicked',
                width: WIDTHS?.[brand]?.[0] || '200px',
              },
              {
                key: 'groupBy',
                title: groupByLabel,
                onClick: 'track.groupByClicked',
                width: WIDTHS?.[brand]?.[1] || '200px',
              },
            ]
          : [
              {
                key: 'locationGroup',
                title: groupFilter?.list?.[0]?.header || 'Group Name',
                width: WIDTHS?.[brand]?.[2] || '200px',
              },
            ],
        locationGroupIds: groupFilter.ids,
        locations:
          !hasGroupBy || !groupFilter.list
            ? undefined
            : groupFilter.list.reduce(
                (result, item) => ({
                  ...result,
                  [item.id]: {
                    id: item.id,
                    displayName: item.name,
                    link: item.link,
                    groupBy: item.groupBy && {
                      id: item.groupBy.id,
                      displayName: item.groupBy.name,
                      link: item.groupBy.link,
                    },
                    tableRow: {
                      location: {
                        id: item.id,
                        link: item.link,
                        label: item.name,
                      },
                      groupBy: item.groupBy && {
                        id: item.groupBy.id,
                        link: item.groupBy.link,
                        label: item.groupBy.name,
                      },
                    },
                  },
                }),
                {},
              ),
        locationGroups:
          hasGroupBy || !groupFilter.list
            ? undefined
            : groupFilter.list.reduce(
                (result, item) => ({
                  ...result,
                  [item.id]: {
                    id: item.id,
                    displayName: item.name,
                    link: item.link,
                    tableRow: {
                      locationGroup: {
                        id: item.id,
                        link: item.link,
                        label: item.name,
                      },
                    },
                  },
                }),
                {},
              ),
      }
    }, [hasGroupBy, groupFilter, groupByLabel, brand]),
  }
}

export default useCorporateGroup
