import useLastTwentyEightDaysTrendData, {
  fwWingstopLastTwentyEightDaysTrendDataConfigs,
} from './useLastTwentyEightDaysTrendData'
import useTimeClock, { fwWingstopTimeClockConfigs } from './useTimeClock'

export const configs = {
  fwWingstopTimeClock: fwWingstopTimeClockConfigs,
  fwWingstopLastTwentyEightDaysTrendData:
    fwWingstopLastTwentyEightDaysTrendDataConfigs,
}

export const api = {
  fwWingstopTimeClock: useTimeClock,
  fwWingstopLastTwentyEightDaysTrendData: useLastTwentyEightDaysTrendData,
}
