import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getDateRangeLabelV2 } from 'pared/utils/date'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

type IDataType<
  T extends string = 'summaryAvgWeeklySales' | 'trendSummaryAvgWeeklySales',
> = Record<
  T,
  {
    nodes: ((T extends 'summaryAvgWeeklySales'
      ? {
          startDate: string
          endDate: string
          groupByStartDate: string
          groupByEndDate: string
          businessWeek: string
          businessWeekOfMonth: string
          businessMonth: string
          businessQuarter: string
          businessYear: string
        }
      : {}) & {
      avgWeeklySales?: number | null
      annualizedAvgWeeklySales?: number | null
    })[]
  }
>

const query = gql`
  query bbbWeeklySales($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    summaryAvgWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        avgWeeklySales
        annualizedAvgWeeklySales
      }
    }

    trendSummaryAvgWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        groupByStartDate: startDate
        groupByEndDate: endDate
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
        avgWeeklySales
        annualizedAvgWeeklySales
      }
    }
  }
`

export const bbbWeeklySalesConfigs = {
  date: 'date',
  avgAuv: 'price',
  auv: 'price',
} as const

const useBbbWeeklySales = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const totalAnnualizedAvgWeeklySales =
        data.summaryAvgWeeklySales.nodes[0]?.annualizedAvgWeeklySales
      const startIndex = data.trendSummaryAvgWeeklySales.nodes.findIndex(
        (d) =>
          ![null, undefined].includes(d.avgWeeklySales as null | undefined),
      )
      const endIndex =
        data.trendSummaryAvgWeeklySales.nodes.length -
        [...data.trendSummaryAvgWeeklySales.nodes]
          .reverse()
          .findIndex(
            (d) =>
              ![null, undefined].includes(d.avgWeeklySales as null | undefined),
          )

      return data.trendSummaryAvgWeeklySales.nodes.map(
        ({ annualizedAvgWeeklySales, ...d }, index) => ({
          date:
            'startDate' in d ? getDateRangeLabelV2('business_week', d) : null,
          auv: annualizedAvgWeeklySales,
          avgAuv:
            index >= startIndex && index < endIndex
              ? totalAnnualizedAvgWeeklySales
              : null,
        }),
      )
    }, [data]),
    loading: loading,
  }
}

export default useBbbWeeklySales
