import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrandSettings } from 'pared/customer'
import getDateRanges, {
  GROUP_BY_LAST_X_DAYS,
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import {
  toFormattedInteger,
  toHourStr,
  toPercentString,
  toPercentageString,
  toUsdStr,
  toUsdString,
} from 'pared/utils/number'
import { getUser } from 'pared/utils/user'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  CUSTOMIZED_TREND_LOCATION_FINANCIAL_KPIS,
  LIST_LOCATION_COMPS_BREAKDOWN,
  LIST_LOCATION_DAYPART_BREAKDOWN,
  LIST_LOCATION_FINANCIAL_KPIS,
  LIST_LOCATION_KPI_RANKINGS,
  LIST_LOCATION_REV_CENTER_BREAKDOWN,
  LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE,
  TREND_LOCATION_FINANCIAL_KPIS,
} from './gql'
import useConfig from './hooks/useConfig'

export interface IActionItems {
  id: string
  isFinished: boolean
  detail: string
}

export interface IManagerReview {
  id: number
  feedback: string
  performanceName: string
  formattedReviewedAt: string
  reviewedAt: string
  reviewer: {
    id: number
    firstName: string
    lastName: string
  }
}

export interface IRawManagerReview {
  revieweeId: string
  feedback: string
  performanceName: string
  reviewedAt: string
  reviewer: {
    id: number
    firstName: string
    lastName: string
  }
}

export interface ITeamMember {
  employeeId: number
  firstName?: string
  lastName?: string
  roleName?: string
  roleEmploymentType?: string
  timeInPosition?: string
  tenure?: string
  managerReviews?: IManagerReview[]
}

export interface IUnfilledPosition {
  id: string
  title: string
  vacantDuration?: string
  lastAdPostedAt?: string
}

interface IEmployee {
  id: number
  firstName: string
  lastName: string
}

interface IStoreGroupedData {
  groupByStartDate: moment.Moment
  groupByEndDate: moment.Moment
  businessWeek: number
  businessMonth: number
  businessQuarter: number
  netSales: string
  grossSales: string
  cogs: string
  cogsPercentage: string
  avtPercent: string
  avtPercentMonthly: string
  avtPercentWeekly: string
  plvPercent: string
  rcp: string
  rcpPercentage: string
  hourlyLaborActual: string
  hourlyLaborPercentage: string
  netSalesBudget?: string
  netSalesBudgetVarianceAmount?: string
  netSalesBudgetVarianceAmountHighlightCode?: string
  netSalesProjection?: string
  netSalesProjectionVarianceAmount?: string
  netSalesProjectionVarianceAmountHighlightCode?: string
  grossSalesBudget?: string
  grossSalesBudgetVarianceAmount?: string
  grossSalesBudgetVarianceAmountHighlightCode?: string
  hourlyLaborCostBudget?: string
  hourlyLaborBudgetVarianceAmount?: string
  hourlyLaborBudgetVarianceAmountHighlightCode?: string
}

export interface ICompsBreakdown {
  compsName: string
  compsAmount: string
  compsCount: string
  compsPercentage: string
}

export interface IStoreData {
  locationId: number

  netSalesSssRank: string
  grossSalesSssRank: string
  rcpRank: string
  hourlyLaborRank: string

  netSales: string
  grossSales: string
  cogs: string
  cogsPercentage: string
  periodCogs: string
  periodCogsPercentage: string
  periodCogsBudget: string
  periodCogsBudgetPercentage: string
  netSalesSssPercent: string
  grossSalesSssPercent: string
  hourlyLaborActual: string
  laborPercentage: string
  avtPercent: string
  avtPercentMonthly: string
  plvPercent: string
  rcp: string
  rcpPercentage: string
  checkCount: string
  checkCountLy: string
  checkAvg: string
  checkCountVsLyPercent: string
  sosAvgSeconds: string

  netSalesBudget: string
  netSalesBudgetVariance: string
  netSalesProjection: string
  netSalesProjectionVariance: string
  grossSalesBudget: string
  grossSalesBudgetVariance: string
  cogsBudget: string
  cogsBudgetPercentage: string
  avtBudgetPercentage: string
  plvBudgetPercentage: string
  rcpBudget: string
  rcpBudgetPercentage: string
  hourlyLaborBudget: string
  hourlyLaborBudgetPercentage: string

  netSalesBudgetToDate: string
  netSalesProjectionToDate: string
  grossSalesBudgetToDate: string
  cogsBudgetToDate: string
  rcpBudgetToDate: string

  groupedData: IStoreGroupedData[]
  groupedDataDaily: IStoreGroupedData[]

  ptdActualBudgetDifference: {
    netSales: string
    netSalesHighlightCode: string
    grossSales: string
    grossSalesHighlightCode: string
    cogs: string
    cogsHighlightCode: string
    avtPercent: string
    avtPercentHighlightCode: string
    avtPercentMonthly: string
    avtPercentMonthlyHighlightCode: string
    plvPercent: string
    plvPercentHighlightCode: string
    rcp: string
    rcpHighlightCode: string
    hourlyLabor: string
    hourlyLaborHighlightCode: string
    cogsPercent: string
    cogsPercentHighlightCode: string
    hourlyLaborPercent: string
    hourlyLaborPercentHighlightCode: string
    rcpPercent: string
    rcpPercentHighlightCode: string
  }

  ptdActualProjectionDifference: {
    netSales: string
    netSalesHighlightCode: string
  }

  compsBreakdown: ICompsBreakdown[]

  overtime: string

  actionItems?: IActionItems[]
  custom: any
}

export interface IRevCenterBreakdown {
  revCenterNetSales: string
  revCenterNetSalesPercent: string
  revCenterGuestAverage?: string
  revenueCenter: string
}

export interface IDaypartBreakdown {
  daypartSales: string
  daypartSalesPercent: string
  daypart: string
  daypartCheckCount: string
  daypartCheckAverage: string
  daypartHourlyLaborPercent: string
  rawData?: {
    daypartSales: number | null
  }
}

interface IProps {
  navParams: INavParams
}

const extractGroupedData = (
  rawData: any,
  groupedData: IStoreGroupedData[],
  defaultValue: string,
  locationId: number | undefined,
  usePeriodCogsForTrend: boolean,
  customizedData: any,
) => {
  const dataLocationId = rawData.locationId
  const groupByStartDate = moment(rawData.startDate, 'YYYY-MM-DD')
  const groupByEndDate = moment(rawData.endDate, 'YYYY-MM-DD')
  const hasCogs = BigInt(rawData.cogs || 0) !== BigInt(0)
  const hasPeriodCogs = BigInt(rawData.periodCogs || 0) !== BigInt(0)
  const cogsValue = hasCogs
    ? toUsdString(parseFloat(rawData.cogs) / 100.0)
    : defaultValue
  const periodCogsValue = hasPeriodCogs
    ? toUsdString(parseFloat(rawData.periodCogs) / 100.0)
    : defaultValue
  const cogsPercentageValue = hasCogs
    ? toPercentString(rawData.cogsPercent, 1)
    : defaultValue
  const periodCogsPercentageValue = hasPeriodCogs
    ? toPercentString(rawData.periodCogsPercent, 1)
    : defaultValue
  const avtPercent = rawData.avtPercent
    ? toPercentString(rawData.avtPercent, 1)
    : defaultValue
  const avtPercentMonthly = rawData.avtPercentMonthly
    ? toPercentString(rawData.avtPercentMonthly, 1)
    : defaultValue
  const avtPercentWeekly = rawData.avtPercentWeekly
    ? toPercentString(rawData.avtPercentWeekly, 1)
    : defaultValue
  const plvPercent = rawData.plvPercent
    ? toPercentString(rawData.plvPercent, 1)
    : defaultValue
  const hasRcp = BigInt(rawData.rcp || 0) !== BigInt(0)
  const hasHourlyLaborCost = BigInt(rawData.hourlyLaborCost || 0) !== BigInt(0)

  if (dataLocationId === locationId) {
    const customizedGroupData = customizedData?.find(
      (c) =>
        c.locationId === locationId &&
        c.businessWeek === rawData.businessWeek &&
        c.businessMonth === rawData.businessMonth &&
        c.businessQuarter === rawData.businessQuarter,
    )

    groupedData.push({
      ...customizedGroupData,
      groupByStartDate,
      groupByEndDate,
      businessWeek: rawData.businessWeek,
      businessMonth: rawData.businessMonth,
      businessQuarter: rawData.businessQuarter,
      netSales: rawData.netSales
        ? toUsdString(parseFloat(rawData.netSales) / 100.0)
        : defaultValue,
      netSalesBudget: rawData.netSalesBudget
        ? toUsdString(parseFloat(rawData.netSalesBudget) / 100.0)
        : defaultValue,
      netSalesBudgetVarianceAmount: rawData.netSalesBudgetVarianceAmount
        ? toUsdString(parseFloat(rawData.netSalesBudgetVarianceAmount) / 100.0)
        : defaultValue,
      netSalesBudgetVarianceAmountHighlightCode:
        rawData.netSalesBudgetVarianceAmount < 0 ? 'danger' : 'success',
      netSalesProjection: rawData.netSalesProjection
        ? toUsdString(parseFloat(rawData.netSalesProjection) / 100.0)
        : defaultValue,
      netSalesProjectionVarianceAmount: rawData.netSalesProjectionVarianceAmount
        ? toUsdString(
            parseFloat(rawData.netSalesProjectionVarianceAmount) / 100.0,
          )
        : defaultValue,
      netSalesProjectionVarianceAmountHighlightCode:
        rawData.netSalesProjectionVarianceAmount < 0 ? 'danger' : 'success',
      grossSales: rawData.grossSales
        ? toUsdString(parseFloat(rawData.grossSales) / 100.0)
        : defaultValue,
      grossSalesBudget: rawData.grossSalesBudget
        ? toUsdString(parseFloat(rawData.grossSalesBudget) / 100.0)
        : defaultValue,
      grossSalesBudgetVarianceAmount: rawData.grossSalesBudgetVarianceAmount
        ? toUsdString(
            parseFloat(rawData.grossSalesBudgetVarianceAmount) / 100.0,
          )
        : defaultValue,
      grossSalesBudgetVarianceAmountHighlightCode:
        rawData.grossSalesBudgetVarianceAmount < 0 ? 'danger' : 'success',
      cogs: usePeriodCogsForTrend ? periodCogsValue : cogsValue,
      cogsPercentage: usePeriodCogsForTrend
        ? periodCogsPercentageValue
        : cogsPercentageValue,
      avtPercent,
      avtPercentMonthly,
      avtPercentWeekly,
      plvPercent,
      rcp: hasRcp ? toUsdString(parseFloat(rawData.rcp) / 100.0) : defaultValue,
      rcpPercentage: hasRcp
        ? toPercentString(rawData.rcpPercent, 1)
        : defaultValue,
      hourlyLaborActual: hasHourlyLaborCost
        ? toUsdString(parseFloat(rawData.hourlyLaborCost) / 100.0)
        : defaultValue,
      hourlyLaborPercentage: hasHourlyLaborCost
        ? toPercentString(rawData.hourlyLaborCostPercent, 1)
        : defaultValue,
      hourlyLaborCostBudget: rawData.hourlyLaborCostBudget
        ? toUsdString(parseFloat(rawData.hourlyLaborCostBudget) / 100.0)
        : defaultValue,
      hourlyLaborBudgetVarianceAmount: rawData.hourlyLaborBudgetVarianceAmount
        ? toUsdString(
            parseFloat(rawData.hourlyLaborBudgetVarianceAmount) / 100.0,
          )
        : defaultValue,
      hourlyLaborBudgetVarianceAmountHighlightCode:
        rawData.hourlyLaborBudgetVarianceAmount < 0 ? 'success' : 'danger',
    })
  }
}

function StoreDetail({ navParams }: IProps) {
  useDefaultLocation()
  let errorMessage: string = ''
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0
  const dateRange = navParams.dateRange || ''
  const brandSettings = getBrandSettings()
  const financialLabels = brandSettings.labels.financial
  const brand = brandSettings.brand
  const isLegacyBrand = brandSettings.isLegacy
  const dayPartSequence = brandSettings.dayPartSequence
  const config = useConfig()

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )

  const locationInfo = useLocationInfo(storeIdNum)
  const locationId = locationInfo?.id || 0
  const [
    listLocationFinancialKpis,
    {
      loading: listLocationFinancialKpisLoading,
      error: listLocationFinancialKpisError,
      data: listLocationFinancialKpisData,
      variables: listLocationFinancialKpisVariables,
    },
  ] = useLazyQuery(LIST_LOCATION_FINANCIAL_KPIS(config.usePeriodCogs))

  const [
    listLocationCompsBreakdown,
    {
      loading: listLocationCompsBreakdownLoading,
      error: listLocationCompsBreakdownError,
      data: listLocationCompsBreakdownData,
    },
  ] = useLazyQuery(LIST_LOCATION_COMPS_BREAKDOWN)

  const [
    trendLocationFinancialKpis,
    {
      loading: trendLocationFinancialKpisLoading,
      error: trendLocationFinancialKpisError,
      data: trendLocationFinancialKpisData,
      variables: trendLocationFinancialKpisVariables,
    },
  ] = useLazyQuery(TREND_LOCATION_FINANCIAL_KPIS(config.usePeriodCogs))

  const [
    customizedTrendLocationFinancialKpis,
    {
      loading: customizedTrendLocationFinancialKpisLoading,
      data: customizedTrendLocationFinancialKpisData,
    },
  ] = useLazyQuery(CUSTOMIZED_TREND_LOCATION_FINANCIAL_KPIS)

  const [
    listLocationStoresCustomizedExtendTable,
    {
      loading: listLocationStoresCustomizedExtendTableLoading,
      data: listLocationStoresCustomizedExtendTableData,
    },
  ] = useLazyQuery(LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE)

  const [
    trendLocationFinancialKpisDaily,
    {
      loading: trendLocationFinancialKpisDailyLoading,
      error: trendLocationFinancialKpisDailyError,
      data: trendLocationFinancialKpisDailyData,
      variables: trendLocationFinancialKpisDailyVariables,
    },
  ] = useLazyQuery(TREND_LOCATION_FINANCIAL_KPIS(config.usePeriodCogs))

  const [
    listLocationKpiRankings,
    {
      loading: listLocationKpiRankingsLoading,
      error: listLocationKpiRankingsError,
      data: listLocationKpiRankingsData,
    },
  ] = useLazyQuery(LIST_LOCATION_KPI_RANKINGS)

  const [
    listLocationRevCenterBreakdown,
    {
      loading: listLocationRevCenterBreakdownLoading,
      error: listLocationRevCenterBreakdownError,
      data: listLocationRevCenterBreakdownData,
    },
  ] = useLazyQuery(LIST_LOCATION_REV_CENTER_BREAKDOWN)

  const [
    listLocationDaypartBreakdown,
    {
      loading: listLocationDaypartBreakdownLoading,
      error: listLocationDaypartBreakdownError,
      data: listLocationDaypartBreakdownData,
    },
  ] = useLazyQuery(LIST_LOCATION_DAYPART_BREAKDOWN)

  useEffect(() => {
    async function fetchData() {
      const newAllDateRangeData = await getDateRanges()

      const years = newAllDateRangeData.years

      if (Array.isArray(years) && years.length > 0) {
        let newSelectedDateRange: IDateRange | null = null
        if (dateRange) {
          newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
        }

        if (!newSelectedDateRange) {
          newSelectedDateRange = newAllDateRangeData.defaultPeriod
        }

        if (locationId && newSelectedDateRange) {
          setSelectedDateRange(newSelectedDateRange)

          listLocationFinancialKpis({
            variables: {
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iFilter: {
                location_ids: [locationId],
              },
            },
          })

          listLocationCompsBreakdown({
            variables: {
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iFilter: {
                location_ids: [locationId],
              },
            },
          })

          listLocationRevCenterBreakdown({
            variables: {
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iFilter: {
                location_ids: [locationId],
              },
            },
          })

          listLocationDaypartBreakdown({
            variables: {
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iFilter: {
                location_ids: [locationId],
              },
            },
          })

          let groupBy = ''
          if (newSelectedDateRange.type === TYPE_YEAR) {
            groupBy = GROUP_BY_QUARTER
          } else if (newSelectedDateRange.type === TYPE_QUARTER) {
            groupBy = GROUP_BY_PERIOD
          } else {
            groupBy = GROUP_BY_WEEK
          }

          trendLocationFinancialKpis({
            variables: {
              iLocationId: locationId,
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iGroupBy: groupBy,
            },
          })

          if (config.useCustomizedTrendLocationFinancialKpis)
            customizedTrendLocationFinancialKpis({
              variables: {
                iInputParams: {
                  startDate: newSelectedDateRange.startDateStr,
                  endDate: newSelectedDateRange.endDateStr,
                  locationId: locationId,
                  groupBy,
                },
              },
            })

          if (config.useListLocationStoresCustomizedExtendTable)
            listLocationStoresCustomizedExtendTable({
              variables: {
                iInputParams: {
                  startDate: newSelectedDateRange.startDateStr,
                  endDate: newSelectedDateRange.endDateStr,
                  locationIds: [locationId],
                },
              },
            })

          if (
            [
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_TRAILING_7_DAYS,
              TYPE_TRAILING_90_DAYS,
              TYPE_YESTERDAY,
            ].includes(newSelectedDateRange.type)
          ) {
            trendLocationFinancialKpisDaily({
              variables: {
                iLocationId: locationId,
                iStartDate: newSelectedDateRange.startDateStr,
                iEndDate: newSelectedDateRange.endDateStr,
                iGroupBy: GROUP_BY_LAST_X_DAYS,
              },
            })
          }

          const rankingFromLocationGroupIds: number[] = [
            BRAND_LOCATION_GROUP_ID[brand],
          ]

          listLocationKpiRankings({
            variables: {
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iFilter: {
                location_ids: [locationId],
                ranking_from: {
                  location_group_ids: rankingFromLocationGroupIds,
                },
                ranking_kpis: [
                  'net_sales_sss',
                  'gross_sales_sss',
                  'rcp',
                  'hourly_labor_budget_variance',
                ],
              },
            },
          })
        }
      }
    }

    fetchData()
  }, [locationId, dateRange])

  useEffect(() => {
    if (storeIdNum && navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl, { locationId: storeIdNum })
      feature.used('Store Performance', { locationId: storeIdNum })
    }
  }, [storeIdNum, navParams.pageUrl])

  if (!storeIdNum) {
    errorMessage = 'Please select a store first to view this page ...'
  }

  let isLoading =
    !selectedDateRange ||
    listLocationFinancialKpisLoading ||
    listLocationFinancialKpisVariables?.iFilter?.location_ids?.[0] !==
      locationId ||
    trendLocationFinancialKpisLoading ||
    customizedTrendLocationFinancialKpisLoading ||
    listLocationStoresCustomizedExtendTableLoading ||
    trendLocationFinancialKpisVariables?.iLocationId !== locationId ||
    trendLocationFinancialKpisDailyLoading ||
    listLocationCompsBreakdownLoading ||
    listLocationRevCenterBreakdownLoading ||
    listLocationKpiRankingsLoading ||
    listLocationDaypartBreakdownLoading
  let storeData: IStoreData | null = null
  let revCenterBreakdown: IRevCenterBreakdown[] = []
  let daypartBreakdown: IDaypartBreakdown[] = []
  let compsBreakdown: ICompsBreakdown[] = []
  const user = getUser()
  const userEmployeeId = _.get(user, 'employeeId', 0)
  const defaultValue = isLoading ? 'loading' : '-'

  if (selectedDateRange) {
    let netSalesSssRank: string = defaultValue
    let grossSalesSssRank: string = defaultValue
    let rcpRank: string = defaultValue
    let netSalesSssPercent: string = defaultValue
    let grossSalesSssPercent: string = defaultValue
    let netSales: string = defaultValue
    let grossSales: string = defaultValue
    let cogs: string = defaultValue
    let cogsPercentage = defaultValue
    let periodCogs: string = defaultValue
    let periodCogsPercentage: string = defaultValue
    let periodCogsBudget: string = defaultValue
    let periodCogsBudgetPercentage: string = defaultValue
    let avtPercent: string = defaultValue
    let avtPercentMonthly: string = defaultValue
    let plvPercent: string = defaultValue
    let rcp: string = defaultValue
    let rcpPercentage: string = defaultValue
    let netSalesBudget: string = defaultValue
    let netSalesBudgetVariance: string = defaultValue
    let netSalesProjection: string = defaultValue
    let netSalesProjectionVariance: string = defaultValue
    let grossSalesBudget: string = defaultValue
    let grossSalesBudgetVariance: string = defaultValue
    let cogsBudget: string = defaultValue
    let cogsBudgetPercentage: string = defaultValue
    let avtBudgetPercentage: string = ''
    let plvBudgetPercentage: string = ''
    let rcpBudget: string = defaultValue
    let rcpBudgetPercentage: string = defaultValue
    let netSalesBudgetToDate: string = defaultValue
    let netSalesProjectionToDate: string = defaultValue
    let grossSalesBudgetToDate: string = defaultValue
    let cogsBudgetToDate: string = defaultValue
    let rcpBudgetToDate: string = defaultValue
    let hourlyLaborBudget: string = defaultValue
    let hourlyLaborBudgetPercentage: string = defaultValue
    let checkCount: string = defaultValue
    let checkAvg: string = defaultValue
    let checkCountLy: string = defaultValue
    let checkCountVsLyPercent: string = defaultValue
    let sosAvgSeconds: string = defaultValue
    let hourlyLaborRank: string = defaultValue
    let hourlyLaborActual = defaultValue
    let laborPercentage: string = defaultValue
    let overtime = defaultValue
    let ptdActualBudgetDifference = {
      netSales: defaultValue,
      netSalesHighlightCode: defaultValue,
      grossSales: defaultValue,
      grossSalesHighlightCode: defaultValue,
      cogs: defaultValue,
      cogsHighlightCode: defaultValue,
      avtPercent: '',
      avtPercentHighlightCode: defaultValue,
      avtPercentMonthly: '',
      avtPercentMonthlyHighlightCode: defaultValue,
      plvPercent: '',
      plvPercentHighlightCode: defaultValue,
      rcp: defaultValue,
      rcpHighlightCode: defaultValue,
      hourlyLabor: defaultValue,
      hourlyLaborHighlightCode: defaultValue,
      cogsPercent: defaultValue,
      cogsPercentHighlightCode: defaultValue,
      hourlyLaborPercent: defaultValue,
      hourlyLaborPercentHighlightCode: defaultValue,
      rcpPercent: defaultValue,
      rcpPercentHighlightCode: defaultValue,
    }
    let ptdActualProjectionDifference = {
      netSales: defaultValue,
      netSalesHighlightCode: defaultValue,
    }

    if (
      listLocationCompsBreakdownData &&
      listLocationCompsBreakdownData.listLocationCompsBreakdown &&
      Array.isArray(
        listLocationCompsBreakdownData.listLocationCompsBreakdown.nodes,
      ) &&
      listLocationCompsBreakdownData.listLocationCompsBreakdown.nodes.length > 0
    ) {
      compsBreakdown = []
      let totalCompsDetails: ICompsBreakdown | null = null

      _.forEach(
        listLocationCompsBreakdownData.listLocationCompsBreakdown.nodes,
        (rawData: any) => {
          if (rawData && rawData.compsType) {
            const isTotalComps = rawData.compsType === 'Total Comps'

            const compsDetails: ICompsBreakdown = {
              compsName: isTotalComps
                ? `Total ${financialLabels.comps}`
                : rawData.compsType,
              compsAmount:
                toUsdString(parseFloat(rawData.compsAmount) / 100.0) ||
                defaultValue,
              compsCount: rawData.compsCount || defaultValue,
              compsPercentage:
                toPercentString(rawData.compsGrossSalesPercent, 1) ||
                defaultValue,
            }

            if (isTotalComps) {
              totalCompsDetails = compsDetails
            } else {
              compsBreakdown.push(compsDetails)
            }
          }
        },
      )

      if (totalCompsDetails) {
        compsBreakdown.push(totalCompsDetails)
      }
    }

    let groupedData: IStoreGroupedData[] = []
    let groupedDataDaily: IStoreGroupedData[] = []
    let usePeriodCogsForSummary = false
    let usePeriodCogsForTrend = false

    switch (selectedDateRange.type) {
      case TYPE_YEAR:
        usePeriodCogsForSummary = config.usePeriodCogs
        usePeriodCogsForTrend = config.usePeriodCogs
        break
      case TYPE_QUARTER:
        usePeriodCogsForSummary = config.usePeriodCogs
        usePeriodCogsForTrend = config.usePeriodCogs
        break
      case TYPE_PERIOD:
        usePeriodCogsForSummary = config.usePeriodCogs
        break
      default:
        break
    }

    if (
      trendLocationFinancialKpisData &&
      trendLocationFinancialKpisData.trendLocationFinancialKpis &&
      Array.isArray(
        trendLocationFinancialKpisData.trendLocationFinancialKpis.nodes,
      ) &&
      trendLocationFinancialKpisData.trendLocationFinancialKpis.nodes.length > 0
    ) {
      trendLocationFinancialKpisData.trendLocationFinancialKpis.nodes.forEach(
        (rawData: any) => {
          extractGroupedData(
            rawData,
            groupedData,
            defaultValue,
            locationId,
            usePeriodCogsForTrend,
            customizedTrendLocationFinancialKpisData
              ?.customizedTrendLocationFinancialKpis.nodes[0].reportResult
              .weeklyData,
          )
        },
      )
    }

    if (
      trendLocationFinancialKpisDailyData &&
      trendLocationFinancialKpisDailyData.trendLocationFinancialKpis &&
      Array.isArray(
        trendLocationFinancialKpisDailyData.trendLocationFinancialKpis.nodes,
      ) &&
      trendLocationFinancialKpisDailyData.trendLocationFinancialKpis.nodes
        .length > 0
    ) {
      trendLocationFinancialKpisDailyData.trendLocationFinancialKpis.nodes.forEach(
        (rawData: any) => {
          extractGroupedData(
            rawData,
            groupedDataDaily,
            defaultValue,
            locationId,
            usePeriodCogsForTrend,
            customizedTrendLocationFinancialKpisData
              ?.customizedTrendLocationFinancialKpis.nodes[0].reportResult
              .weeklyData,
          )
        },
      )
    }

    if (
      listLocationFinancialKpisData &&
      listLocationFinancialKpisData.listLocationFinancialKpis &&
      Array.isArray(
        listLocationFinancialKpisData.listLocationFinancialKpis.nodes,
      ) &&
      listLocationFinancialKpisData.listLocationFinancialKpis.nodes.length ===
        1 &&
      listLocationFinancialKpisData.listLocationFinancialKpis.nodes[0]
        .locationId === locationId
    ) {
      const kpis =
        listLocationFinancialKpisData.listLocationFinancialKpis.nodes[0]

      const netSalesNum = parseFloat(kpis.netSales)
      const netSalesBudgetNum = parseFloat(kpis.netSalesBudget)
      const netSalesBudgetVarianceNum = parseFloat(kpis.netSalesBudgetVariance)
      const netSalesProjectionNum = parseFloat(kpis.netSalesProjection)
      const netSalesProjectionVarianceNum = parseFloat(
        kpis.netSalesProjectionVariance,
      )
      const grossSalesNum = parseFloat(kpis.grossSales)
      const grossSalesBudgetNum = parseFloat(kpis.grossSalesBudget)
      const grossSalesBudgetVarianceNum = parseFloat(
        kpis.grossSalesBudgetVariance,
      )
      const cogsNum = parseFloat(kpis.cogs)
      const periodCogsNum = parseFloat(kpis.periodCogs)
      const cogsBudgetNum = parseFloat(kpis.cogsBudget)
      const periodCogsBudgetNum = parseFloat(kpis.periodCogsBudget)
      const rcpNum = parseFloat(kpis.rcp)
      const rcpBudgetNum = parseFloat(kpis.rcpBudget)
      const hourlyLaborActualNum = parseFloat(kpis.hourlyLaborCost)
      const hourlyLaborBudgetNum = parseFloat(kpis.hourlyLaborCostBudget)

      netSales = toUsdString(netSalesNum / 100.0)
      grossSales = toUsdString(grossSalesNum / 100.0)
      cogs = toUsdString(cogsNum / 100.0)
      cogsPercentage = toPercentString(kpis.cogsPercent, 1)
      periodCogs = toUsdString(periodCogsNum / 100.0)
      periodCogsPercentage = toPercentString(kpis.periodCogsPercent, 1)
      periodCogsBudget = toUsdString(periodCogsBudgetNum / 100.0)
      periodCogsBudgetPercentage = toPercentString(
        kpis.periodCogsBudgetPercent,
        1,
      )
      avtPercent = toPercentString(kpis.avtPercent, 1)
      avtPercentMonthly = toPercentString(kpis.avtPercentMonthly, 1)
      plvPercent = toPercentString(kpis.plvPercent, 1)
      rcp = toUsdString(rcpNum / 100.0)
      rcpPercentage = toPercentString(kpis.rcpPercent, 1)

      if (kpis.netSalesSssPercent !== null) {
        netSalesSssPercent = toPercentString(kpis.netSalesSssPercent, 1, true)
      }

      if (kpis.grossSalesSssPercent !== null) {
        grossSalesSssPercent = toPercentString(
          kpis.grossSalesSssPercent,
          1,
          true,
        )
      }

      netSalesBudget = toUsdString(netSalesBudgetNum / 100.0)
      netSalesBudgetVariance = toPercentString(netSalesBudgetVarianceNum, 1)
      netSalesProjection = toUsdString(netSalesProjectionNum / 100.0)
      netSalesProjectionVariance = toPercentString(
        netSalesProjectionVarianceNum,
        1,
      )
      grossSalesBudget = toUsdString(grossSalesBudgetNum / 100.0)
      grossSalesBudgetVariance = toPercentString(grossSalesBudgetVarianceNum, 1)
      cogsBudget = toUsdString(cogsBudgetNum / 100.0)
      cogsBudgetPercentage = toPercentString(kpis.cogsBudgetPercent, 1)
      rcpBudget = toUsdString(rcpBudgetNum / 100.0)
      rcpBudgetPercentage = toPercentString(kpis.rcpBudgetPercent, 1)
      hourlyLaborBudget = toUsdString(hourlyLaborBudgetNum / 100.0)
      hourlyLaborBudgetPercentage = toPercentString(
        kpis.hourlyLaborCostBudgetPercent,
        1,
      )

      netSalesBudgetToDate = netSalesBudget
      netSalesProjectionToDate = netSalesProjection
      grossSalesBudgetToDate = grossSalesBudget
      cogsBudgetToDate = cogsBudget
      rcpBudgetToDate = rcpBudget

      const rawCheckCount = kpis?.[config.checkCount]
      const rawCheckAvg = kpis?.[config.checkAverage]
      checkCountLy =
        config.checkCount === 'checkCount'
          ? kpis?.inUnitYoyCheckCount
          : kpis?.yoyTotalCheckCount

      checkCount =
        rawCheckCount && parseInt(rawCheckCount, 10)
          ? toFormattedInteger(rawCheckCount)
          : defaultValue
      checkAvg = rawCheckAvg
        ? toUsdString(parseFloat(rawCheckAvg) / 100.0, 2)
        : defaultValue
      checkCountVsLyPercent =
        checkCount &&
        checkCountLy &&
        parseInt(checkCount, 10) &&
        parseInt(checkCountLy, 10)
          ? toPercentString(
              (100.0 * (parseInt(rawCheckCount, 10) - checkCountLy)) /
                checkCountLy,
            )
          : '-'
      checkCountLy =
        checkCountLy && parseInt(checkCountLy, 10)
          ? toFormattedInteger(checkCountLy)
          : defaultValue

      hourlyLaborActual = toUsdString(parseFloat(kpis.hourlyLaborCost) / 100.0)
      laborPercentage = toPercentString(kpis.hourlyLaborCostPercent, 1)

      sosAvgSeconds =
        kpis?.sosAvgSeconds && parseInt(kpis?.sosAvgSeconds, 10)
          ? toFormattedInteger(kpis.sosAvgSeconds)
          : defaultValue

      if (netSalesNum > 0 && netSalesBudgetNum > 0) {
        ptdActualBudgetDifference.netSales = toPercentageString(
          ((netSalesNum - netSalesBudgetNum) * 1.0) / netSalesBudgetNum,
          1,
        )
        if (netSalesNum < netSalesBudgetNum) {
          ptdActualBudgetDifference.netSalesHighlightCode = 'danger'
        } else if (netSalesNum > netSalesBudgetNum) {
          ptdActualBudgetDifference.netSalesHighlightCode = 'success'
        }
      }

      if (netSalesNum > 0 && netSalesProjectionNum > 0) {
        ptdActualProjectionDifference.netSales = toPercentageString(
          ((netSalesNum - netSalesProjectionNum) * 1.0) / netSalesProjectionNum,
          1,
        )
        if (netSalesNum < netSalesProjectionNum) {
          ptdActualProjectionDifference.netSalesHighlightCode = 'danger'
        } else if (netSalesNum > netSalesProjectionNum) {
          ptdActualProjectionDifference.netSalesHighlightCode = 'success'
        }
      }

      if (grossSalesNum > 0 && grossSalesBudgetNum > 0) {
        ptdActualBudgetDifference.grossSales = toPercentageString(
          ((grossSalesNum - grossSalesBudgetNum) * 1.0) / grossSalesBudgetNum,
          1,
        )
        if (grossSalesNum < grossSalesBudgetNum) {
          ptdActualBudgetDifference.grossSalesHighlightCode = 'danger'
        } else if (grossSalesNum > grossSalesBudgetNum) {
          ptdActualBudgetDifference.grossSalesHighlightCode = 'success'
        }
      }

      const cogsNumForComparison = usePeriodCogsForSummary
        ? periodCogsNum
        : cogsNum

      if (cogsNumForComparison > 0 && cogsBudgetNum > 0) {
        ptdActualBudgetDifference.cogs = toPercentageString(
          ((cogsNumForComparison - cogsBudgetNum) * 1.0) / cogsBudgetNum,
          1,
        )
        if (cogsNumForComparison > cogsBudgetNum) {
          ptdActualBudgetDifference.cogsHighlightCode = 'danger'
        } else if (cogsNumForComparison < cogsBudgetNum) {
          ptdActualBudgetDifference.cogsHighlightCode = 'success'
        }
      }

      if (rcpNum > 0 && rcpBudgetNum > 0) {
        ptdActualBudgetDifference.rcp = toPercentageString(
          ((rcpNum - rcpBudgetNum) * 1.0) / rcpBudgetNum,
          1,
        )
        if (rcpNum < rcpBudgetNum) {
          ptdActualBudgetDifference.rcpHighlightCode = 'danger'
        } else if (rcpNum > rcpBudgetNum) {
          ptdActualBudgetDifference.rcpHighlightCode = 'success'
        }
      }

      if (hourlyLaborActualNum > 0 && hourlyLaborBudgetNum > 0) {
        ptdActualBudgetDifference.hourlyLabor = toPercentageString(
          ((hourlyLaborActualNum - hourlyLaborBudgetNum) * 1.0) /
            hourlyLaborBudgetNum,
          1,
        )
        if (hourlyLaborActualNum > hourlyLaborBudgetNum) {
          ptdActualBudgetDifference.hourlyLaborHighlightCode = 'danger'
        } else if (hourlyLaborActualNum < hourlyLaborBudgetNum) {
          ptdActualBudgetDifference.hourlyLaborHighlightCode = 'success'
        }
      }

      const cogsPercentForComparison = usePeriodCogsForSummary
        ? kpis.periodCogsPercent
        : kpis.cogsPercent
      if (cogsPercentForComparison > 0 && kpis.cogsBudgetPercent > 0) {
        ptdActualBudgetDifference.cogsPercent = toPercentageString(
          (cogsPercentForComparison - kpis.cogsBudgetPercent) / 100.0,
          1,
        )
        if (cogsPercentForComparison > kpis.cogsBudgetPercent) {
          ptdActualBudgetDifference.cogsPercentHighlightCode = 'danger'
        } else if (cogsPercentForComparison < kpis.cogsBudgetPercent) {
          ptdActualBudgetDifference.cogsPercentHighlightCode = 'success'
        }
      }

      if (
        kpis.hourlyLaborCostPercent > 0 &&
        kpis.hourlyLaborCostBudgetPercent > 0
      ) {
        ptdActualBudgetDifference.hourlyLaborPercent = toPercentageString(
          (kpis.hourlyLaborCostPercent - kpis.hourlyLaborCostBudgetPercent) /
            100.0,
          1,
        )
        if (kpis.hourlyLaborCostPercent > kpis.hourlyLaborCostBudgetPercent) {
          ptdActualBudgetDifference.hourlyLaborPercentHighlightCode = 'danger'
        } else if (
          kpis.hourlyLaborCostPercent < kpis.hourlyLaborCostBudgetPercent
        ) {
          ptdActualBudgetDifference.hourlyLaborPercentHighlightCode = 'success'
        }
      }

      if (kpis.rcpPercent > 0 && kpis.rcpBudgetPercent > 0) {
        ptdActualBudgetDifference.rcpPercent = toPercentageString(
          (kpis.rcpPercent - kpis.rcpBudgetPercent) / 100.0,
          1,
        )
        if (kpis.rcpPercent < kpis.rcpBudgetPercent) {
          ptdActualBudgetDifference.rcpPercentHighlightCode = 'danger'
        } else if (kpis.rcpPercent > kpis.rcpBudgetPercent) {
          ptdActualBudgetDifference.rcpPercentHighlightCode = 'success'
        }
      }

      if (config.overtime === 'hour') {
        overtime = !_.isNil(kpis.hourlyLaborOvertimeHours)
          ? `${toHourStr(kpis.hourlyLaborOvertimeHours)} hours`
          : '-'
      } else {
        overtime = toUsdString(parseFloat(kpis.hourlyLaborOvertimeCost) / 100.0)
      }
    }

    if (
      listLocationRevCenterBreakdownData &&
      listLocationRevCenterBreakdownData.listLocationRevenueCenterBreakdown &&
      Array.isArray(
        listLocationRevCenterBreakdownData.listLocationRevenueCenterBreakdown
          .nodes,
      )
    ) {
      let rawRevCenterBreakdownData =
        listLocationRevCenterBreakdownData.listLocationRevenueCenterBreakdown
          .nodes
      rawRevCenterBreakdownData = _.orderBy(
        rawRevCenterBreakdownData,
        'revenueCenter',
      )

      const totalSales = _.sumBy(rawRevCenterBreakdownData, (d) => {
        return parseFloat(_.get(d, 'revCenterNetSales') || 0)
      })

      let dineInNetSales = 0
      let dineInNetSalesPercent = 0
      let barNetSales = 0
      let barNetSalesPercent = 0

      revCenterBreakdown = _.compact(
        _.map(rawRevCenterBreakdownData, (d) => {
          const revCenterNetSales = parseFloat(
            _.get(d, 'revCenterNetSales') || 0,
          )
          const revCenterNetSalesPercent =
            (revCenterNetSales * 1.0) / totalSales
          const revCenterGuestAverage = parseFloat(
            _.get(d, 'revCenterGuestAverage') || 0,
          )
          const revCenterCheckCount = toFormattedInteger(
            _.get(d, 'revCenterCheckCount') ?? '-',
          )
          const revCenterCheckAverage = parseFloat(
            _.get(d, 'revCenterCheckAverage'),
          )

          const revenueCenter = _.get(d, 'revenueCenter', '')
          const drevCenterNetSalesPercentStr = (() => {
            if (
              revCenterNetSalesPercent > 0 &&
              revCenterNetSalesPercent < 0.001
            ) {
              return '0.1%'
            }
            if (
              revCenterNetSalesPercent < 0 &&
              revCenterNetSalesPercent > -0.001
            ) {
              return '(0.1%)'
            }
            return toPercentageString(revCenterNetSalesPercent, 1)
          })()

          if (isLegacyBrand && revenueCenter === 'Dine In') {
            dineInNetSales = revCenterNetSales
            dineInNetSalesPercent = revCenterNetSalesPercent
          } else if (isLegacyBrand && revenueCenter === 'Bar') {
            barNetSales = revCenterNetSales
            barNetSalesPercent = revCenterNetSalesPercent
          } else {
            return {
              revCenterNetSales: toUsdString(revCenterNetSales / 100.0),
              revCenterNetSalesPercent: drevCenterNetSalesPercentStr,
              revCenterGuestAverage: toUsdStr(revCenterGuestAverage / 100.0),
              revenueCenter,
              revCenterCheckCount: revCenterCheckCount,
              revCenterCheckAverage: toUsdStr(revCenterCheckAverage / 100.0),
            }
          }
        }),
      )

      if (isLegacyBrand) {
        const inUnitRevCenterNetSalesPercent =
          dineInNetSalesPercent + barNetSalesPercent

        revCenterBreakdown.push({
          revCenterNetSales: toUsdString(
            (dineInNetSales + barNetSales) / 100.0,
          ),
          revCenterNetSalesPercent:
            inUnitRevCenterNetSalesPercent > 0.001 ||
            inUnitRevCenterNetSalesPercent < 0
              ? toPercentageString(inUnitRevCenterNetSalesPercent, 1)
              : '0.1%',
          revenueCenter: 'In Unit',
        })
      }
    }

    if (
      listLocationDaypartBreakdownData &&
      listLocationDaypartBreakdownData.listLocationDaypartBreakdown &&
      Array.isArray(
        listLocationDaypartBreakdownData.listLocationDaypartBreakdown.nodes,
      )
    ) {
      let rawDaypartBreakdownData =
        listLocationDaypartBreakdownData.listLocationDaypartBreakdown.nodes
      rawDaypartBreakdownData = _.map(rawDaypartBreakdownData, (data: any) => {
        return {
          ...data,
          dayPartSequence:
            dayPartSequence[data?.daypart] || dayPartSequence['_default_'],
        }
      })
      rawDaypartBreakdownData = _.orderBy(
        rawDaypartBreakdownData,
        ['dayPartSequence', 'daypart'],
        'asc',
      )

      const totalSales = _.sumBy(rawDaypartBreakdownData, (d) => {
        return parseFloat(_.get(d, 'daypartSales') || 0)
      })

      daypartBreakdown = _.compact(
        _.map(rawDaypartBreakdownData, (d) => {
          const daypartSales = parseFloat(_.get(d, 'daypartSales') || 0)
          const daypartSalesPercent = (daypartSales * 1.0) / totalSales
          const daypart = _.get(d, 'daypart', '')
          const daypartCheckCount = toFormattedInteger(
            _.get(d, 'daypartCheckCount') ?? '-',
          )
          const daypartCheckAverage = parseFloat(
            _.get(d, 'daypartCheckAverage'),
          )
          const daypartSalesPercentStr = (() => {
            if (daypartSalesPercent > 0 && daypartSalesPercent < 0.001) {
              return '0.1%'
            }
            if (daypartSalesPercent < 0 && daypartSalesPercent > -0.001) {
              return '(0.1%)'
            }
            return toPercentageString(daypartSalesPercent, 1)
          })()
          const daypartHourlyLaborPercent = parseFloat(
            _.get(d, 'daypartHourlyLaborPercent'),
          )
          const daypartHourlyLaborPercentStr = toPercentageString(
            daypartHourlyLaborPercent,
            1,
          )

          return {
            daypartSales: toUsdString(daypartSales / 100.0),
            daypartSalesPercent: daypartSalesPercentStr,
            daypart,
            daypartCheckCount,
            daypartCheckAverage: toUsdStr(daypartCheckAverage / 100.0),
            daypartHourlyLaborPercent: daypartHourlyLaborPercentStr,
            rawData: {
              daypartSales,
            },
          }
        }),
      )
    }

    if (
      listLocationKpiRankingsData &&
      listLocationKpiRankingsData.listLocationKpiRankings &&
      Array.isArray(
        listLocationKpiRankingsData.listLocationKpiRankings.nodes,
      ) &&
      listLocationKpiRankingsData.listLocationKpiRankings.nodes.length > 0
    ) {
      listLocationKpiRankingsData.listLocationKpiRankings.nodes.forEach(
        (rawData: any) => {
          switch (rawData.kpi) {
            case 'net_sales_sss': {
              netSalesSssRank =
                _.get(rawData, 'rankingData.net_sales_sss_percent_rank') ||
                defaultValue
              break
            }

            case 'gross_sales_sss': {
              grossSalesSssRank =
                _.get(rawData, 'rankingData.gross_sales_sss_percent_rank') ||
                defaultValue
              break
            }

            case 'rcp': {
              rcpRank =
                _.get(rawData, 'rankingData.rcp_percent_rank') || defaultValue
              break
            }

            case 'hourly_labor_budget_variance': {
              hourlyLaborRank =
                _.get(
                  rawData,
                  'rankingData.hourly_labor_budget_variance_rank',
                ) || defaultValue
              break
            }

            default:
            // do nothing
          }
        },
      )
    }

    storeData = {
      locationId,

      netSalesSssRank,
      grossSalesSssRank,
      rcpRank,
      hourlyLaborRank,

      netSales,
      grossSales,
      cogs,
      cogsPercentage,
      periodCogs,
      periodCogsPercentage,
      periodCogsBudget,
      periodCogsBudgetPercentage,
      avtPercent,
      avtPercentMonthly,
      plvPercent,
      rcp,
      rcpPercentage,
      netSalesSssPercent,
      grossSalesSssPercent,

      netSalesBudget,
      netSalesBudgetVariance,
      netSalesProjection,
      netSalesProjectionVariance,
      grossSalesBudget,
      grossSalesBudgetVariance,
      cogsBudget,
      cogsBudgetPercentage,
      avtBudgetPercentage,
      plvBudgetPercentage,
      rcpBudget,
      rcpBudgetPercentage,
      hourlyLaborBudget,
      hourlyLaborBudgetPercentage,

      netSalesBudgetToDate,
      netSalesProjectionToDate,
      grossSalesBudgetToDate,
      cogsBudgetToDate,
      rcpBudgetToDate,

      checkCount,
      checkAvg,
      checkCountLy,
      checkCountVsLyPercent,

      sosAvgSeconds,

      hourlyLaborActual,
      laborPercentage,

      groupedData,
      groupedDataDaily,

      ptdActualBudgetDifference,
      ptdActualProjectionDifference,

      overtime,

      compsBreakdown,
    }

    if (config.useListLocationStoresCustomizedExtendTable) {
      storeData.custom =
        listLocationStoresCustomizedExtendTableData?.customizedTrendLocationFinancialKpis.nodes[0]
    }
  }

  if (
    listLocationFinancialKpisError ||
    listLocationCompsBreakdownError ||
    trendLocationFinancialKpisError ||
    trendLocationFinancialKpisDailyError ||
    listLocationRevCenterBreakdownError ||
    listLocationKpiRankingsError
  ) {
    let hasError = false
    errorMessage =
      'Unexpected Error. Please try again or contact support@getexpo.com.'
    if (
      listLocationFinancialKpisError &&
      listLocationFinancialKpisError.message
    ) {
      hasError = true
      errorMessage += ` (${listLocationFinancialKpisError.message})`
    }
    if (
      listLocationCompsBreakdownError &&
      listLocationCompsBreakdownError.message
    ) {
      hasError = true
      errorMessage += ` (${listLocationCompsBreakdownError.message})`
    }
    if (
      trendLocationFinancialKpisError &&
      trendLocationFinancialKpisError.message
    ) {
      hasError = true
      errorMessage += ` (${trendLocationFinancialKpisError.message})`
    }
    if (
      listLocationRevCenterBreakdownError &&
      listLocationRevCenterBreakdownError.message
    ) {
      hasError = true
      errorMessage += ` (${listLocationRevCenterBreakdownError.message})`
    }
    if (listLocationKpiRankingsError && listLocationKpiRankingsError.message) {
      hasError = true
      errorMessage += ` (${listLocationKpiRankingsError.message})`
    }

    if (!hasError) {
      // filter out some arithmetical errors
      errorMessage = ''
    }
  }

  return (
    <Main
      navParams={navParams}
      isLoading={isLoading}
      selectedDateRange={selectedDateRange}
      storeData={storeData}
      errorMessage={errorMessage}
      userEmployeeId={userEmployeeId}
      selectedLocation={locationInfo}
      revCenterBreakdown={revCenterBreakdown}
      locationRevCenterBreakdownLoading={listLocationRevCenterBreakdownLoading}
      daypartBreakdown={daypartBreakdown}
      customizedTrendLocationFinancialKpis={{
        columns:
          customizedTrendLocationFinancialKpisData
            ?.customizedTrendLocationFinancialKpis.nodes[0].reportDefinition
            .columns || [],
        total:
          customizedTrendLocationFinancialKpisData
            ?.customizedTrendLocationFinancialKpis.nodes[0].reportResult
            .totalData || [],
      }}
      trendSalesTrendStackedBarItems={config.trendSalesTrendStackedBarItems}
    />
  )
}

export default StoreDetail
