import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOGOS } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IDataType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      employeeId: number
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
      geo?: {
        latitude?: number
        longitude?: number
      }
      locationGroups: {
        id: number
        name: string
        type: string
        start_date: string
        end_date: string
      }[]
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids?: number[]
  }
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query listGeoInfo($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON) {
    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        code
        name
        geo
        locationGroups
      }
    }

    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        locationGroupId
        firstName
        lastName
      }
    }
  }
`

const useListGeoInfo = () => {
  const { brand } = useBrands()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: { location_group_ids: groupFilter?.ids },
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !groupFilter || !startDate || !endDate,
    },
  )

  return {
    data: useMemo((): IDataType[] | null => {
      if (!data) return null

      const locationGroupIds = groupFilter?.ids.join('')
      const output = data.listLocationDetails.nodes.reduce(
        (result, { id, code, name, geo, locationGroups }) => {
          const directors = data.listDirectors.nodes
            .filter((d) =>
              locationGroups.some((lg) => lg.id === d.locationGroupId),
            )
            .sort((a, b) => {
              const locationGroupA = locationGroups.find(
                (lg) => lg.id === a.locationGroupId,
              )
              const locationGroupB = locationGroups.find(
                (lg) => lg.id === b.locationGroupId,
              )

              if (!locationGroupA || !locationGroupB) return 0

              return moment
                .utc(locationGroupA.end_date)
                .isSameOrBefore(moment.utc(locationGroupB.end_date))
                ? -1
                : 1
            })
          const director = directors[0]
          const groupInfo = director
            ? {
                id: director.employeeId,
                givenName: director.firstName,
                familyName: director.lastName,
              }
            : {
                id: null,
                givenName: 'Others',
                familyName: '',
              }
          const key = !groupInfo.id
            ? 'Others'
            : `${locationGroupIds}${groupInfo.id}`

          if (!result[key])
            result[key] = {
              key,
              name: `${groupInfo.givenName} ${groupInfo.familyName}`,
              points: [],
            }

          if (geo?.latitude && geo?.longitude)
            result[key].points.push({
              key: id,
              name: `${code} - ${name}`,
              logo: BRAND_LOGOS[brand].dark,
              latitude: geo.latitude,
              longitude: geo.longitude,
            })

          return result
        },
        {} as Record<string, IDataType>,
      )

      return Object.values(output)
    }, [data, brand, groupFilter]),
    loading,
  }
}

export default useListGeoInfo
