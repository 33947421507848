import { useQuery } from '@apollo/client'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import { useState } from 'react'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrand } from 'pared/utils/brand'
import { getDateRangeLabel } from 'pared/utils/date'

import { TREND_LOCATION_CUSTOMIZED_REPORT } from './gql'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350
const CHART_START_DATE = moment.utc('2023-01-01', 'YYYY-MM-DD', true)

interface IProps {
  locationId: number
  locationName: string
  endDate: string
}

interface IData {
  trendLocationCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        textData: {
          upsizePercent: string
        }
      }
    }[]
  }

  trendLocationGroupCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        textData: {
          upsizePercent: string
        }
      }
    }[]
  }
}

interface IOrganizedData {
  dateRanges: string[]
  locationInboundTotal: number[]
  locationAnsweredPercent: number[]
  locationMissedAndAbandonedPercent: number[]
  locationMissedPercent: number[]
  locationAbandonedPercent: number[]
  locationGroupAnsweredPercent: number[]
  locationGroupMissedAndAbandonedPercent: number[]
  locationGroupMissedPercent: number[]
  locationGroupAbandonedPercent: number[]
}

type IKpiType = 'Answered' | 'MissedAndAbandoned' | 'Missed' | 'Abandoned'

const IS_KPI_BETTER_WHEN_HIGH: { [key: string]: boolean } = {
  Answered: true,
  ['MissedAndAbandoned']: false,
  Missed: false,
  Abandoned: false,
}

const KPI_LABEL: { [key: string]: string } = {
  Answered: 'Answered',
  ['MissedAndAbandoned']: 'Missed + Abandoned',
  Missed: 'Missed',
  Abandoned: 'Abandoned',
}

const getOptions = (brandCode: string): IKpiType[] => {
  switch (brandCode) {
    case 'sullivans': // Sullivan's
    case 'eddiemerlots': // Eddie Merlot's
    case 'sullivans_nso': // Sullivan's NSO
      return ['Answered', 'Missed']

    default:
      return ['Answered', 'MissedAndAbandoned', 'Missed', 'Abandoned']
  }
}

const UpsizeTrendChart = ({ locationId, locationName, endDate }: IProps) => {
  const brand = getBrand()
  const options = getOptions(brand)
  const [kpi, setKpi] = useState<IKpiType>('Answered')
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[getBrand()]
  const trendChartParams = {
    iLocationReportName: 'LIST_LOCATION_INBOUND_CALLS_TABLE',
    iLocationId: locationId,
    iLocationGroupReportName: 'LIST_LOCATION_GROUP_INBOUND_CALLS_TABLE',
    iLocationGroupId: brandLocationGroupId,
    iEndDate: endDate,
    iGroupBy: 'business_month',
  }

  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_CUSTOMIZED_REPORT, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  // if (loading) {
  //   return <PageStatusDiv>Loading ...</PageStatusDiv>
  // }

  // if (!data) {
  //   return (
  //     <PageStatusDiv>
  //       There is no data within the date range selected. Please select a
  //       different date range.
  //     </PageStatusDiv>
  //   )
  // }

  const locationData = data?.trendLocationCustomizedReport?.nodes || []
  const locationGroupData =
    data?.trendLocationGroupCustomizedReport?.nodes || []

  const processedDataMap: {
    [startDate: string]: {
      inboundTotal: number
      inboundAnsweredPercent: number
      inboundMissedAndAbandonedPercent: number
      inboundMissedPercent: number
      inboundAbandonedPercent: number
      locationGroupInboundAnsweredPercent: number
      locationGroupInboundMissedAndAbandonedPercent: number
      locationGroupInboundMissedPercent: number
      locationGroupInboundAbandonedPercent: number
    }
  } = {}
  locationData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const {
      inboundTotal,
      inboundAnsweredPercent,
      inboundMissedAndAbandonedPercent,
      inboundMissedPercent,
      inboundAbandonedPercent,
    } = rawData?.reportResult?.tableData?.[0] ?? {}
    const processedData = processedDataMap[startDate] || {}
    processedData.inboundTotal = inboundTotal
    processedData.inboundAnsweredPercent = inboundAnsweredPercent?.toFixed(1)
    processedData.inboundMissedAndAbandonedPercent =
      inboundMissedAndAbandonedPercent?.toFixed(1)
    processedData.inboundMissedPercent = inboundMissedPercent?.toFixed(1)
    processedData.inboundAbandonedPercent = inboundAbandonedPercent?.toFixed(1)
    processedDataMap[startDate] = processedData
  })

  locationGroupData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const {
      inboundAnsweredPercent,
      inboundMissedAndAbandonedPercent,
      inboundMissedPercent,
      inboundAbandonedPercent,
    } = rawData?.reportResult?.tableData?.[0] ?? {}
    const processedData = processedDataMap[startDate] || {}
    processedData.locationGroupInboundAnsweredPercent =
      inboundAnsweredPercent?.toFixed(1)
    processedData.locationGroupInboundMissedAndAbandonedPercent =
      inboundMissedAndAbandonedPercent?.toFixed(1)
    processedData.locationGroupInboundMissedPercent =
      inboundMissedPercent?.toFixed(1)
    processedData.locationGroupInboundAbandonedPercent =
      inboundAbandonedPercent?.toFixed(1)
    processedDataMap[startDate] = processedData
  })

  const organizedData = locationData.reduce(
    (acc, { startDate }) => {
      if (CHART_START_DATE.isBefore(startDate)) {
        acc.dateRanges.push(
          getDateRangeLabel(startDate, trendChartParams.iGroupBy),
        )
        acc.locationInboundTotal.push(
          processedDataMap[startDate]?.inboundTotal || 0,
        )
        acc.locationAnsweredPercent.push(
          processedDataMap[startDate]?.inboundAnsweredPercent || 0.0,
        )
        acc.locationGroupAnsweredPercent.push(
          processedDataMap[startDate]?.locationGroupInboundAnsweredPercent ||
            0.0,
        )
        acc.locationMissedAndAbandonedPercent.push(
          processedDataMap[startDate]?.inboundMissedAndAbandonedPercent || 0.0,
        )
        acc.locationMissedPercent.push(
          processedDataMap[startDate]?.inboundMissedPercent || 0.0,
        )
        acc.locationGroupMissedAndAbandonedPercent.push(
          processedDataMap[startDate]
            ?.locationGroupInboundMissedAndAbandonedPercent || 0.0,
        )
        acc.locationGroupMissedPercent.push(
          processedDataMap[startDate]?.locationGroupInboundMissedPercent || 0.0,
        )
        acc.locationAbandonedPercent.push(
          processedDataMap[startDate]?.inboundAbandonedPercent || 0.0,
        )
        acc.locationGroupAbandonedPercent.push(
          processedDataMap[startDate]?.locationGroupInboundAbandonedPercent ||
            0.0,
        )
      }
      return acc
    },
    {
      dateRanges: [],
      locationInboundTotal: [],
      locationAnsweredPercent: [],
      locationMissedAndAbandonedPercent: [],
      locationMissedPercent: [],
      locationAbandonedPercent: [],
      locationGroupAnsweredPercent: [],
      locationGroupMissedAndAbandonedPercent: [],
      locationGroupMissedPercent: [],
      locationGroupAbandonedPercent: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = [
    {
      type: 'bar',
      yAxisId: 'yRight',
      data: organizedData.locationInboundTotal,
      tooltipLabel: `${locationName} # of Calls`,
    },
    {
      type: 'line',
      data: organizedData[`location${kpi}Percent`],
      tooltipLabel: `${locationName} % ${KPI_LABEL[kpi]}`,
      borderColor: COLORS.Plum,
      backgroundColor: COLORS.Plum,
      fill: {
        target: 2,
        below: IS_KPI_BETTER_WHEN_HIGH[kpi]
          ? 'hsla(0, 99%, 69%, 0.5)'
          : 'hsla(130, 35%, 58%, 0.5)',
        above: IS_KPI_BETTER_WHEN_HIGH[kpi]
          ? 'hsla(130, 35%, 58%, 0.5)'
          : 'hsla(0, 99%, 69%, 0.5)',
      },
    },
    {
      type: 'line',
      data: organizedData[`locationGroup${kpi}Percent`],
      tooltipLabel: `Systemwide % ${KPI_LABEL[kpi]}`,
      borderColor: COLORS.Chalkboard,
      backgroundColor: COLORS.Chalkboard,
    },
  ]

  const chartOptions = {
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '%',
    yLeftTickCallback: (value: number) => {
      return `${value}%`
    },
    yRightShowGridLines: false,
  }

  return (
    <>
      <Header>
        <PageHeader>Trend Chart</PageHeader>
        <StyledFormControl variant="outlined" size="small">
          <Select
            value={kpi}
            onChange={(e: any) => {
              const value: IKpiType = e.target.value
              setKpi(value)
            }}
          >
            {options.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {KPI_LABEL[option]}
                </MenuItem>
              )
            })}
          </Select>
        </StyledFormControl>
      </Header>
      <VerticalSpacer />
      {loading ? (
        <PageStatusDiv>Loading ...</PageStatusDiv>
      ) : data ? (
        <MobileContainer>
          <MixedChart
            xAxisData={organizedData.dateRanges}
            yAxisDataArr={yAxisDataArr}
            options={chartOptions}
          />
        </MobileContainer>
      ) : (
        <PageStatusDiv>
          There is no data within the date range selected. Please select a
          different date range.
        </PageStatusDiv>
      )}
    </>
  )
}

export default UpsizeTrendChart

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const StyledFormControl = styled(FormControl)`
  width: 200px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  color: ${COLORS.Chalkboard};
  @media ${MOBILE_WIDTH} {
    font-size: 18px;
  }
`
