import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { toHourStr, toPercentString, toUsdString } from 'pared/utils/number'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestOutliersKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }

    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }
  }
`

export const farwestOutliersConfigs = {
  laborHoursVariance: 'colored-text',
  varFoodCostPercentage: 'colored-text',
  actualFoodCostPercentage: 'colored-text',
  otActualHours: 'colored-text',
  cashOs: 'colored-text',
  mealViolation: 'colored-text',
  discountPercentage: 'colored-text',
  voidPercentage: 'colored-text',
  netSalesPercentageDiffToLastYear: 'colored-text',
  netSalesPercentageDiffTo7DaysAgo: 'colored-text',
  checkAverage: 'colored-text',
  laborToMatrixVariance: 'colored-text',
} as const

const useFarwestOutliers = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: [
          'labor_hours_variance',
          'labor_variance_hours',
          'var_food_cost_percentage',
          'actual_food_cost_percentage',
          'ot_actual_hours',
          'cash_os',
          'meal_violation',
          'discount_percentage',
          'void_percentage',
          'net_sales_percentage_diff_to_last_year',
          'net_sales_percentage_diff_to_7_days_ago',
          'check_average',
          'labor_to_matrix_variance',
        ],
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'listLocationMetricValues'
            : 'listLocationGroupMetricValues'
        ]?.nodes

      if (!customizedData) return null

      const source = customizedData.map((locationData: any) => {
        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )

        const kpisData: {
          [key: string]: {
            text: string
            textColor: string
            type?: string
            value?: number
          }
        } = {}

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            let status: string = ''
            switch (key) {
              case 'labor_hours_variance':
                const laborVar =
                  locationData.metricData['labor_hours_variance']?.value
                kpisData[newKey] = {
                  text: toHourStr(locationData.metricData[key].value || '-'),
                  textColor: laborVar >= 30 ? 'danger' : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'var_food_cost_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100,
                    2,
                  ),
                  textColor:
                    locationData.metricData[key].value >= 0.015
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'actual_food_cost_percentage':
                if (locationData.metricData[key].value >= 0.29) {
                  status = 'danger'
                } else if (locationData.metricData[key].value < 0.25) {
                  status = 'black'
                } else {
                  status = 'warning'
                }

                kpisData[newKey] = {
                  text: `${toPercentString(
                    locationData.metricData[key].value * 100,
                    2,
                  )}`,
                  textColor: status,
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'ot_actual_hours':
                kpisData[newKey] = {
                  text:
                    locationData?.metricData[key].value !== null
                      ? locationData.metricData[key].value
                      : '-',
                  textColor:
                    locationData.metricData[key].value >= 15
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'meal_violation':
                kpisData[newKey] = {
                  text: locationData.metricData[key].value,
                  textColor:
                    locationData.metricData[key].value >= 5
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'cash_os':
                kpisData[newKey] = {
                  text:
                    locationData.metricData[key].value !== null
                      ? toUsdString(locationData.metricData[key].value, 0)
                      : '-',
                  textColor:
                    locationData.metricData[key].value >= 100
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'void_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100,
                    2,
                  ),
                  textColor:
                    locationData.metricData[key].value >= 0.005
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'discount_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100,
                    2,
                  ),
                  textColor:
                    locationData.metricData[key].value >= 0.02
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'net_sales_percentage_diff_to_last_year':
                kpisData[newKey] = {
                  text: locationData.metricData[key].value
                    ? toPercentString(
                        locationData.metricData[key].value * 100,
                        2,
                      )
                    : '-',
                  textColor: 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'net_sales_percentage_diff_to_7_days_ago':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100,
                    2,
                  ),
                  textColor: 'black',
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'check_average':
                if (locationData.metricData[key].value >= 30) {
                  status = 'black'
                } else if (locationData.metricData[key].value < 20) {
                  status = 'danger'
                } else {
                  status = 'warning'
                }

                kpisData[newKey] = {
                  text: toUsdString(locationData.metricData[key].value, 2),
                  textColor: status,
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              case 'labor_to_matrix_variance':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100,
                    2,
                  ),
                  textColor:
                    locationData.metricData[key].value >= 0.02
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value || 0,
                }
                break

              default:
                break
            }
          }
        }

        return {
          ...kpisData,
          groupInfo,
        }
      })

      return {
        source,
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestOutliers
