import _ from 'lodash'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import FullWidthTable from 'pared/components/FullWidthTable'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import NormalizationStatus from 'pared/components/NormalizationStatus'
import HighlightSpan from 'pared/components/basicUi/HighlightSpan'
import Tooltip from 'pared/components/basicUi/Tooltip'
import Spin from 'pared/components/basicUi/spin'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getFinancialLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getDay } from 'pared/utils/date'

import CustomizedTd from './CustomizedTd'
import DayPartPieChart from './DayPartPieChart'
import PerformanceTrendChart from './PerformanceTrendChart'
import PlvPercentTrendChart from './PlvPercentTrendChart'
import SalesTrendStackedBarChart from './SalesTrendStackedBarChart'
import useConfig from './hooks/useConfig'
import { IDaypartBreakdown, IRevCenterBreakdown, IStoreData } from './index'

const DATE_DISPLAY_FORMAT = 'M/D/YY'

interface IOverviewTableTh {
  largerFont?: boolean
  textAlign?: string
  verticalAlign?: string
}

interface IOpenPositionsTd {
  bold?: boolean
  textAlign?: string
  danger?: boolean
  success?: boolean
}

interface IProps {
  navParams: INavParams
  isLoading: boolean
  storeData: IStoreData | null
  selectedDateRange: IDateRange | null
  errorMessage: string
  userEmployeeId: number
  selectedLocation: ILocationInfo | null
  revCenterBreakdown: IRevCenterBreakdown[]
  locationRevCenterBreakdownLoading: boolean
  daypartBreakdown: IDaypartBreakdown[]
  customizedTrendLocationFinancialKpis: {
    columns: {}[]
    total: {}
  }
  trendSalesTrendStackedBarItems?: string[]
}

const isTextNegativeNumber = (text: string) =>
  text.includes('-') || text.includes('(')

function WebLargeMain({
  navParams,
  isLoading,
  storeData,
  selectedDateRange,
  errorMessage,
  selectedLocation,
  revCenterBreakdown,
  locationRevCenterBreakdownLoading,
  daypartBreakdown,
  customizedTrendLocationFinancialKpis,
  trendSalesTrendStackedBarItems,
}: IProps) {
  const config = useConfig()
  const { brand } = useBrands()

  if (errorMessage) {
    return (
      <MainContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (!storeData || !selectedLocation || !selectedDateRange) {
    return (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial
  const businessLabel = brandSettings.labels.business
  const isAvtMonthlyVisible =
    config.avtPercentMonthly &&
    ![TYPE_TRAILING_7_DAYS, TYPE_YESTERDAY].includes(selectedDateRange.type)

  let actionItemsContent = null
  if (
    Array.isArray(storeData.actionItems) &&
    storeData.actionItems.length > 0
  ) {
    actionItemsContent = (
      <>
        <VerticalSpacer30px />
        <SectionHeader>ACTION ITEMS</SectionHeader>
        <ActionItemContainer>
          {storeData.actionItems.map((item) => {
            return (
              <ActionItemDiv key={item.id}>
                <CheckMarkSpan>
                  <span role="img" aria-label="check mark">
                    ✅
                  </span>
                </CheckMarkSpan>
                {item.detail}
              </ActionItemDiv>
            )
          })}
        </ActionItemContainer>
      </>
    )
  }

  let breakDownName = 'Week'
  let breakDownFieldName: 'businessWeek' | 'businessMonth' | 'businessQuarter' =
    'businessWeek'
  let breakDownSumName = 'PTD'
  if (selectedDateRange.type === TYPE_YEAR) {
    breakDownName = 'Quarter'
    breakDownFieldName = 'businessQuarter'
    breakDownSumName = 'YTD'
  } else if (selectedDateRange.type === TYPE_QUARTER) {
    breakDownName = 'Period'
    breakDownFieldName = 'businessMonth'
    breakDownSumName = 'QTD'
  }

  const revCenterOrder = config.revCenterOrder
  const revCenterBreakdownRows = locationRevCenterBreakdownLoading ? (
    <PageStatusDiv>Loading ...</PageStatusDiv>
  ) : (
    revCenterBreakdown
      .sort((a, b) => {
        if (revCenterOrder) {
          return (
            revCenterOrder?.[a.revenueCenter] -
            revCenterOrder?.[b.revenueCenter]
          )
        } else {
          return 0
        }
      })
      .map((d) => {
        const rawRevCenterName = _.get(d, 'revenueCenter', '')
        const mappedRevCenterName =
          config.revenueCenterNameMap?.[rawRevCenterName]

        return (
          <tr key={uuidv4()}>
            <OverviewTableLeftTd>
              {mappedRevCenterName ? mappedRevCenterName : rawRevCenterName}
            </OverviewTableLeftTd>
            <OverviewTableTd>
              {_.get(d, 'revCenterNetSales', '')}
            </OverviewTableTd>
            <OverviewTableTd>
              {_.get(d, 'revCenterNetSalesPercent', '')}
            </OverviewTableTd>
            {config.isRevCenterGuestAverageVisible ? (
              <OverviewTableTd>
                {_.get(d, 'revCenterGuestAverage', '')}
              </OverviewTableTd>
            ) : null}
            {config.isRevCenterCheckCountVisible ? (
              <OverviewTableTd>
                {_.get(d, 'revCenterCheckCount', '')}
              </OverviewTableTd>
            ) : null}
            {config.isRevCenterCheckAverageVisible ? (
              <OverviewTableTd>
                {_.get(d, 'revCenterCheckAverage', '')}
              </OverviewTableTd>
            ) : null}
          </tr>
        )
      })
  )

  const dapartOrder = {
    'Early Bird': 1,
    Breakfast: 2,
    Lunch: 3,
    'Afternoon Snack': 4,
    'Mid-Day': 5,
    Afternoon: 6,
    Dinner: 7,
    Evening: 8,
    'Evening Snack': 9,
    'Late-Night': 10,
  }

  const daypartBreakdownRows = locationRevCenterBreakdownLoading ? (
    <PageStatusDiv>Loading ...</PageStatusDiv>
  ) : (
    daypartBreakdown
      .sort((a, b) => dapartOrder[a.daypart] - dapartOrder[b.daypart])
      .map((d) => {
        return (
          <tr key={uuidv4()}>
            <OverviewTableLeftTd>{_.get(d, 'daypart', '')}</OverviewTableLeftTd>
            <OverviewTableTd>{_.get(d, 'daypartSales', '')}</OverviewTableTd>
            <OverviewTableTd>
              {_.get(d, 'daypartSalesPercent', '')}
            </OverviewTableTd>
            {config.isDaypartCheckCountVisible ? (
              <OverviewTableTd>
                {_.get(d, 'daypartCheckCount', '')}
              </OverviewTableTd>
            ) : null}
            {config.isDaypartCheckAverageVisible ? (
              <OverviewTableTd>
                {_.get(d, 'daypartCheckAverage', '')}
              </OverviewTableTd>
            ) : null}
            {config.isDaypartHourlyLaborPercentVisible ? (
              <OverviewTableTd>
                {_.get(d, 'daypartHourlyLaborPercent', '')}
              </OverviewTableTd>
            ) : null}
          </tr>
        )
      })
  )

  return (
    <MainContainer>
      <PageHeaderLineContainer>
        <PageLeftHeader>
          {businessLabel.store}: {selectedLocation.displayName}
        </PageLeftHeader>
        <SelectorContainer>
          <LocationSelectorContainer>
            <LocationSelector navParams={navParams} />
          </LocationSelectorContainer>
          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={config.dateRangeOptions}
          />
        </SelectorContainer>
      </PageHeaderLineContainer>

      <LocationInfo locationId={selectedLocation.id} />

      <NormalizationStatusDiv>
        <NormalizationStatus
          normalizationTable="location_normalized_data_daily"
          brandId={brandSettings.brandId}
        />
      </NormalizationStatusDiv>

      {actionItemsContent}

      <VerticalSpacer80px />

      <PtdOverviewTable>
        <tbody>
          <tr>
            <td>
              <PtdKpiTable>
                <thead>
                  <tr>
                    {config.isNetSalesVisible ? (
                      <th>{storeData.netSales}</th>
                    ) : null}
                    {config.isGrossSalesVisible ? (
                      <th>{storeData.grossSales}</th>
                    ) : null}
                    {config.isNetSalesBudgetVisible ? (
                      <th>{storeData.netSalesBudget}</th>
                    ) : null}
                    {config.isNetSalesBudgetVarianceVisible ? (
                      <th>{storeData.netSalesBudgetVariance}</th>
                    ) : null}
                    {config.isNetSalesVisible ? (
                      <th>{storeData.netSalesSssPercent}</th>
                    ) : null}
                    {config.isGrossSalesVisible ? (
                      <th>{storeData.grossSalesSssPercent}</th>
                    ) : null}
                    <th>{storeData.checkCount}</th>
                    {!config.isCheckCountVsLyPercentVisible ? null : (
                      <>
                        <th>{storeData.checkCountLy}</th>
                        <th>{storeData.checkCountVsLyPercent}</th>
                      </>
                    )}
                    <th>{storeData.checkAvg}</th>
                    {!config.isSosAvgSecondsVisible ? null : (
                      <th>{storeData.sosAvgSeconds}</th>
                    )}
                    {config.avtPercent ? (
                      <DangerTh
                        danger={isTextNegativeNumber(storeData.avtPercent)}
                      >
                        {storeData.avtPercent}
                      </DangerTh>
                    ) : null}
                    {isAvtMonthlyVisible ? (
                      <DangerTh
                        danger={isTextNegativeNumber(
                          storeData.avtPercentMonthly,
                        )}
                      >
                        {storeData.avtPercentMonthly}
                      </DangerTh>
                    ) : null}
                    {config.isPlvVisible ? (
                      <DangerTh
                        danger={isTextNegativeNumber(storeData.plvPercent)}
                      >
                        {storeData.plvPercent}
                      </DangerTh>
                    ) : null}
                    {config.isPlvLastThirtyOneDaysVisible ? (
                      <th>
                        <CustomizedReport
                          reportName="LIST_LOCATION_STORE_PERFORMANCE_CUSTOMIZED"
                          customizedLoadingStatus="-"
                          inputParams={{
                            locationId: selectedLocation.id,
                          }}
                        />
                      </th>
                    ) : null}
                    {config.isCogsPercentVisible ? (
                      <th>
                        {config.usePeriodCogs &&
                        storeData.periodCogsPercentage !== '-'
                          ? storeData.periodCogsPercentage
                          : storeData.cogsPercentage}
                      </th>
                    ) : null}
                    {!config.rcp ? null : <th>{storeData.rcpPercentage}</th>}
                    {storeData.custom?.reportDefinition?.columns.map(
                      ({ key, ...column }) => (
                        <CustomizedTd
                          {...column}
                          key={key}
                          value={
                            storeData.custom.reportResult?.tableData?.[0]?.[key]
                          }
                          type="th"
                        />
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {config.isNetSalesVisible ? <td>Net Sales</td> : null}
                    {config.isGrossSalesVisible ? <td>Gross Sales</td> : null}
                    {config.isNetSalesBudgetVisible ? (
                      <td>Sales Budget</td>
                    ) : null}
                    {config.isNetSalesBudgetVarianceVisible ? (
                      <td>Sales {financialLabel.variance}</td>
                    ) : null}
                    <TdWithTooltip>
                      {financialLabel.sss}
                      {config.tooltips?.sss ? (
                        <Tooltip
                          title={config.tooltips.sss}
                          customStyle={{ position: 'absolute', top: '-3px' }}
                        />
                      ) : null}
                    </TdWithTooltip>
                    <TdWithTooltip>
                      {financialLabel.transaction}
                      {config.tooltips?.transaction ? (
                        <Tooltip
                          title={config.tooltips.transaction}
                          customStyle={{
                            position: 'absolute',
                            top: '-3px',
                          }}
                        />
                      ) : null}
                    </TdWithTooltip>
                    {!config.isCheckCountVsLyPercentVisible ? null : (
                      <>
                        <TdWithTooltip>
                          {financialLabel.transactionLy}
                        </TdWithTooltip>
                        <TdWithTooltip>
                          {financialLabel.transactionVsLyPercent}
                        </TdWithTooltip>
                      </>
                    )}
                    <TdWithTooltip>
                      {financialLabel.transactionAvg}
                      {config.tooltips?.transactionAvg ? (
                        <Tooltip
                          title={config.tooltips.transactionAvg}
                          customStyle={{
                            position: 'absolute',
                            top: '-3px',
                          }}
                        />
                      ) : null}
                    </TdWithTooltip>
                    {!config.isSosAvgSecondsVisible ? null : (
                      <TdWithTooltip>{financialLabel.sos}</TdWithTooltip>
                    )}
                    {config.avtPercent ? <td>{financialLabel.avt}</td> : null}
                    {isAvtMonthlyVisible ? <td>{financialLabel.avt}</td> : null}
                    {config.isPlvVisible ? <td>{financialLabel.plv}</td> : null}
                    {config.isPlvLastThirtyOneDaysVisible ? (
                      <TdWithTooltip>Last 30 Days PLV</TdWithTooltip>
                    ) : null}
                    {config.isCogsPercentVisible ? (
                      <TdWithTooltip>
                        {financialLabel.cogs} %
                        {config.tooltips?.cogsKpi &&
                        selectedDateRange.type === TYPE_PERIOD ? (
                          <Tooltip
                            title={config.tooltips.cogsKpi}
                            customStyle={{
                              position: 'absolute',
                              top: '-3px',
                            }}
                          />
                        ) : null}
                      </TdWithTooltip>
                    ) : null}
                    {!config.rcp ? null : <td>{financialLabel.rcp} %</td>}
                    {storeData.custom?.reportDefinition?.columns.map(
                      (column) => (
                        <td key={column.key}>{column.header}</td>
                      ),
                    )}
                  </tr>
                </tbody>
              </PtdKpiTable>
            </td>
          </tr>
        </tbody>
      </PtdOverviewTable>

      <VerticalSpacer60px />

      <WeeklyTable>
        <thead>
          <tr>
            <th></th>
            {!config.isNetSalesVisible ||
            !config.isWeeklyNetSalesVisible ? null : (
              <th>Net Sales</th>
            )}
            {!config.isGrossSalesVisible ? null : <th>Gross Sales</th>}
            {!config.isCogsVisible ? null : <th>{financialLabel.cogs}</th>}
            {!config.isCogsPercentVisible ? null : (
              <th>{financialLabel.cogs} %</th>
            )}
            {!config.avtPercent ? null : <th>{financialLabel.avt}</th>}
            {!isAvtMonthlyVisible ? null : <th>{financialLabel.avt}</th>}
            {!config.isPlvVisible ? null : <th>{financialLabel.plv}</th>}
            {!config.isHourlyLaborVisible ? null : (
              <th>{financialLabel.hourlyLabor}</th>
            )}
            {!config.isHourlyLaborVisible ? null : (
              <th>{financialLabel.hourlyLabor} %</th>
            )}
            {!config.rcp || /^lfr/.test(brand) ? null : (
              <>
                <th>{financialLabel.rcp}</th>
                <th>{financialLabel.rcp} %</th>
              </>
            )}
            {customizedTrendLocationFinancialKpis.columns.map(
              ({ key, header }) => (
                <th key={key}>{header}</th>
              ),
            )}
            {!config.rcp || !/^lfr/.test(brand) ? null : (
              <>
                <th>{financialLabel.rcp}</th>
                <th>{financialLabel.rcp} %</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {storeData.groupedData.map((data) => {
            const weekStartDate =
              data.groupByStartDate.format(DATE_DISPLAY_FORMAT)
            return (
              <tr key={weekStartDate}>
                <WeeklyTableLeftMostTd>
                  <WeekIndexDiv>
                    {breakDownName} {data[breakDownFieldName]}
                  </WeekIndexDiv>
                  <WeekDateRangeDiv>
                    {weekStartDate} -{' '}
                    {data.groupByEndDate.format(DATE_DISPLAY_FORMAT)}
                  </WeekDateRangeDiv>
                </WeeklyTableLeftMostTd>
                {!config.isNetSalesVisible ||
                !config.isWeeklyNetSalesVisible ? null : (
                  <td>{data.netSales}</td>
                )}
                {!config.isGrossSalesVisible ? null : (
                  <td>{data.grossSales}</td>
                )}
                {!config.isCogsVisible ? null : <td>{data.cogs}</td>}
                {!config.isCogsPercentVisible ? null : (
                  <td>{data.cogsPercentage}</td>
                )}
                {!config.avtPercent ? null : (
                  <DangerTd danger={isTextNegativeNumber(data.avtPercent)}>
                    {data.avtPercent}
                  </DangerTd>
                )}
                {!isAvtMonthlyVisible ? null : [
                    TYPE_QUARTER,
                    TYPE_YEAR,
                  ].includes(selectedDateRange.type) ? (
                  <DangerTd
                    danger={isTextNegativeNumber(data.avtPercentMonthly)}
                  >
                    {data.avtPercentMonthly}
                  </DangerTd>
                ) : (
                  <DangerTd
                    danger={isTextNegativeNumber(data.avtPercentWeekly)}
                  >
                    {data.avtPercentWeekly}
                  </DangerTd>
                )}
                {!config.isPlvVisible ? null : (
                  <DangerTd danger={isTextNegativeNumber(data.plvPercent)}>
                    {data.plvPercent}
                  </DangerTd>
                )}
                {!config.isHourlyLaborVisible ? null : (
                  <td>{data.hourlyLaborActual}</td>
                )}
                {!config.isHourlyLaborVisible ? null : (
                  <td>{data.hourlyLaborPercentage}</td>
                )}
                {!config.rcp || /^lfr/.test(brand) ? null : (
                  <>
                    <td>{data.rcp}</td>
                    <td>{data.rcpPercentage}</td>
                  </>
                )}
                {customizedTrendLocationFinancialKpis.columns.map(
                  ({ key, ...props }) => (
                    <CustomizedTd {...props} key={key} value={data[key]} />
                  ),
                )}
                {!config.rcp || !/^lfr/.test(brand) ? null : (
                  <>
                    <td>{data.rcp}</td>
                    <td>{data.rcpPercentage}</td>
                  </>
                )}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <WeeklyTableLeftMostTd>
              <BoldTextSpan>{breakDownSumName} Actual</BoldTextSpan>
            </WeeklyTableLeftMostTd>
            {!config.isNetSalesVisible ||
            !config.isWeeklyNetSalesVisible ? null : (
              <td>
                <BoldTextSpan>{storeData.netSales}</BoldTextSpan>
              </td>
            )}
            {!config.isGrossSalesVisible ? null : (
              <td>
                <BoldTextSpan>{storeData.grossSales}</BoldTextSpan>
              </td>
            )}
            {!config.isCogsVisible ? null : (
              <TdWithTooltip>
                <BoldTextSpan>
                  {config.usePeriodCogs ? storeData.periodCogs : storeData.cogs}
                </BoldTextSpan>
                {config.tooltips?.cogsTableSummary &&
                selectedDateRange.type === TYPE_PERIOD ? (
                  <Tooltip
                    title={config.tooltips.cogsTableSummary}
                    customStyle={{
                      position: 'absolute',
                      top: '11px',
                    }}
                  />
                ) : null}
              </TdWithTooltip>
            )}
            {!config.isCogsPercentVisible ? null : (
              <td>
                <BoldTextSpan>
                  {config.usePeriodCogs
                    ? storeData.periodCogsPercentage
                    : storeData.cogsPercentage}
                </BoldTextSpan>
              </td>
            )}
            {!config.avtPercent ? null : (
              <DangerTd danger={isTextNegativeNumber(storeData.avtPercent)}>
                <BoldTextSpan>{storeData.avtPercent}</BoldTextSpan>
              </DangerTd>
            )}
            {!isAvtMonthlyVisible ? null : (
              <DangerTd
                danger={isTextNegativeNumber(storeData.avtPercentMonthly)}
              >
                <BoldTextSpan>{storeData.avtPercentMonthly}</BoldTextSpan>
              </DangerTd>
            )}
            {!config.isPlvVisible ? null : (
              <DangerTd danger={isTextNegativeNumber(storeData.plvPercent)}>
                <BoldTextSpan>{storeData.plvPercent}</BoldTextSpan>
              </DangerTd>
            )}
            {!config.isHourlyLaborVisible ? null : (
              <td>
                <BoldTextSpan>{storeData.hourlyLaborActual}</BoldTextSpan>
              </td>
            )}
            {!config.isHourlyLaborVisible ? null : (
              <td>
                <BoldTextSpan>{storeData.laborPercentage}</BoldTextSpan>
              </td>
            )}
            {!config.rcp || /^lfr/.test(brand) ? null : (
              <>
                <td>
                  <BoldTextSpan>{storeData.rcp}</BoldTextSpan>
                </td>
                <td>
                  <BoldTextSpan>{storeData.rcpPercentage}</BoldTextSpan>
                </td>
              </>
            )}
            {customizedTrendLocationFinancialKpis.columns.map(
              ({ key, ...props }) => (
                <CustomizedTd
                  {...props}
                  key={key}
                  value={
                    customizedTrendLocationFinancialKpis.total[key]?.actual
                  }
                />
              ),
            )}
            {!config.rcp || !/^lfr/.test(brand) ? null : (
              <>
                <td>
                  <BoldTextSpan>{storeData.rcp}</BoldTextSpan>
                </td>
                <td>
                  <BoldTextSpan>{storeData.rcpPercentage}</BoldTextSpan>
                </td>
              </>
            )}
          </tr>
          {!config.isPTDBudgetVisible ? null : (
            <tr>
              <WeeklyTableLeftMostTd>
                <BoldTextSpan>
                  {breakDownSumName} {getFinancialLabel('projected')}
                </BoldTextSpan>
              </WeeklyTableLeftMostTd>
              {!config.isNetSalesVisible ||
              !config.isWeeklyNetSalesVisible ? null : (
                <td>{storeData.netSalesBudgetToDate}</td>
              )}
              {!config.isGrossSalesVisible ? null : (
                <td>{storeData.grossSalesBudgetToDate}</td>
              )}
              {!config.isCogsVisible ? null : (
                <td>
                  {config.usePeriodCogsBudget
                    ? storeData.periodCogsBudget
                    : storeData.cogsBudgetToDate}
                </td>
              )}
              {!config.isCogsPercentVisible ? null : (
                <td>
                  {config.usePeriodCogsBudget
                    ? storeData.periodCogsBudgetPercentage
                    : storeData.cogsBudgetPercentage}
                </td>
              )}
              {!config.avtPercent ? null : (
                <td>{storeData.avtBudgetPercentage}</td>
              )}
              {!isAvtMonthlyVisible ? null : (
                <td>{storeData.avtMonthlyBudgetPercentage}</td>
              )}
              {!config.isPlvVisible ? null : (
                <td>{storeData.plvBudgetPercentage}</td>
              )}
              {!config.isHourlyLaborVisible ? null : (
                <td>{storeData.hourlyLaborBudget}</td>
              )}
              {!config.isHourlyLaborVisible ? null : (
                <td>{storeData.hourlyLaborBudgetPercentage}</td>
              )}
              {!config.rcp || /^lfr/.test(brand) ? null : (
                <>
                  <td>{storeData.rcpBudgetToDate}</td>
                  <td>{storeData.rcpBudgetPercentage}</td>
                </>
              )}
              {customizedTrendLocationFinancialKpis.columns.map(
                ({ key, ...props }) => (
                  <CustomizedTd
                    {...props}
                    key={key}
                    value={
                      customizedTrendLocationFinancialKpis.total[key]?.target
                    }
                  />
                ),
              )}
              {!config.rcp || !/^lfr/.test(brand) ? null : (
                <>
                  <td>{storeData.rcpBudgetToDate}</td>
                  <td>{storeData.rcpBudgetPercentage}</td>
                </>
              )}
            </tr>
          )}
          {!config.isDifferenceVisible ? null : (
            <tr>
              <WeeklyTableLeftMostTd>
                <BoldTextSpan>
                  {config.budgetDifferenceLabel || 'Difference'}
                </BoldTextSpan>
              </WeeklyTableLeftMostTd>
              {!config.isNetSalesVisible ||
              !config.isWeeklyNetSalesVisible ? null : (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference.netSalesHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.netSales}
                  </HighlightSpan>
                </td>
              )}
              {!config.isGrossSalesVisible ? null : (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference
                        .grossSalesHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.grossSales}
                  </HighlightSpan>
                </td>
              )}
              {!config.isCogsVisible ? null : (
                <td>
                  {config.isAmountDifferenceVisible ? (
                    <HighlightSpan
                      code={
                        storeData.ptdActualBudgetDifference.cogsHighlightCode
                      }
                    >
                      {storeData.ptdActualBudgetDifference.cogs}
                    </HighlightSpan>
                  ) : null}
                </td>
              )}
              {!config.isCogsPercentVisible ? null : (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference
                        .cogsPercentHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.cogsPercent}
                  </HighlightSpan>
                </td>
              )}
              {config.avtPercent ? (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference
                        .avtPercentHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.avtPercent}
                  </HighlightSpan>
                </td>
              ) : null}
              {isAvtMonthlyVisible ? (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference
                        .avtPercentMonthlyHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.avtPercentMonthly}
                  </HighlightSpan>
                </td>
              ) : null}
              {config.isPlvVisible ? (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference
                        .plvPercentHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.plvPercent}
                  </HighlightSpan>
                </td>
              ) : null}
              {!config.isHourlyLaborVisible ? null : (
                <td>
                  {config.isAmountDifferenceVisible ? (
                    <HighlightSpan
                      code={
                        storeData.ptdActualBudgetDifference
                          .hourlyLaborHighlightCode
                      }
                    >
                      {storeData.ptdActualBudgetDifference.hourlyLabor}
                    </HighlightSpan>
                  ) : null}
                </td>
              )}
              {!config.isHourlyLaborVisible ? null : (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualBudgetDifference
                        .hourlyLaborPercentHighlightCode
                    }
                  >
                    {storeData.ptdActualBudgetDifference.hourlyLaborPercent}
                  </HighlightSpan>
                </td>
              )}
              {!config.rcp || /^lfr/.test(brand) ? null : (
                <>
                  <td>
                    <HighlightSpan
                      code={
                        storeData.ptdActualBudgetDifference.rcpHighlightCode
                      }
                    >
                      {storeData.ptdActualBudgetDifference.rcp}
                    </HighlightSpan>
                  </td>
                  <td>
                    <HighlightSpan
                      code={
                        storeData.ptdActualBudgetDifference
                          .rcpPercentHighlightCode
                      }
                    >
                      {storeData.ptdActualBudgetDifference.rcpPercent}
                    </HighlightSpan>
                  </td>
                </>
              )}
              {customizedTrendLocationFinancialKpis.columns.map(
                ({ key, reverse, ...props }) => {
                  const data = customizedTrendLocationFinancialKpis.total[key]
                  const code = (() => {
                    if (data?.target && data?.actual) {
                      if (data.actual > data.target)
                        return reverse ? 'success' : 'danger'

                      if (data.actual < data.target)
                        return reverse ? 'danger' : 'success'
                    }

                    return undefined
                  })()

                  return (
                    <CustomizedTd
                      {...props}
                      key={key}
                      value={
                        _.isNil(data?.target) || _.isNil(data?.actual)
                          ? null
                          : data.actual - data.target
                      }
                      code={code}
                    />
                  )
                },
              )}
              {!config.rcp || !/^lfr/.test(brand) ? null : (
                <>
                  <td>
                    <HighlightSpan
                      code={
                        storeData.ptdActualBudgetDifference.rcpHighlightCode
                      }
                    >
                      {storeData.ptdActualBudgetDifference.rcp}
                    </HighlightSpan>
                  </td>
                  <td>
                    <HighlightSpan
                      code={
                        storeData.ptdActualBudgetDifference
                          .rcpPercentHighlightCode
                      }
                    >
                      {storeData.ptdActualBudgetDifference.rcpPercent}
                    </HighlightSpan>
                  </td>
                </>
              )}
            </tr>
          )}
          {!config.isPTDProjectionVisible ? null : (
            <tr>
              <WeeklyTableLeftMostTd>
                <BoldTextSpan>
                  {breakDownSumName} {getFinancialLabel('projected')}
                </BoldTextSpan>
              </WeeklyTableLeftMostTd>
              {!config.isNetSalesVisible ||
              !config.isWeeklyNetSalesVisible ? null : (
                <td>{storeData.netSalesProjectionToDate}</td>
              )}
            </tr>
          )}
          {!config.isProjectionDifferenceVisible ? null : (
            <tr>
              <WeeklyTableLeftMostTd>
                <BoldTextSpan>
                  {config.projectionDifferenceLabel || 'Difference'}
                </BoldTextSpan>
              </WeeklyTableLeftMostTd>
              {!config.isNetSalesVisible ||
              !config.isWeeklyNetSalesVisible ? null : (
                <td>
                  <HighlightSpan
                    code={
                      storeData.ptdActualProjectionDifference
                        .netSalesHighlightCode
                    }
                  >
                    {storeData.ptdActualProjectionDifference.netSales}
                  </HighlightSpan>
                </td>
              )}
            </tr>
          )}
        </tfoot>
      </WeeklyTable>

      {![
        TYPE_LAST_WEEK,
        TYPE_THIS_WEEK,
        TYPE_TRAILING_7_DAYS,
        TYPE_YESTERDAY,
      ].includes(selectedDateRange.type) || config.hideWeeklytable ? null : (
        <>
          <VerticalSpacer80px />
          <WeeklyTable>
            <thead>
              <tr>
                <th></th>
                <th>Net Sales</th>
                <th>Net Sales Budget</th>
                <th>Net Sales {financialLabel.variance}</th>
                {!config.isHourlyLaborVisible ? null : (
                  <th>{financialLabel.hourlyLabor}</th>
                )}
                {!config.isHourlyLaborVisible ? null : (
                  <th>{financialLabel.hourlyLabor} %</th>
                )}
                {!config.isHourlyLaborVisible ? null : (
                  <th>
                    {financialLabel.hourlyLabor} {financialLabel.variance}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {storeData.groupedDataDaily.map((data) => {
                const weekStartDate =
                  data.groupByStartDate.format(DATE_DISPLAY_FORMAT)
                return (
                  <tr key={weekStartDate}>
                    <WeeklyTableLeftMostTd>
                      <WeekIndexDiv>{getDay(weekStartDate)}</WeekIndexDiv>
                      <WeekDateRangeDiv>{weekStartDate}</WeekDateRangeDiv>
                    </WeeklyTableLeftMostTd>
                    <td>{data.netSales}</td>
                    <td>{data.netSalesBudget}</td>
                    <td>
                      <HighlightSpan
                        code={
                          data.netSalesBudgetVarianceAmountHighlightCode ||
                          'success'
                        }
                      >
                        {data.netSalesBudgetVarianceAmount}
                      </HighlightSpan>
                    </td>
                    {!config.isHourlyLaborVisible ? null : (
                      <td>{data.hourlyLaborActual}</td>
                    )}
                    {!config.isHourlyLaborVisible ? null : (
                      <td>{data.hourlyLaborPercentage}</td>
                    )}
                    {!config.isHourlyLaborVisible ? null : (
                      <td>
                        <HighlightSpan
                          code={
                            data.hourlyLaborBudgetVarianceAmountHighlightCode ||
                            'success'
                          }
                        >
                          {data.hourlyLaborBudgetVarianceAmount}
                        </HighlightSpan>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </WeeklyTable>
        </>
      )}

      {!config.isPlvVisible ? null : (
        <>
          <VerticalSpacer60px />
          <PlvPercentTrendChart
            navParams={navParams}
            selectedDateRange={selectedDateRange}
          />
        </>
      )}

      {!config.isPerformanceTrendChartVisible ? null : (
        <>
          <VerticalSpacer60px />
          <PerformanceTrendChart
            navParams={navParams}
            selectedDateRange={selectedDateRange}
          />
        </>
      )}

      {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? null : (
        <>
          <VerticalSpacer60px />
          <TwoColumnTable>
            <tbody>
              <tr>
                <LeftHalfWidthTableContainer>
                  <FullWidthTable gap="10px">
                    <thead>
                      <tr>
                        <OverviewTableTh largerFont textAlign="left">
                          REVENUE CENTER
                        </OverviewTableTh>
                        <OverviewTableTh>
                          {config.revenueCenterHeader}
                        </OverviewTableTh>
                        <OverviewTableTh>
                          % {config.revenueCenterHeader}
                        </OverviewTableTh>
                        {config.isRevCenterGuestAverageVisible ? (
                          <OverviewTableTh>PPA</OverviewTableTh>
                        ) : null}
                        {config.isRevCenterCheckCountVisible ? (
                          <OverviewTableTh>
                            {financialLabel.transaction}
                          </OverviewTableTh>
                        ) : null}
                        {config.isRevCenterCheckAverageVisible ? (
                          <OverviewTableTh>
                            {financialLabel.transactionAvg}
                          </OverviewTableTh>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>{revCenterBreakdownRows}</tbody>
                  </FullWidthTable>
                </LeftHalfWidthTableContainer>
                <TwoColumnTableSpacer />
                <RightHalfWidthTableContainer>
                  <FullWidthTable gap="10px">
                    <thead>
                      <tr>
                        <OverviewTableTh largerFont textAlign="left">
                          DAYPART
                        </OverviewTableTh>
                        <OverviewTableTh>
                          {config.daypartHeader}
                        </OverviewTableTh>
                        <OverviewTableTh>
                          % {config.daypartHeader}
                        </OverviewTableTh>
                        {config.isDaypartCheckCountVisible ? (
                          <OverviewTableTh>
                            {financialLabel.transaction}
                          </OverviewTableTh>
                        ) : null}
                        {config.isDaypartCheckAverageVisible ? (
                          <OverviewTableTh>
                            {financialLabel.transactionAvg}
                          </OverviewTableTh>
                        ) : null}
                        {config.isDaypartHourlyLaborPercentVisible ? (
                          <OverviewTableTh>Labor %</OverviewTableTh>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>{daypartBreakdownRows}</tbody>
                  </FullWidthTable>
                  <DayPartPieChartContainer>
                    <Spin spinning={locationRevCenterBreakdownLoading}>
                      <DayPartPieChart data={daypartBreakdown} width={400} />
                    </Spin>
                  </DayPartPieChartContainer>
                </RightHalfWidthTableContainer>
              </tr>
            </tbody>
          </TwoColumnTable>

          <VerticalSpacer60px />

          <SalesTrendStackedBarChart
            navParams={navParams}
            selectedDateRange={selectedDateRange}
            trendSalesTrendStackedBarItems={trendSalesTrendStackedBarItems}
          />
        </>
      )}

      <VerticalSpacer60px />

      <TwoColumnTable>
        <tbody>
          <tr>
            {!config.isCompsVisible ? null : (
              <>
                <LeftHalfWidthTableContainer>
                  <FullWidthTable>
                    <thead>
                      <tr>
                        <OverviewTableTh largerFont textAlign="left">
                          {getFinancialLabel('comps').toUpperCase()}
                        </OverviewTableTh>
                        {config.isCompsCountVisible ? (
                          <OverviewTableTh>Qty</OverviewTableTh>
                        ) : null}
                        <OverviewTableTh>Amount</OverviewTableTh>
                        {config.isCompsPercentVisible ? (
                          <OverviewTableTh>
                            % {config.compsHeader}
                          </OverviewTableTh>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(storeData.compsBreakdown, (compsDetails) => {
                        return (
                          <tr>
                            <OverviewTableLeftTd>
                              {compsDetails.compsName}
                            </OverviewTableLeftTd>
                            {config.isCompsCountVisible ? (
                              <OverviewTableTd>
                                {compsDetails.compsCount}
                              </OverviewTableTd>
                            ) : null}
                            <OverviewTableTd>
                              {compsDetails.compsAmount}
                            </OverviewTableTd>
                            {config.isCompsPercentVisible ? (
                              <OverviewTableTd>
                                {compsDetails.compsPercentage}
                              </OverviewTableTd>
                            ) : null}
                          </tr>
                        )
                      })}
                    </tbody>
                  </FullWidthTable>
                </LeftHalfWidthTableContainer>

                <TwoColumnTableSpacer />
              </>
            )}
            <RightHalfWidthTableContainer>
              {config.isLaborOverviewVisible === false ? null : (
                <FullWidthTable>
                  <thead>
                    <tr>
                      <OverviewTableTh largerFont textAlign="left">
                        LABOR OVERVIEW
                      </OverviewTableTh>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <OverviewTableLeftTd>
                        {financialLabel.hourlyLabor} %
                      </OverviewTableLeftTd>
                      <OverviewTableTd>
                        {storeData.laborPercentage}
                      </OverviewTableTd>
                    </tr>
                    {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? null : (
                      <tr>
                        <OverviewTableLeftTd>Overtime</OverviewTableLeftTd>
                        <OverviewTableTd>{storeData.overtime}</OverviewTableTd>
                      </tr>
                    )}

                    {!config.scheduledHours ? null : (
                      <tr>
                        <OverviewTableLeftTd>
                          Actual vs Scheduled
                        </OverviewTableLeftTd>

                        <OverviewTableTd>
                          <CustomizedReport
                            reportName="LOCATION_LABOR_VARIANCE_HOURS"
                            inputParams={{
                              locationId: selectedLocation.id,
                              startDate: selectedDateRange.startDateStr,
                              endDate: selectedDateRange.endDateStr,
                            }}
                          />{' '}
                          hours
                        </OverviewTableTd>
                      </tr>
                    )}
                  </tbody>
                </FullWidthTable>
              )}
            </RightHalfWidthTableContainer>
          </tr>
        </tbody>
      </TwoColumnTable>

      <VerticalSpacer60px />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
  color: ${COLORS.Chalkboard};
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const LocationSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const NormalizationStatusDiv = styled.div`
  padding: 12px 0 0 1px;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const ActionItemContainer = styled.div`
  background-color: #fffce2;
  padding: 15px 30px;
`

const ActionItemDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  padding: 5px 0;
`

const CheckMarkSpan = styled.span`
  padding: 0 8px 0 0;
  font-style: normal;
`

const SectionHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 0 0 10px 0;
`

const PtdOverviewTable = styled.table`
  width: 100%;
`

const PtdKpiTable = styled.table`
  width: 100%;
  text-align: center;

  thead th {
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    min-width: 60px;
    flex: 1;
  }

  tr {
    display: flex;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    height: 60px;
    flex: 1;
  }
`

const WeeklyTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding: 10px 0;
    min-width: 100px;
    font-family: Lexend-SemiBold;
  }

  tbody td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 15px 0;
  }

  tfoot td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 15px 0 0 0;
  }
`

const WeeklyTableLeftMostTd = styled.td`
  width: 300px;
  text-align: left;
`

const WeekIndexDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const WeekDateRangeDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const BoldTextSpan = styled.span`
  font-family: Lexend-SemiBold;
  font-weight: 700;
`

const TwoColumnTable = styled.table`
  tbody tr {
    vertical-align: top;
  }
`

const LeftHalfWidthTableContainer = styled.td`
  width: 480px;
`

const TwoColumnTableSpacer = styled.td`
  width: 350px;
`

const RightHalfWidthTableContainer = styled.td`
  width: 400px;
`

const OverviewTableTh = styled.th<IOverviewTableTh>`
  font-family: Lexend-SemiBold;
  font-size: ${(props) => (props.largerFont ? '20px' : '14px')};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => props.textAlign || 'center'};
  vertical-align: ${(props) =>
    props.verticalAlign ? props.verticalAlign : 'middle'} !important;
`

const OverviewTableTd = styled.td<IOpenPositionsTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  text-align: ${(props) => props.textAlign || 'center'};
  color: ${(props) =>
    props.danger
      ? COLORS.Pomodoro
      : props.success
      ? COLORS.Basil
      : COLORS.Chalkboard};
`

const OverviewTableLeftTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: ${COLORS.Chalkboard};
`

const DayPartPieChartContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 30px;
`

const TdWithTooltip = styled.td`
  position: relative;
`

const DangerTh = styled.th<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? COLORS.Pomodoro : COLORS.Chalkboard)};
`

const DangerTd = styled.td<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? COLORS.Pomodoro : COLORS.Chalkboard)};
`

export default WebLargeMain
