import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { IReportResult } from 'pared/components/CustomizedReport'

import useInventoryConfig from './useInventoryConfig'

export interface ICategoryFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

const ALL_CATEGORIES = 'All Categories'

export function getCategoryFilteredReportResult(
  customizedReportResult: (IReportResult & { summaryMap?: any }) | undefined,
  selectedCategory: string,
) {
  let filteredCustomizedReportResult: any = undefined
  if (
    !selectedCategory ||
    selectedCategory === ALL_CATEGORIES ||
    !Array.isArray(customizedReportResult?.tableData)
  ) {
    const allCategorySummary = customizedReportResult?.summaryMap?.['ALL']
    filteredCustomizedReportResult = {
      ...customizedReportResult,
      summary: allCategorySummary,
    }
  } else {
    const categorySummary =
      customizedReportResult?.summaryMap?.[selectedCategory]
    filteredCustomizedReportResult = {
      ...customizedReportResult,
      tableData: _.filter(customizedReportResult?.tableData, {
        itemType: selectedCategory,
      } as any),
      summary: categorySummary,
    }
  }
  return filteredCustomizedReportResult
}

const useCategoryFilter = (customizedReportResult?: IReportResult) => {
  const [hasSetInitialDefault, setHasSetInitialDefault] =
    useState<boolean>(false)
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])
  const config = useInventoryConfig()

  const categorySet = new Set<string>()
  const categoryFilters = useMemo(() => {
    const menuItemList: ICategoryFilterType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
      {
        id: ALL_CATEGORIES,
        parentId: 'root' as const,
        value: ALL_CATEGORIES,
        text: ALL_CATEGORIES,
      },
    ]

    categorySet.clear()
    if (Array.isArray(customizedReportResult?.tableData)) {
      customizedReportResult?.tableData.forEach((rawData: any) => {
        if (rawData?.itemType) {
          categorySet.add(rawData.itemType)
        }
      })
    }

    Array.from(categorySet)
      .sort()
      .forEach((category) => {
        menuItemList.push({
          id: category,
          parentId: 'root' as const,
          value: category,
          text: category,
        })
      })

    return menuItemList
  }, [customizedReportResult])

  useEffect(() => {
    setHasSetInitialDefault(false)
  }, [customizedReportResult])

  useEffect(() => {
    if (!hasSetInitialDefault) {
      if (config?.defaultInventoryCategory) {
        if (categorySet.has(config.defaultInventoryCategory)) {
          setCategoryFilter([config.defaultInventoryCategory])
          setHasSetInitialDefault(true)
        } else {
          setCategoryFilter([categoryFilters[1].value])
        }
      } else {
        setCategoryFilter([categoryFilters[1].value])
        setHasSetInitialDefault(true)
      }
    }
  }, [categoryFilters])

  const selectedCategory = _.get(categoryFilter, '[0]')

  const categoryFilteredCustomizedReportResult =
    getCategoryFilteredReportResult(customizedReportResult, selectedCategory)

  return {
    categoryFilter,
    setCategoryFilter,
    categoryFilters,
    categoryFilteredCustomizedReportResult,
  }
}

export default useCategoryFilter
