import { useMemo } from 'react'

import {
  TYPE_LAST_WEEK,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

interface IInventoryConfig {
  defaultInventoryCategory?: string
  isPurchaseTableVisible?: boolean
  purchaseTableTitle?: string
  includeVendorInPurchaseTable?: boolean
  varianceFilter: {
    typeVisible: boolean
    kpiVisible: boolean
    unitVisible: boolean
  }
  varianceTableTitle?: string
  queryVersion?: number
  preSelectedDateRange?: string
  dateRangeOptions?: string[]
}

const defaultConfig = {
  defaultInventoryCategory: undefined,
  isPurchaseTableVisible: false,
  includeVendorInPurchaseTable: false,
  varianceFilter: {
    typeVisible: true,
    kpiVisible: true,
    unitVisible: true,
  },
  varianceTableTitle: 'Variance',
}

const useInventoryConfig = (): IInventoryConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'irmg_bk':
      case 'demo_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'irmg_pop':
      case 'supreme_pop':
      case 'demo_pop':
      case 'ace_pop':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          isPurchaseTableVisible: true,
          includeVendorInPurchaseTable: false,
        }
      case 'viking_bk':
      case 'sandbox_bk':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          isPurchaseTableVisible: true,
          includeVendorInPurchaseTable: true,
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          isPurchaseTableVisible: true,
          includeVendorInPurchaseTable: true,
          varianceTableTitle: 'AvT',
          varianceFilter: {
            typeVisible: true,
            kpiVisible: false,
            unitVisible: false,
          },
          queryVersion: 2,
        }
      case 'jitb':
        return {
          defaultInventoryCategory: 'Food',
          isPurchaseTableVisible: true,
          includeVendorInPurchaseTable: false,
          varianceFilter: {
            typeVisible: false,
            kpiVisible: false,
            unitVisible: true,
          },
        }
      case 'ghai_pop':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          isPurchaseTableVisible: true,
          includeVendorInPurchaseTable: false,
        }
      case 'bibibop':
        return {
          ...defaultConfig,
          defaultInventoryCategory: 'Food',
          isPurchaseTableVisible: true,
          purchaseTableTitle: 'Weekly Inventory',
          includeVendorInPurchaseTable: true,
          varianceTableTitle: 'AvT',
          varianceFilter: {
            typeVisible: false,
            kpiVisible: false,
            unitVisible: false,
          },
          queryVersion: 2,
          preSelectedDateRange: TYPE_LAST_WEEK,
          dateRangeOptions: [TYPE_WEEK, TYPE_LAST_WEEK, TYPE_THIS_WEEK],
        }
      case 'mwb':
      case 'burgerworks':
        return {
          ...defaultConfig,
          isPurchaseTableVisible: true,
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useInventoryConfig
