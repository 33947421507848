import { IApiType } from '../types'
import { api as bbbApi, configs as bbbConfigs } from './bbb'
import { api as commonApi, configs as commonConfigs } from './common'
import {
  api as fwWingstopApi,
  configs as fwWingstopConfigs,
} from './fwWingstop'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...commonConfigs,
  ...bbbConfigs,
  ...fwWingstopConfigs,
}

export const api = {
  ...commonApi,
  ...bbbApi,
  ...fwWingstopApi,
}

const useApi = (apiName: IApiKeyType): IApiType => api[apiName]()

export default useApi
