import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

type IDataType = Record<
  'listLocationGroupMetricValues',
  {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
>

const query = gql`
  query bbbSystemwideOverviewKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        metricData
      }
    }
  }
`

export const bbbSystemwideOverviewKpisConfigs = {
  title: 'string',
  sales: 'price',
  foodAndPaperPercent: 'percent',
  laborPercent: 'percent',
  fplPercent: 'percent',
  ebitdaBeforeBonusPercent: 'percent',
  ebitdaAfterBonus: 'price',
  ebitdaAfterBonusPercent: 'percent',
  grossProfitPercent: 'percent',
  restaurantLevelProfitPercent: 'percent',
} as const

const calc = (
  a: number | null,
  operator: '*' | '/' | '-',
  b: number | null,
) => {
  if (_.isNil(a) || _.isNil(b)) return null

  switch (operator) {
    case '*':
      return a * b
    case '/':
      return (a * 100) / b
    case '-':
      return a - b
  }
}

const useBbbSystemwideOverviewKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: groupFilter?.ids,
        metrics: [
          'total_sales',
          'total_sales_budget',
          'total_food_and_paper',
          'total_food_and_paper_budget',
          'total_labor',
          'total_labor_budget',
          'ebitda_before_bonus',
          'ebitda_before_bonus_budget',
          'ebitda_after_bonus',
          'ebitda_after_bonus_budget',
          'gross_profit',
          'gross_profit_budget',
          'restaurant_level_profit',
          'restaurant_level_profit_budget',
        ],
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const metricData = data.listLocationGroupMetricValues.nodes.reduce(
        (result, { metricData }) =>
          Object.keys(metricData).reduce((subResult, key) => {
            const newKey = _.camelCase(key.replace(/^total_/, ''))

            return {
              ...subResult,
              [newKey]: (subResult[newKey] || 0) + metricData[key].value,
            }
          }, result),
        {} as Record<string, number | null>,
      )

      metricData.fpl =
        _.isNil(metricData.foodAndPaper) && _.isNil(metricData.labor)
          ? null
          : (metricData.foodAndPaper || 0) + (metricData.labor || 0)
      metricData.fplBudget =
        _.isNil(metricData.foodAndPaperBudget) &&
        _.isNil(metricData.laborBudget)
          ? null
          : (metricData.foodAndPaperBudget || 0) + (metricData.laborBudget || 0)

      return {
        source: (['Actual', 'Budget', 'Difference'] as const).map((type) =>
          (
            Object.keys(
              bbbSystemwideOverviewKpisConfigs,
            ) as (keyof typeof bbbSystemwideOverviewKpisConfigs)[]
          ).reduce(
            (result, key) => {
              if (key === 'title') return result

              switch (type) {
                case 'Actual':
                  if (/Percent$/.test(key))
                    return {
                      ...result,
                      [key]: calc(
                        metricData[key.replace(/Percent$/, '')],
                        '/',
                        metricData.sales,
                      ),
                    }

                  return {
                    ...result,
                    [key]: metricData[key],
                  }
                case 'Budget':
                  if (/Percent$/.test(key))
                    return {
                      ...result,
                      [key]: calc(
                        metricData[key.replace(/Percent$/, 'Budget')],
                        '/',
                        metricData.salesBudget,
                      ),
                    }

                  return {
                    ...result,
                    [key]: metricData[`${key}Budget`],
                  }

                default:
                  if (/Percent$/.test(key))
                    return {
                      ...result,
                      [key]: calc(
                        calc(
                          calc(
                            metricData[key.replace(/Percent$/, '')],
                            '/',
                            metricData.sales,
                          ),
                          '-',
                          calc(
                            metricData[key.replace(/Percent$/, 'Budget')],
                            '/',
                            metricData.salesBudget,
                          ),
                        ),
                        '*',
                        [
                          'foodAndPaperPercent',
                          'laborPercent',
                          'fplPercent',
                        ].includes(key)
                          ? -1
                          : 1,
                      ),
                    }

                  return {
                    ...result,
                    [key]: calc(
                      metricData[key],
                      '-',
                      metricData[`${key}Budget`],
                    ),
                  }
              }
            },
            {
              title: type,
            },
          ),
        ),
      }
    }, [data]),
    loading,
  }
}

export default useBbbSystemwideOverviewKpis
