import * as d3 from 'd3-hierarchy'
import moment from 'moment'
import { useCallback } from 'react'

import { IDateDataType } from '../../variables'

type IOptionType = IDateDataType['options'][number]

const getIds = ({
  data,
  parent,
}: d3.HierarchyNode<IOptionType>): IOptionType['id'][] =>
  data.id === 'root' ? [] : [...(!parent ? [] : getIds(parent)), data.id]

const getFirstDate = ({
  data,
  children,
}: d3.HierarchyNode<IOptionType>): IOptionType =>
  !children || children.length === 0 ? data : getFirstDate(children[0])

const getDate = (
  [id, ...ids]: IOptionType['id'][],
  optionsTree: d3.HierarchyNode<IOptionType>,
): IOptionType | null => {
  const date = optionsTree.children?.find((c) => c.data.id === id)

  if (ids.length === 0 || !date)
    return date ? getFirstDate(date) : getFirstDate(optionsTree)

  if ('type' in date.data) return date.data

  return getDate(ids, date)
}

const getNewDate = (
  [currentId, ...currentIds]: IOptionType['id'][],
  [newId, ...newIds]: (IOptionType['id'] | moment.Moment)[],
  optionsTree: d3.HierarchyNode<IOptionType>,
): IOptionType | null => {
  if (currentId === newId) {
    const date = optionsTree.children?.find((c) => c.data.id === currentId)

    return !date ? null : getNewDate(currentIds, newIds, date)
  }

  if (typeof newId === 'string' && /year root/.test(newId)) {
    const newYear = newId.replace(/(\d+).+/, '$1')
    const currentYear = currentId.replace(/(\d+).+/, '$1')

    return getDate(
      ([newId, ...newIds] as IOptionType['id'][]).map(
        (id) => id.replace(currentYear, newYear) as IOptionType['id'],
      ),
      optionsTree,
    )
  }

  switch (currentId) {
    case 'custom_date': {
      const customDateNode = optionsTree.find(
        (o) => 'type' in o.data && o.data.type === 'custom_date',
      )

      if (!customDateNode || typeof newId === 'string') return null

      return {
        ...customDateNode.data,
        dateRange: {
          startDate: newId,
          startDateStr: newId.format('YYYY-MM-DD'),
          endDate: newId,
          endDateStr: newId.format('YYYY-MM-DD'),
        },
      } as IOptionType
    }

    case 'custom_date_range': {
      const customDateNode = optionsTree.find(
        (o) => 'type' in o.data && o.data.type === 'custom_date_range',
      )
      const endDate = newIds[0]

      if (
        !customDateNode ||
        typeof newId === 'string' ||
        !endDate ||
        typeof endDate === 'string'
      )
        return null

      return {
        ...customDateNode.data,
        dateRange: {
          startDate: newId,
          startDateStr: newId.format('YYYY-MM-DD'),
          endDate,
          endDateStr: endDate.format('YYYY-MM-DD'),
        },
      } as IOptionType
    }
  }

  return getDate([newId, ...newIds] as IOptionType['id'][], optionsTree)
}

const useDateChange = (
  optionsTree: d3.HierarchyNode<IOptionType>,
  currentOptionsTree: d3.HierarchyNode<IOptionType> | null,
  setDate: (date: IDateDataType['value']) => void,
) =>
  useCallback(
    (newIds: (IOptionType['id'] | moment.Moment)[]) => {
      if (!currentOptionsTree) return null

      const currentIds = getIds(currentOptionsTree)
      const newDate = getNewDate(currentIds, newIds, optionsTree)

      if (newDate) setDate(newDate as IDateDataType['value'])
    },
    [optionsTree, currentOptionsTree, setDate],
  )

export default useDateChange
