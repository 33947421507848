import { IRouteType } from '../../types'
import wingitnorthDailyFlash from './wingitnorthDailyFlash'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Daily Flash',
        link: '/:brand/daily_flash',
        default: true,
      },
    ],
  },
]

export default [wingitnorthDailyFlash]
