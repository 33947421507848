import axios from 'axios'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import {
  AI_FEEDBACK_EXPO_FAILED_TO_ANSWER,
  AI_FEEDBACK_USER_DISSATISFIED,
  AI_FEEDBACK_USER_SATISFIED,
} from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import ExpoLogo from 'pared/images/Expo/expo-icon-100x100.png'
import LocationDeliverySummary from 'pared/pages/Delivery/Summary'
import Summary from 'pared/pages/ExpoAi/Summary'
import Table from 'pared/pages/ExpoAi/Table'
import Trend from 'pared/pages/ExpoAi/Trend'
import {
  FROM_EXPO,
  FROM_USER,
  NUMBER_OF_PREVIOUS_QUESTIONS_TO_CONSIDER_TOGETHER,
  PAGE_PADDING_LEFT,
} from 'pared/pages/ExpoAi/constants'
import { IChatBoxConfig } from 'pared/pages/ExpoAi/hooks/useConfig'
import useConfig from 'pared/pages/ExpoAi/hooks/useConfig'
import { IMessage } from 'pared/pages/ExpoAi/type'
import SalesPerLaborHourChart from 'pared/pages/v2/Salesmanship/SalesPerLaborHour'
import { getUser } from 'pared/utils/user'

import CannedQuestions from './CannedQuestions'
import ChatInput from './ChatInput'
import formatDate from './formatDate'
import handleCannedQuestionAnswer from './responseHandler/handleCannedQuestionAnswer'
import handleDeliveryKpiRankings from './responseHandler/handleDeliveryKpiRankings'
import handleDeliveryKpiTrends from './responseHandler/handleDeliveryKpiTrends'
import handleDeliveryStoreEmployeeKpis from './responseHandler/handleDeliveryStoreEmployeeKpis'
import handleErrors from './responseHandler/handleErrors'
import handleForecastedResults from './responseHandler/handleForecastedResults'
import handleFssKpiRankings from './responseHandler/handleFssKpiRankings'
import handleFssKpiValues from './responseHandler/handleFssKpiValues'
import handleGuestKpiRankings from './responseHandler/handleGuestKpiRankings'
import handleGuestKpiTrends from './responseHandler/handleGuestKpiTrends'
import handleGuestSuggestions from './responseHandler/handleGuestSuggestions'
import handleInventoryKpiValues from './responseHandler/handleInventoryKpiValues'
import handleInventoryLocationSuggestions from './responseHandler/handleInventoryLocationSuggestions'
import handleKpiAnalyzerAnswers from './responseHandler/handleKpiAnalyzerAnswers'
import handleLossPreventionKpiTrends from './responseHandler/handleLossPreventionKpiTrends'
import handleLossPreventionKpiValues from './responseHandler/handleLossPreventionKpiValues'
import handleLossPreventionStoreEmployeeKpis from './responseHandler/handleLossPreventionStoreEmployeeKpis'
import handlePmixKpiValues from './responseHandler/handlePmixKpiValues'
import handlePurchaseTrends from './responseHandler/handlePurchaseTrends'
import handleSalesmanshipEmployeeKpis from './responseHandler/handleSalesmanshipEmployeeKpis'
import handleSalesmanshipKpiTrends from './responseHandler/handleSalesmanshipKpiTrends'
import handleSalesmanshipKpiValues from './responseHandler/handleSalesmanshipKpiValues'

export interface IPropsType extends Partial<IChatBoxConfig> {
  type: 'chatbox'
}

const ChatboxWrapper = styled.div`
  margin-top: 40px;
  margin-left: -50px;
`

const ButtonsContainer = styled.div`
  font-family: Lexend-Regular;
  display: flex;
  flex-direction: column;
  padding: 10px ${PAGE_PADDING_LEFT + 30}px;
`

const ButtonRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-left: 50px;
`

const OptionButton = styled.button<{ isSelected: boolean }>`
  font-family: Lexend-Regular;
  margin-right: 10px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#007bff' : '#eeeeee')};
  color: ${(props) => (props.isSelected ? '#fff' : '#000')};
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
`

const ButtonGroupLabel = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

const MessageContainer = styled.div<{ messageFrom?: string }>`
  ${(props) => {
    if (props.messageFrom === FROM_USER) {
      return `background-color: #F1F1F1;`
    }
    return ''
  }}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 30px 0;
`

const MessageFromContainer = styled.div`
  margin: 0 0 0 ${PAGE_PADDING_LEFT}px;
  width: 80px;

  @media ${MOBILE_WIDTH} {
    margin: 0 0 0 20px;
  }
`

const UserMessageContent = styled.div`
  padding: 8px 0 0 0;
  color: ${COLORS.Chalkboard};
  font-size: 18px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    padding: 8px 20px 0 0;
  }
`

const ExpoMessageContent = styled.div`
  padding: 8px 0 0 0;
  color: ${COLORS.Chalkboard};
  font-size: 18px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;

  @media ${MOBILE_WIDTH} {
    margin: 0 20px;
    overflow: hidden;
  }
`

const TextOnlyMessage = styled.div`
  max-width: 800px;
`

const UserInitialIcon = styled.div`
  width: 45px;
  height: 45px;
  background: #f5b332;
  color: ${COLORS.Salt};
  font-size: 25px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  text-align: center;
`

const LoadingStatus = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: italic;
  font-weight: 700;
  color: ${COLORS.Steel};
  padding: 25px 0 0 ${PAGE_PADDING_LEFT + 20}px;
`

const LogoImg = styled.img`
  height: 40px;
  width: 40px;
`

const UserFeedbackForAiAnswer = styled.div`
  padding: 20px 0 0 0;
  font-size: 14px;
  font-style: italic;

  span {
    color: ${COLORS.Link};
    padding: 0 10px;
    cursor: pointer;
  }
`

let cachedMessages: any[] = []
let userQuestions: string[] = []
let lastGptAnswer: any = null

interface IMessagePaddingContent {
  question: string
  gptAnswer: any
}

function padMsg(
  originalMessage: IMessage,
  { question, gptAnswer }: IMessagePaddingContent,
) {
  return {
    ...originalMessage,
    question,
    gptAnswer,
  }
}

interface IFeedback {
  feedback: string
}

const Chatbox: React.FC<IPropsType> = (props) => {
  const { groupFilter } = useGroupFilter()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messages, setMessages] = useState<IMessage[]>([...cachedMessages])
  const [inputValue, setInputValue] = useState('')
  const [selectedFirstOption, setSelectedFirstOption] = useState<string>('')
  const [selectedSecondOption, setSelectedSecondOption] = useState<string>('')

  const locationName = groupFilter?.label || ''

  const firstRowOptions = ['Sales', 'Revenue Center', 'Daypart']
  const secondRowOptions = ['90 days', '30 days', '7 days', 'yesterday']

  useEffect(() => {
    if (selectedFirstOption && !selectedSecondOption) {
      setSelectedSecondOption('30 days')
    }
  }, [selectedFirstOption])

  useEffect(() => {
    if (selectedSecondOption && !selectedFirstOption) {
      setSelectedFirstOption('Sales')
    }
  }, [selectedSecondOption])

  useEffect(() => {
    if (selectedFirstOption && selectedSecondOption) {
      const timePeriodText =
        selectedSecondOption === 'yesterday'
          ? 'for yesterday'
          : `over the past ${selectedSecondOption}`
      const message = `${selectedFirstOption} Analysis${
        locationName ? ` for ${locationName}` : ''
      } ${timePeriodText}`
      setInputValue(message)
    }
  }, [selectedFirstOption, selectedSecondOption])

  const setAndCacheMessages = (newMessages: any[]) => {
    cachedMessages = [...newMessages]
    setMessages(cachedMessages)
  }

  const { brand, brandId, brandLocationGroupId } = getBrandSettings()
  const user = getUser()
  const userInitial = (
    (user?.firstName || '').substring(0, 1) || 'u'
  ).toUpperCase()
  const userEmail = (user.email || '').trim()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  async function recordUserFeedback(
    messageFromExpo: IMessage,
    { feedback }: IFeedback,
    shouldUpdateUi: boolean,
  ) {
    if (shouldUpdateUi) {
      const allMessages = [...messages]
      const messageCount = allMessages.length
      for (let mi = messageCount - 1; mi >= 0; --mi) {
        const msg = allMessages[mi]
        if (msg.id === messageFromExpo.id) {
          allMessages[mi] = {
            ...msg,
            feedback,
          }
          break
        }
      }
      setAndCacheMessages(allMessages)
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/record_feedback_for_ai_answer`,
        {
          brandId,
          userEmail,
          feedback,
          question: messageFromExpo.question,
          gptAnswer: messageFromExpo.gptAnswer || null,
          customerBrandCode: brand,
          userId: user.userId,
          userJwt: user.jwt,
        },
        {
          timeout: 60000,
        },
      )
    } catch (error) {
      console.error('Error:', error)
      // mute the error
    }
  }

  const config = {
    ...useConfig().chatBox,
    ...props,
  }

  const handleFormSubmit = async (
    eventOrQuestion: FormEvent<HTMLFormElement> | string,
  ) => {
    let question = inputValue.trim()

    if (typeof eventOrQuestion === 'string') {
      question = eventOrQuestion
    } else {
      eventOrQuestion.preventDefault()
      eventOrQuestion.stopPropagation()
    }

    if (!question) {
      return
    }

    const newMessage: IMessage = {
      id: uuidv4(),
      from: FROM_USER,
      content: question,
    }

    let newMessages = [...messages, newMessage]
    setAndCacheMessages(newMessages)
    setInputValue('')
    setIsLoading(true)

    const isExpoUser =
      userEmail.endsWith('@getexpo.com') || userEmail.endsWith('@pared.com')

    let response: any = null
    let hasAnswer = false

    const previousQuestions = userQuestions.slice(
      -NUMBER_OF_PREVIOUS_QUESTIONS_TO_CONSIDER_TOGETHER,
    )

    userQuestions.push(question)

    try {
      response = await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/answer_question`,
        {
          brandId,
          isExpoUser,
          userEmail,
          question,
          previousQuestions,
          lastGptAnswer,
          customerBrandCode: brand,
          userId: user.userId,
          userJwt: user.jwt,
          locationId: groupFilter?.ids[0],
        },
        {
          timeout: 60000,
        },
      )
      setIsLoading(false)
    } catch (error) {
      console.error('Error:', error)
      setIsLoading(false)
    }

    const responseData = response?.data?.answer
    const gptAnswer = response?.data?.lastGptAnswer || null
    lastGptAnswer = gptAnswer

    if (responseData?.nativeGptAnswer) {
      hasAnswer = true
      const answerMessage: IMessage = {
        id: uuidv4(),
        from: FROM_EXPO,
        content: (
          <TextOnlyMessage>{responseData?.nativeGptAnswer}</TextOnlyMessage>
        ),
      }
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    let answerMessage = handleErrors(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleKpiAnalyzerAnswers(
      responseData,
      handleCannedQuestionClicked,
    )
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    if (
      responseData?.financialKpiValues ||
      responseData?.deliveryKpiValues ||
      responseData?.guestKpiValues ||
      responseData?.storeRanks ||
      responseData?.directorRanks
    ) {
      const answerMessage: IMessage = {
        id: uuidv4(),
        from: FROM_EXPO,
        content: (
          <Table
            data={
              responseData?.financialKpiValues ||
              responseData?.deliveryKpiValues ||
              responseData?.guestKpiValues ||
              responseData?.storeRanks ||
              responseData?.directorRanks
            }
          />
        ),
      }
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    if (responseData?.financialKpiTrends) {
      const answerMessage: IMessage = {
        id: uuidv4(),
        from: FROM_EXPO,
        content: <Trend data={responseData?.financialKpiTrends} />,
      }
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    if (responseData?.salesPerLaborHourSuggestions) {
      const suggestions = responseData?.salesPerLaborHourSuggestions
      const answerMessage: IMessage = {
        id: uuidv4(),
        from: FROM_EXPO,
        content: suggestions.error ? (
          suggestions.error
        ) : (
          <div>
            <div>
              {suggestions.locationName} ({formatDate(suggestions.startDate)} ~{' '}
              {formatDate(suggestions.endDate)})
            </div>
            <div style={{ padding: '20px 0 0 0' }}>
              <SalesPerLaborHourChart
                locationName={suggestions.locationName}
                locationId={suggestions.locationId}
                startDate={suggestions.startDate}
                endDate={suggestions.endDate}
              />
            </div>
          </div>
        ),
      }
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    if (responseData?.locationDeliverySuggestions) {
      const suggestions = responseData?.locationDeliverySuggestions
      const answerMessage: IMessage = {
        id: uuidv4(),
        from: FROM_EXPO,
        content: suggestions.error ? (
          suggestions.error
        ) : (
          <div>
            <div>
              {suggestions.locationName} ({formatDate(suggestions.startDate)} ~{' '}
              {formatDate(suggestions.endDate)})
            </div>
            <div style={{ padding: '20px 0 0 0' }}>
              <LocationDeliverySummary
                locationName={suggestions.locationName}
                locationId={suggestions.locationId}
                startDateStr={suggestions.startDate}
                endDateStr={suggestions.endDate}
              />
            </div>
          </div>
        ),
      }
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    if (
      responseData?.locationGroupLaborSummary ||
      responseData?.locationGroupSalesmanshipSummary ||
      responseData?.locationGroupLossPreventionSummary ||
      responseData?.locationGroupInventorySummary ||
      responseData?.locationGroupDeliverySummary ||
      responseData?.locationGroupTeamSummary
    ) {
      const summary =
        responseData?.locationGroupLaborSummary ||
        responseData?.locationGroupSalesmanshipSummary ||
        responseData?.locationGroupLossPreventionSummary ||
        responseData?.locationGroupInventorySummary ||
        responseData?.locationGroupDeliverySummary ||
        responseData?.locationGroupTeamSummary
      const answerMessage: IMessage = {
        id: uuidv4(),
        from: FROM_EXPO,
        content: (
          <div>
            <div>
              {summary.locationGroupName} ({formatDate(summary.startDate)} ~{' '}
              {formatDate(summary.endDate)})
            </div>
            <div style={{ padding: '20px 0 0 0' }}>
              <Summary
                summaryType={summary.summaryType}
                locationGroupId={summary.locationGroupId}
                brandLocationGroupId={brandLocationGroupId}
                startDate={summary.startDate}
                endDate={summary.endDate}
              />
            </div>
          </div>
        ),
      }
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleLossPreventionKpiValues(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleLossPreventionKpiTrends(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleLossPreventionStoreEmployeeKpis(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleInventoryKpiValues(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleInventoryLocationSuggestions(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handlePurchaseTrends(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleDeliveryKpiTrends(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleDeliveryKpiRankings(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleDeliveryStoreEmployeeKpis(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleSalesmanshipKpiValues(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleCannedQuestionAnswer(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleSalesmanshipKpiTrends(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleSalesmanshipEmployeeKpis(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleGuestKpiTrends(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleGuestKpiRankings(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handlePmixKpiValues(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleFssKpiValues(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleFssKpiRankings(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleGuestSuggestions(responseData, brandLocationGroupId)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    answerMessage = handleForecastedResults(responseData)
    if (answerMessage) {
      hasAnswer = true
      newMessages = [
        ...newMessages,
        padMsg(answerMessage, { question, gptAnswer }),
      ]
      setAndCacheMessages(newMessages)
    }

    if (!hasAnswer) {
      const feedback = AI_FEEDBACK_EXPO_FAILED_TO_ANSWER
      const answerMessage = padMsg(
        {
          feedback,
          id: uuidv4(),
          from: FROM_EXPO,
          content: `We don't have the answer for that right now, but we're working on it.`,
        },
        { question, gptAnswer },
      )
      newMessages = [...newMessages, answerMessage]
      setAndCacheMessages(newMessages)
      await recordUserFeedback(answerMessage, { feedback }, false)
    }
  }

  function handleCannedQuestionClicked(questionText: string) {
    setInputValue(questionText)
    window.scrollTo(0, document.body.scrollHeight)
  }

  return (
    <ChatboxWrapper>
      {config?.hasSalesAnalysisButton ? (
        <ButtonsContainer>
          <ButtonGroupLabel>
            What are my Strengths and Opportunities in:
          </ButtonGroupLabel>
          <ButtonRow>
            {firstRowOptions.map((option) => (
              <OptionButton
                key={option}
                isSelected={selectedFirstOption === option}
                onClick={() => setSelectedFirstOption(option)}
              >
                {option}
              </OptionButton>
            ))}
          </ButtonRow>
          <ButtonGroupLabel>Looking at last:</ButtonGroupLabel>
          <ButtonRow>
            {secondRowOptions.map((option) => (
              <OptionButton
                key={option}
                isSelected={selectedSecondOption === option}
                onClick={() => setSelectedSecondOption(option)}
              >
                {option}
              </OptionButton>
            ))}
          </ButtonRow>
        </ButtonsContainer>
      ) : null}
      {config?.hasCannedQuestions ? (
        <CannedQuestions
          onQuestionClicked={handleCannedQuestionClicked}
          config={config}
        />
      ) : null}
      <div className="chatbox">
        {messages.map((message) => (
          <MessageContainer key={message.id} messageFrom={message.from}>
            <MessageFromContainer>
              {message.from === FROM_EXPO ? (
                <LogoImg src={ExpoLogo} alt="Expo Logo" />
              ) : (
                <UserInitialIcon>{userInitial}</UserInitialIcon>
              )}
            </MessageFromContainer>
            {message.from === FROM_EXPO ? (
              <ExpoMessageContent>
                <div>{message.content}</div>
                {message.isFeedbackVisible === false ||
                message.feedback ===
                  AI_FEEDBACK_EXPO_FAILED_TO_ANSWER ? null : (
                  <UserFeedbackForAiAnswer>
                    {message.feedback === AI_FEEDBACK_USER_SATISFIED ? (
                      'Thank you for your feedback!'
                    ) : message.feedback === AI_FEEDBACK_USER_DISSATISFIED ? (
                      'Thank you for your feedback! We will continue to make improvements to better meet your needs as soon as possible.'
                    ) : (
                      <>
                        Did this answer your question?
                        <span
                          onClick={() => {
                            recordUserFeedback(
                              message,
                              {
                                feedback: AI_FEEDBACK_USER_SATISFIED,
                              },
                              true,
                            )
                          }}
                        >
                          Yes
                        </span>
                        or
                        <span
                          onClick={() => {
                            recordUserFeedback(
                              message,
                              {
                                feedback: AI_FEEDBACK_USER_DISSATISFIED,
                              },
                              true,
                            )
                          }}
                        >
                          No
                        </span>
                      </>
                    )}
                  </UserFeedbackForAiAnswer>
                )}
              </ExpoMessageContent>
            ) : (
              <UserMessageContent>
                <TextOnlyMessage>{message.content}</TextOnlyMessage>
              </UserMessageContent>
            )}
          </MessageContainer>
        ))}
      </div>

      {isLoading ? <LoadingStatus>Loading ...</LoadingStatus> : null}

      <ChatInput
        isLoading={isLoading}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        config={config}
      />
    </ChatboxWrapper>
  )
}

export default Chatbox
