import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import useFetchGroupMetricValue from './useFetchGroupMetricValue'

const useBbbCorpCtrlExpenses = (): IApiDataType => {
  const { variables } = useVariables()

  const { data, loading } = useFetchGroupMetricValue([
    'total_controllable_expenses_without_bank_charges_and_third_party_fees',
    'total_controllable_expenses_without_bank_charges_and_third_party_fees_budget',
    'total_controllable_expenses_percent',
    'total_controllable_expenses_budget_percent',
  ])

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const ctrlExpenses =
        data.total_controllable_expenses_without_bank_charges_and_third_party_fees ??
        0
      const ctrlExpensesBudget =
        data.total_controllable_expenses_without_bank_charges_and_third_party_fees_budget ??
        0
      const ctrlExpensesPct = data.total_controllable_expenses_percent ?? 0
      const ctrlExpensesBudgetPct =
        data.total_controllable_expenses_budget_percent ?? 0
      const priorCtrlExpensesPct =
        data.prior_total_controllable_expenses_percent ?? 0
      const yoyCtrlExpensesPct =
        data.yoy_total_controllable_expenses_percent ?? 0

      const ctrlExpensesVarPct = ctrlExpensesPct - ctrlExpensesBudgetPct

      // ADD TEXT HERE START

      const ctrlExpensesVarPctText =
        ctrlExpensesVarPct >= 0
          ? `Missing the ${toPercentString(
              ctrlExpensesBudgetPct,
            )} budget by ${toPercentString(ctrlExpensesVarPct)}`
          : `Beating the ${toPercentString(
              ctrlExpensesBudgetPct,
            )} budget by ${toPercentString(-ctrlExpensesVarPct)}`

      const ctrlExpensesVarPctPeriodChgText =
        ctrlExpensesPct >= priorCtrlExpensesPct
          ? `Increasing ${toPercentString(
              ctrlExpensesPct - priorCtrlExpensesPct,
            )} from Prior Period of ${toPercentString(priorCtrlExpensesPct)}`
          : `Decreasing ${toPercentString(
              priorCtrlExpensesPct - ctrlExpensesPct,
            )} from Prior Period of ${toPercentString(priorCtrlExpensesPct)}`

      const ctrlExpensesVarPctYoyChgText =
        ctrlExpensesPct >= yoyCtrlExpensesPct
          ? `Increasing ${toPercentString(
              ctrlExpensesPct - yoyCtrlExpensesPct,
            )} from Prior Year of ${toPercentString(yoyCtrlExpensesPct)}`
          : `Decreasing ${toPercentString(
              yoyCtrlExpensesPct - ctrlExpensesPct,
            )} from Prior Year of ${toPercentString(yoyCtrlExpensesPct)}`

      // ADD TEXT HERE END

      return {
        title: `Controllable Expenses`,
        total: ctrlExpenses,
        summary: `Controllable Expenses in ${
          currentPeriod?.displayName
        } was ${toPercentString(ctrlExpensesPct)}`,
        detail: `
          <ul>
            <li>${ctrlExpensesVarPctText} or ${toUsdString(
          Math.abs(ctrlExpenses - ctrlExpensesBudget),
        )}</li>
            <li>${ctrlExpensesVarPctPeriodChgText}</li>
            <li>${ctrlExpensesVarPctYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [data, variables]),
    loading,
  }
}

export default useBbbCorpCtrlExpenses
