import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType, IDataType } from '../types'
import { useCorporateGroupFilterQuery } from './useCorporateFilter'

const buildBbbCorporateGroupFilterHook = (type: 'brand' | 'company') => {
  const useBbbCorporateGroupFilterHook = () => {
    const { brand } = useBrands()
    const locationGroupIds =
      type === 'company' ? [38] : [BRAND_LOCATION_GROUP_ID[brand]]
    const directorLabel = getBusinessLabel('director')
    const { startDate, endDate } = useDateFilter()
    const {
      data: newData,
      loading,
      previousData,
    } = useCorporateGroupFilterQuery(
      {
        iFilter: {
          location_group_ids: locationGroupIds,
          brand_ids: [BRAND_ID[brand]],
        },
        iStartDate: startDate,
        iEndDate: endDate,
        iBrandId: BRAND_ID[brand],
      },
      !startDate || !endDate,
    )
    const data = newData || previousData

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const locationGroups = (
          data?.getLocationAccessGroupsForBrand?.nodes || []
        ).filter(
          (d) =>
            ![
              'Brand',
              'Custom',
              'null',
              'Company',
              'City',
              'Legal Entity',
              'Company (Period)',
            ].includes(d.type),
        )
        const locationGroupTypes = locationGroups.reduce(
          (result, { type }) =>
            result.includes(type) ? result : [...result, type],
          [] as string[],
        )

        const allStores = data.listLocationDetails.nodes.map((l) => {
          const directors = data.listDirectors.nodes
            .filter((d) =>
              l.locationGroups.some((lg) => lg.id === d.locationGroupId),
            )
            .sort((a, b) => {
              const locationGroupA = l.locationGroups.find(
                (lg) => lg.id === a.locationGroupId,
              )
              const locationGroupB = l.locationGroups.find(
                (lg) => lg.id === b.locationGroupId,
              )

              if (!locationGroupA || !locationGroupB) return 0

              return moment
                .utc(locationGroupA.end_date)
                .isSameOrBefore(moment.utc(locationGroupB.end_date))
                ? 1
                : -1
            })
          const director = directors[0]
          const locationGroup = locationGroups.find(
            (lg) => lg.id === director?.locationGroupId,
          )

          return {
            id: l.id,
            name: `${l.code} - ${l.name}`,
            header: 'Stores',
            link: `/${brand}/store_detail?store=${l.id}`,
            groupBy: director && {
              id: director.employeeId,
              header: locationGroup?.type || directorLabel,
              name: `${director.firstName} ${director.lastName}`,
            },
          }
        })

        const cities = locationGroups
          .filter((lg) => lg.type === 'State')
          .map((lg) => {
            const cityLocationGroups = data.listLocationDetails.nodes
              .filter((l) => l.locationGroups.some((llg) => llg.id === lg.id))
              .map((l) => l.locationGroups.filter((llg) => llg.type === 'City'))
              .flat()
              .reduce((result, { id, name }) => {
                if (result.some((d) => d.id === id.toString())) return result

                return [
                  ...result,
                  {
                    id: id.toString(),
                    parentId: lg.id.toString(),
                    ids: [id],
                    label: name,
                    list: data.listLocationDetails.nodes
                      .filter((l) =>
                        l.locationGroups.some((lg) => lg.id === id),
                      )
                      .map((l) => {
                        const director = data.listDirectors.nodes.find((d) =>
                          l.locationGroups.some(
                            (lg) => lg.id === d.locationGroupId,
                          ),
                        )
                        const locationGroup = locationGroups.find(
                          (lg) => lg.id === director?.locationGroupId,
                        )

                        return {
                          id: l.id,
                          name: `${l.code} - ${l.name}`,
                          header: 'Stores',
                          link: `/${brand}/store_detail?store=${l.id}`,
                          groupBy: director && {
                            id: director.employeeId,
                            header: locationGroup?.type || directorLabel,
                            name: `${director.firstName} ${director.lastName}`,
                          },
                        }
                      }),
                  },
                ]
              }, [] as IDataType[])

            return [
              {
                id: `${lg.name} Breakdown`,
                parentId: lg.id.toString(),
                label: `Breakdown By ${lg.name}`,
                ids: cityLocationGroups.map((c) => c.ids).flat(),
                list: cityLocationGroups.map((c) => ({
                  id: parseInt(c.id),
                  name: c.label,
                  header: 'City',
                })),
              },
              ...cityLocationGroups,
            ]
          })
          .flat()

        const values = [
          {
            id: 'all-stores',
            parentId: 'root',
            ids: locationGroupIds,
            label: 'All Stores',
            list: allStores,
          },
          ...(type === 'brand'
            ? []
            : [
                {
                  id: 'opened-stores',
                  parentId: 'root',
                  label: 'Opened Stores',
                },
              ]),
          ...locationGroupTypes.map((locationGroupType) => [
            {
              id: locationGroupType,
              parentId: type === 'brand' ? 'root' : 'opened-stores',
              label: locationGroupType,
            },
            {
              id: `${locationGroupType} Breakdown`,
              parentId: locationGroupType,
              label: `Breakdown By ${locationGroupType}`,
              ids: locationGroups
                .filter((l) => l.type === locationGroupType)
                .map((d) => d.id),
              list: locationGroups
                .filter((l) => l.type === locationGroupType)
                .map((d) => ({
                  id: d.id,
                  name: d.name,
                  header: d.type,
                })),
            },
          ]),
          locationGroups.map(({ id, type, name }) => ({
            id: id.toString(),
            parentId: type,
            ids: [id],
            label: name,
            list: data.listLocationDetails.nodes
              .filter((l) => l.locationGroups.some((lg) => lg.id === id))
              .map((l) => {
                const director = data.listDirectors.nodes.find((d) =>
                  l.locationGroups.some((lg) => lg.id === d.locationGroupId),
                )
                const locationGroup = locationGroups.find(
                  (lg) => lg.id === director?.locationGroupId,
                )

                return {
                  id: l.id,
                  name: `${l.code} - ${l.name}`,
                  header: 'Stores',
                  link: `/${brand}/store_detail?store=${l.id}`,
                  groupBy: director && {
                    id: director.employeeId,
                    header: locationGroup?.type || directorLabel,
                    name: `${director.firstName} ${director.lastName}`,
                  },
                }
              }),
          })),
          ...cities,
        ].flat()

        return {
          values:
            locationGroupTypes.length !== 1
              ? values
              : values
                  .filter(({ id }) => id !== locationGroupTypes[0])
                  .map(({ parentId, ...value }) => ({
                    ...value,
                    parentId:
                      parentId === locationGroupTypes[0] ? 'root' : parentId,
                  })),
          defaultValue: [values[0].id],
        }
      }, [data, brand, directorLabel]),
      loading,
    }
  }

  return useBbbCorporateGroupFilterHook
}

export const useBbbCorporateFilter = buildBbbCorporateGroupFilterHook('brand')
export const useBbbPnlCorporateFilter =
  buildBbbCorporateGroupFilterHook('company')
