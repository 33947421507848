import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const ghaiPopHome: IConfigsType = {
  ghai_pop: {
    '/:brand/scorecard': {
      groupFilter: {
        api: 'locationFilter',
      },
      title: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        fields: ['sales', 'ghaiPopFoodCost', 'ghaiPopLabor', 'guest'],
      },
    },
  },
}

export default ghaiPopHome
