import { useMemo } from 'react'

import { IApiDataType } from '../types'
import {
  useBbbCorporateFilter,
  useBbbPnlCorporateFilter,
} from './useBbbCorporateFilter'

const buildBbbCorporateWithoutBreakdownFilter = (
  useHook: typeof useBbbCorporateFilter | typeof useBbbPnlCorporateFilter,
) => {
  const useBbbCorporateWithoutBreakdownFilter = () => {
    const { data, loading } = useHook()

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const { values, defaultValue } = data
        const statesItem = values.filter((v) => v.id === 'State Breakdown')?.[0]
        const states =
          statesItem && 'list' in statesItem ? statesItem.list || [] : []
        const newValues = values.filter((v) => !/Breakdown By/.test(v.label))
        const valueLastIndex = [...newValues]
          .reverse()
          .findIndex((v) => v.parentId === 'State')

        newValues.splice(
          newValues.length - valueLastIndex,
          0,
          ...states.map((s) => ({
            id: `All Stores in ${s.id}`,
            parentId: s.id.toString(),
            ids: [s.id],
            label: `All Stores in ${s.name}`,
            list: values
              .filter(
                (v) =>
                  !/Breakdown By/.test(v.label) &&
                  v.parentId === s.id.toString(),
              )
              .map((v) => (v && 'list' in v ? v.list : []))
              .flat(),
          })),
        )

        return {
          values: newValues,
          defaultValue,
        }
      }, [data]),
      loading,
    }
  }
  return useBbbCorporateWithoutBreakdownFilter
}

export const useBbbCorporateWithoutBreakdownFilter =
  buildBbbCorporateWithoutBreakdownFilter(useBbbCorporateFilter)

export const useBbbPnlCorporateWithoutBreakdownFilter =
  buildBbbCorporateWithoutBreakdownFilter(useBbbPnlCorporateFilter)
