import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'
import ExpoAiLogo from 'pared/images/Expo/expoai.png'
import { getUser } from 'pared/utils/user'

import ChatBox from './ChatBox'
import Exray from './Exray'
import { PAGE_PADDING_LEFT } from './constants'
import useConfig from './hooks/useConfig'

const MainContainer = styled.div`
  padding: 0 0 50px 0;
`

const PageHeader = styled.div`
  padding: 10px 0 30px ${PAGE_PADDING_LEFT}px;

  @media ${MOBILE_WIDTH} {
    padding: 30px 0 30px 20px;
  }
`

function ExpoAi() {
  const config = useConfig()
  const user = getUser()
  return (
    <MainContainer>
      <PageHeader>
        <img height={40} src={ExpoAiLogo} alt="Expo AI" />
      </PageHeader>
      {config.showExray && !user.isStoreLevelUser ? <Exray /> : null}
      {config.showChatBox ? <ChatBox /> : null}
    </MainContainer>
  )
}

export default ExpoAi
