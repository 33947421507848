import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { toPercentString, toUsdString } from 'pared/utils/number'

interface IBasePropsType {
  value: unknown
  loading: boolean
  width?: string
  danger?: string
}

export type IPropsType =
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
    })

const Th = styled.th<{ isLoading: boolean; width?: string; danger?: boolean }>`
  ${({ width }) =>
    width
      ? `
          min-width: ${width};
        `
      : `
          min-width: 120px;
          max-width: 150px;
        `}
  color: ${({ danger }) => (danger ? COLORS.Pomodoro : 'black')};}
  font-family: ${({ isLoading }) =>
    isLoading ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${({ isLoading }) => (isLoading ? '18px' : '30px')};
  font-weight: ${({ isLoading }) => (isLoading ? '400' : '700')};
`

const Format = ({ value, loading, width, ...props }: IPropsType) => {
  switch (props.type) {
    case 'price':
      return (
        <Th isLoading={loading} width={width}>
          {toUsdString(parseFloat(value as string) / 100, props.decimal)}
        </Th>
      )

    case 'percent':
      const danger =
        props.danger === 'positive'
          ? (value as number) > 0
          : (value as number) < 0

      return (
        <Th
          isLoading={loading}
          width={width}
          danger={props.reverse ? !danger : danger}
        >
          {toPercentString(value as string, props.decimal)}
        </Th>
      )

    case 'number': {
      if (value === '-' || value === undefined || value === null)
        return (
          <Th isLoading={loading} width={width}>
            -
          </Th>
        )

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)

        return (
          <Th isLoading={loading} width={width}>
            {numberValue.toLocaleString('en-US', {
              minimumFractionDigits: props.decimal || 0,
              maximumFractionDigits: props.decimal || 0,
            })}
          </Th>
        )
      }

      return (
        <Th isLoading={loading} width={width}>
          {(value as number).toLocaleString('en-US', {
            minimumFractionDigits: props.decimal || 0,
            maximumFractionDigits: props.decimal || 0,
          })}
        </Th>
      )
    }
  }
}

export default Format
