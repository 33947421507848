import useTrendLocationGroupFinancialKpis, {
  trendLocationGroupFinancialKpisConfigs,
} from './useTrendLocationGroupFinancialKpis'

export const configs = {
  trendLocationGroupFinancialKpis: trendLocationGroupFinancialKpisConfigs,
}

export const api = {
  trendLocationGroupFinancialKpis: useTrendLocationGroupFinancialKpis,
}
