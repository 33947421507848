import { IRouteType } from '../../types'
import burgerworksSystemwideOverview from './burgerworksSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
    ],
  },
]

export default [burgerworksSystemwideOverview]
