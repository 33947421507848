import { useMemo } from 'react'

import { getBrandSettings } from 'pared/customer'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

interface IConfig {
  checkCount: 'checkCount' | 'totalCheckCount' | 'totalGuestCount'
  checkAverage: 'checkAverage' | 'totalCheckAverage' | 'totalGuestAverage'
  isCheckCountVsLyPercentVisible?: boolean
  avtPercent: boolean
  avtPercentMonthly: boolean
  isPlvVisible?: boolean
  rcp: boolean
  isPTDBudgetVisible: boolean
  isDifferenceVisible: boolean
  budgetDifferenceLabel?: string
  isPTDProjectionVisible?: boolean
  isProjectionDifferenceVisible?: boolean
  projectionDifferenceLabel?: string
  isAmountDifferenceVisible: boolean
  isNetSalesVisible: boolean
  isWeeklyNetSalesVisible: boolean
  isGrossSalesVisible: boolean
  isNetSalesBudgetVisible?: boolean
  isNetSalesBudgetVarianceVisible?: boolean
  tooltips?: {
    sss?: string
    transaction?: string
    transactionAvg?: string
    cogsKpi?: string
    cogsTableSummary?: string
  }
  scheduledHours: boolean
  usePeriodCogs: boolean
  usePeriodCogsBudget?: boolean
  isRevCenterGuestAverageVisible?: boolean
  compsHeader: string
  isCompsVisible: boolean
  isCompsCountVisible?: boolean
  isCompsPercentVisible: boolean
  isCogsVisible: boolean
  isCogsPercentVisible: boolean
  isHourlyLaborVisible: boolean
  isSosAvgSecondsVisible: boolean
  isPlvLastThirtyOneDaysVisible?: boolean
  useCustomizedTrendLocationFinancialKpis: boolean
  useListLocationStoresCustomizedExtendTable?: boolean
  daypartHeader: string
  revenueCenterHeader: string
  revenueCenterNameMap?: {
    [rawRevenueCenterName: string]: string | undefined
  }
  dateRangeOptions?: string[]
  isLaborOverviewVisible?: boolean
  isDaypartCheckCountVisible?: boolean
  isDaypartCheckAverageVisible?: boolean
  isDaypartHourlyLaborPercentVisible?: boolean
  isRevCenterCheckCountVisible?: boolean
  isRevCenterCheckAverageVisible?: boolean
  revCenterOrder?: { [revenueCenter: string]: number | undefined }
  hideWeeklytable?: boolean
  overtime?: string
  isPerformanceTrendChartVisible?: boolean
  trendSalesTrendStackedBarItems?: string[]
}

export default (): IConfig => {
  const brand = getBrand()
  const brandSettings = getBrandSettings()

  return useMemo(() => {
    switch (brand) {
      case 'demo_bk':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          isPlvVisible: true,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'irmg_bk':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          isPlvVisible: true,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'irmg_pop':
      case 'demo_pop':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: true,
          tooltips: {
            cogsKpi:
              'Estimated COGS% until Ending Period Inventory is completed',
            cogsTableSummary:
              'True COGS is updated once Ending Period Inventory is completed',
          },
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'supreme_pop':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          isCheckCountVsLyPercentVisible: true,
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: true,
          tooltips: {
            cogsKpi:
              'Estimated COGS% until Ending Period Inventory is completed',
            cogsTableSummary:
              'True COGS is updated once Ending Period Inventory is completed',
          },
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: true,
          useCustomizedTrendLocationFinancialKpis: true,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'ghai_pop':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: true,
          usePeriodCogsBudget: true,
          tooltips: {
            cogsKpi:
              'Estimated COGS% until Ending Period Inventory is completed',
            cogsTableSummary:
              'True COGS is updated once Ending Period Inventory is completed',
          },
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: true,
          daypartHeader: 'Net Sales',
          isDaypartCheckCountVisible: true,
          isDaypartCheckAverageVisible: true,
          revenueCenterHeader: 'Net Sales',
          isRevCenterCheckCountVisible: true,
          isRevCenterCheckAverageVisible: true,
          compsHeader: 'Net Sales',
          isCompsCountVisible: true,
        }
      case 'viking_bk':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          isPlvVisible: true,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: true,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: false,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          isDaypartCheckCountVisible: true,
          isDaypartCheckAverageVisible: true,
          isRevCenterCheckCountVisible: true,
          isRevCenterCheckAverageVisible: true,
          revCenterOrder: {
            'Drive Thru': 1,
            'Take Out': 2,
            'Eat In': 3,
            Delivery: 4,
            Mobile: 5,
            Kiosk: 6,
          },
        }
      case 'supreme_bk':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          isPlvVisible: true,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: true,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          isPlvLastThirtyOneDaysVisible: true,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'timoney_bk':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          isPlvVisible: true,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: true,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'sandbox_bk':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: true,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: true,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: true,
          isPlvVisible: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: true,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: false,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          revenueCenterNameMap: {
            DRIVE_THRU: 'Drive Thru',
            EAT_IN: 'Eat In',
            ONLINE: 'Online',
            PICKUP: 'Pickup',
            TAKE_OUT: 'Take Out',
          },
          isLaborOverviewVisible: false,
          compsHeader: 'Net Sales',
        }
      case 'wingitnorth':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          avtPercent: true,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          isDaypartCheckCountVisible: true,
          isDaypartCheckAverageVisible: true,
          isRevCenterCheckCountVisible: true,
          isRevCenterCheckAverageVisible: true,
        }
      case 'wingstop':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'cava':
        return {
          checkCount: 'totalGuestCount',
          checkAverage: 'totalGuestAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: false,
          isNetSalesBudgetVisible: true,
          isNetSalesBudgetVarianceVisible: true,
          tooltips: {
            sss: 'Same Restaurant Sales',
            transaction: '1 Guest per Entree',
            transactionAvg: 'Per Person Average - Total Sales / # of Entrees',
          },
          scheduledHours: false,
          usePeriodCogs: false,
          isRevCenterGuestAverageVisible: true,
          isCompsVisible: true,
          isCompsCountVisible: true,
          isCompsPercentVisible: false,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'jitb':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
        }
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: true,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: true,
          daypartHeader: 'Sales',
          revenueCenterHeader: 'Sales',
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_CUSTOM,
          ],
          compsHeader: 'Sales',
        }
      case 'primanti':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          isCheckCountVsLyPercentVisible: true,
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: false,
          isDifferenceVisible: false,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: false,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
        }
      case 'bibibop':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible: false,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: false,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: false,
          isLaborOverviewVisible: false,
          isSosAvgSecondsVisible: false,
          useListLocationStoresCustomizedExtendTable: true,
          useCustomizedTrendLocationFinancialKpis: true,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          hideWeeklytable: true,
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
          isPerformanceTrendChartVisible: true,
        }
      case 'mwb':
      case 'burgerworks':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: false,
          isDifferenceVisible: true,
          budgetDifferenceLabel: 'Actual vs Plan',
          isPTDProjectionVisible: false,
          isProjectionDifferenceVisible: true,
          projectionDifferenceLabel: 'Actual vs Projection',
          isNetSalesVisible: true,
          isWeeklyNetSalesVisible: true,
          isGrossSalesVisible: false,
          isAmountDifferenceVisible: false,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: false,
          isCogsPercentVisible: false,
          isHourlyLaborVisible: false,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: true,
          daypartHeader: 'Net Sales',
          isDaypartCheckCountVisible: true,
          isDaypartCheckAverageVisible: true,
          isDaypartHourlyLaborPercentVisible: true,
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
            TYPE_TRAILING_90_DAYS,
            TYPE_CUSTOM,
          ],
          useListLocationStoresCustomizedExtendTable: true,
          isLaborOverviewVisible: true,
          isRevCenterCheckCountVisible: true,
          isRevCenterCheckAverageVisible: true,
        }
      case 'sullivans':
      case 'sullivans_nso':
      case 'eddiemerlots':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          isCheckCountVsLyPercentVisible: true,
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: true,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
        }
      case 'fw_wingstop':
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          hideWeeklytable: true,
          dateRangeOptions: [
            TYPE_YESTERDAY,
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_TRAILING_7_DAYS,
            TYPE_CUSTOM,
          ],
          overtime: 'hour',
        }
      case 'ace_pop':
        return {
          checkCount: 'totalCheckCount',
          checkAverage: 'totalCheckAverage',
          isCheckCountVsLyPercentVisible: true,
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: false,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          scheduledHours: false,
          usePeriodCogs: true,
          tooltips: {
            cogsKpi:
              'Estimated COGS% until Ending Period Inventory is completed',
            cogsTableSummary:
              'True COGS is updated once Ending Period Inventory is completed',
          },
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: true,
          useCustomizedTrendLocationFinancialKpis: true,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          revenueCenterNameMap: {
            Delivery: 'Delivery',
            'Door Dash': ' 。 Door Dash',
            EZCater: ' 。 EZCater',
            'Grub Hub': ' 。 Grub Hub',
            Postmates: ' 。 Postmates',
            'Uber Eats': ' 。 Uber Eats',
            'Dine In': 'Dine In',
            'Drive Thru': 'Drive Thru',
            'RBI Mobile': 'RBI Mobile',
            'Take Out': 'Take Out',
          },
          revCenterOrder: {
            Delivery: 1,
            'Door Dash': 2,
            EZCater: 3,
            'Grub Hub': 4,
            Postmates: 5,
            'Uber Eats': 6,
            'Dine In': 7,
            'Drive Thru': 8,
            'RBI Mobile': 9,
            'Take Out': 10,
          },
          trendSalesTrendStackedBarItems: [
            'Dine In',
            'Door Dash',
            'Drive Thru',
            'EZCater',
            'Grub Hub',
            'Postmates',
            'RBI Mobile',
            'Take Out',
            'Uber Eats',
          ],
        }
      default:
        return {
          checkCount: 'checkCount',
          checkAverage: 'checkAverage',
          avtPercent: false,
          avtPercentMonthly: false,
          rcp: true,
          isPTDBudgetVisible: true,
          isDifferenceVisible: true,
          isNetSalesVisible: brandSettings.overview.netSales.isVisible ?? true,
          isWeeklyNetSalesVisible:
            brandSettings.overview.netSales.isVisible ?? true,
          isGrossSalesVisible:
            brandSettings.overview.grossSales.isVisible ?? false,
          isAmountDifferenceVisible: true,
          isNetSalesBudgetVisible: false,
          isNetSalesBudgetVarianceVisible: false,
          tooltips: {},
          scheduledHours: false,
          usePeriodCogs: false,
          isCompsVisible: true,
          isCompsPercentVisible: true,
          isCogsVisible: true,
          isCogsPercentVisible: true,
          isHourlyLaborVisible: true,
          isSosAvgSecondsVisible: false,
          useCustomizedTrendLocationFinancialKpis: false,
          daypartHeader: 'Net Sales',
          revenueCenterHeader: 'Net Sales',
          compsHeader: 'Net Sales',
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
        }
    }
  }, [brand])
}
