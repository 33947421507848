import { useMemo } from 'react'

import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

interface IConfig {
  dateRangeOptions: string[]
  preSelectedDateRange: string
}

const useConfig = (): IConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'mwb':
      case 'burgerworks':
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
            TYPE_TRAILING_90_DAYS,
            TYPE_CUSTOM,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }
      case 'ace_pop':
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }
      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_CUSTOM,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }

      default:
        return {
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
          ],
          preSelectedDateRange: TYPE_PERIOD,
        }
    }
  }, [brand])
}

export default useConfig
