import useItemByDaypart, { itemByDaypartConfigs } from './useItemByDaypart'
import useLocationGroupDayPart, {
  locationGroupDayPartConfigs,
} from './useLocationGroupDayPart'
import useLocationGroupRevenueCenter, {
  locationGroupRevenueCenterConfigs,
} from './useLocationGroupRevenueCenter'
import useLocationGroups, { locationGroupsConfigs } from './useLocationGroups'
import useLocations, { locationsConfigs } from './useLocations'
import useMadeFromScratch, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'
import useMadeFromScratchSummary, {
  madeFromScratchSummaryConfigs,
} from './useMadeFromScratchSummary'

export const configs = {
  locationGroups: locationGroupsConfigs,
  locations: locationsConfigs,
  itemByDaypart: itemByDaypartConfigs,
  madeFromScratch: madeFromScratchConfigs,
  madeFromScratchSummary: madeFromScratchSummaryConfigs,
  locationGroupDayPart: locationGroupDayPartConfigs,
  locationGroupRevenueCenter: locationGroupRevenueCenterConfigs,
}

export const api = {
  locationGroups: useLocationGroups,
  locations: useLocations,
  itemByDaypart: useItemByDaypart,
  madeFromScratch: useMadeFromScratch,
  madeFromScratchSummary: useMadeFromScratchSummary,
  locationGroupDayPart: useLocationGroupDayPart,
  locationGroupRevenueCenter: useLocationGroupRevenueCenter,
}
