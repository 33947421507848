import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE'
    value: number
  }
>

type IDataType<
  T extends string =
    | 'listLocationGroupMetricValues'
    | 'listLocationMetricValues',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupMetricValues'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) &
      Record<'metricData' | 'metricSummaryData', IMetricDataType>)[]
  }
>

const query = gql`
  query metricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
    $hasSummary: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData @include(if: $hasSummary)
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData @include(if: $hasSummary)
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const buildMetricValuesHook = (
  metrics: (string | { key: string; type: 'yoy' })[],
  handler: (data: Record<string, unknown>) => Record<string, unknown> = (
    data,
  ) => data,
  hasSummary: boolean = false,
) => {
  const useMetricValues = () => {
    const { variables } = useVariables()
    const { startDate, endDate } = useDateFilter()
    const { groupFilter, hasGroupBy } = useGroupFilter()
    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: hasGroupBy
            ? groupFilter?.ids
            : groupFilter?.list?.map((g) => g.id),
          metrics: metrics
            .map((m) => (typeof m === 'string' ? m : null))
            .filter(Boolean),
        },
        hasGroupBy,
        hasSummary,
      },
      skip:
        !startDate ||
        !endDate ||
        !groupFilter ||
        !metrics.some((m) => typeof m === 'string'),
    })
    const { data: yoyData, loading: yoyLoading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: hasGroupBy
            ? groupFilter?.ids
            : groupFilter?.list?.map((g) => g.id),
          metrics: metrics
            .map((m) =>
              typeof m !== 'string' && m.type === 'yoy' ? m.key : null,
            )
            .filter(Boolean),
          use_yoy: true,
        },
        hasGroupBy,
        hasSummary,
      },
      skip:
        !startDate ||
        !endDate ||
        !groupFilter ||
        !metrics.some((m) => typeof m !== 'string' && m.type === 'yoy'),
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const nodes = (
          data.listLocationGroupMetricValues || data.listLocationMetricValues
        ).nodes
        const yoyNodes =
          (
            yoyData?.listLocationGroupMetricValues ||
            yoyData?.listLocationMetricValues
          )?.nodes || []
        const { locations, locationGroups } = variables.corporateGroup || {}
        const summary = {}
        const newData = nodes.map((n) => {
          const id = (() => {
            if (hasGroupBy && locations && 'locationId' in n)
              return n.locationId
            if (!hasGroupBy && locationGroups && 'locationGroupId' in n)
              return n.locationGroupId
            return -1
          })()
          const yoyN = yoyNodes.find((yn) =>
            'locationId' in yn
              ? yn.locationId === id
              : yn.locationGroupId === id,
          )
          const tableRow = (locations || locationGroups)?.[id].tableRow

          if (n.metricSummaryData && hasSummary)
            Object.assign(
              summary,
              n.metricSummaryData,
              _.mapKeys(
                yoyN?.metricSummaryData || {},
                (_, key) => `yoy_${key}`,
              ),
            )

          return {
            ...tableRow,
            ...handler(
              format({
                ...n.metricData,
                ..._.mapKeys(yoyN?.metricData || {}, (_, key) => `yoy_${key}`),
              }),
            ),
            id: id.toString(),
            parentId: hasSummary ? 'summary' : 'root',
          }
        })

        return [
          ...(!hasSummary
            ? []
            : [
                {
                  ...handler(format(summary)),
                  [variables.corporateGroup?.tableColumns[0].key as string]: {
                    label: 'TOTAL',
                  },
                  id: 'summary',
                  parentId: 'root',
                },
              ]),
          ...newData,
        ]
      }, [variables, hasGroupBy, data, yoyData]),
      loading: loading || yoyLoading,
    }
  }

  return useMetricValues
}

const isNumber = (d: unknown): d is number => typeof d === 'number'

export const calc = (a: unknown, operator: '*' | '/' | '-', b: unknown) => {
  if (!isNumber(a) || !isNumber(b)) return null

  switch (operator) {
    case '*':
      return a * b
    case '/':
      return b === 0 ? null : a / b
    case '-':
      return a - b
  }
}

export default buildMetricValuesHook
