import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import useFetchGroupMetricValue from './useFetchGroupMetricValue'

const useBbbCorpEbitda = (): IApiDataType => {
  const { variables } = useVariables()
  const { data, loading } = useFetchGroupMetricValue([
    'ebidta_without_bonus',
    'ebidta_without_bonus_budget',
    'check_count',
    'check_average',
  ])

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const ebitda = data.ebidta_without_bonus ?? 0
      const priorEbitda = data.prior_ebidta_without_bonus ?? 0
      const yoyEbitda = data.yoy_ebidta_without_bonus ?? 0
      const ebitdaBudget = data.ebidta_without_bonus_budget ?? 0
      const ebitdaVar = ebitda - ebitdaBudget

      // ADD TEXT HERE START

      const ebitdaVarText =
        ebitdaVar <= 0
          ? `Missing the ${toUsdString(ebitdaBudget)} budget by ${toUsdString(
              -ebitdaVar,
            )}`
          : `Beating the ${toUsdString(ebitdaBudget)} budget by ${toUsdString(
              ebitdaVar,
            )}`

      const ebitdaVarPeriodChgText =
        priorEbitda === 0
          ? `No EBITDA data from Prior Period for comparison`
          : ebitda >= priorEbitda
          ? `Increasing ${toPercentString(
              ((ebitda - priorEbitda) / priorEbitda) * 100,
            )} from Prior Period of ${toUsdString(priorEbitda)}`
          : `Decreasing ${toPercentString(
              ((priorEbitda - ebitda) / priorEbitda) * 100,
            )} from Prior Period of ${toUsdString(priorEbitda)}`

      const ebitdaYoyChgText =
        yoyEbitda === 0
          ? `No EBITDA data from Prior Year for comparison`
          : ebitda >= yoyEbitda
          ? `Increasing ${toPercentString(
              ((ebitda - yoyEbitda) / yoyEbitda) * 100,
            )} from Prior Year of ${toUsdString(yoyEbitda)}`
          : `Decreasing ${toPercentString(
              ((yoyEbitda - ebitda) / yoyEbitda) * 100,
            )} from Prior Year of ${toUsdString(yoyEbitda)}`

      // ADD TEXT HERE END

      return {
        title: `EBITDA`,
        total: ebitdaVar * -1,
        summary: `EBITDA in ${currentPeriod?.displayName} was ${toUsdString(
          ebitda,
        )}`,
        detail: `
          <ul>
            <li>${ebitdaVarText}</li>
            <li>${ebitdaVarPeriodChgText}</li>
            <li>${ebitdaYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [data, variables]),
    loading,
  }
}

export default useBbbCorpEbitda
