import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { getBusinessLabel } from 'pared/customer'
import { getBrandId } from 'pared/utils/brand'

import { QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND } from '../gql'
import useConfig from './useConfig'

const useGroupSelector = ({
  initialGroupBy,
  shouldHideBreakdown,
  isBrandVisible,
  startDate = '1900-01-01',
  endDate = '9999-12-31',
}: {
  initialGroupBy: string
  shouldHideBreakdown?: boolean
  isBrandVisible?: boolean
  startDate?: string
  endDate?: string
}) => {
  const config = useConfig()
  const [groupBy, setGroupBy] = useState([initialGroupBy])

  const { data } = useQuery(QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND, {
    variables: {
      iBrandId: getBrandId(),
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: config.shouldHideGroups,
  })

  const locationGroups =
    data?.getLocationAccessGroupsForBrand?.nodes.filter((n) =>
      endDate === '9999-12-31' ? n.endDate === '9999-12-31' : true,
    ) || []

  const locationGroupOptions = useMemo(() => {
    const locationGroupTypes = config.shouldHideGroups
      ? []
      : config.locationGroupTypes.map(({ name }) => ({
          id: name,
          parentId: 'root',
          value: name,
          text: name,
        }))

    const locationGroupBreakdown =
      config.shouldHideGroups || shouldHideBreakdown
        ? []
        : config.locationGroupTypes.map(({ name }) => ({
            id: `${name}Breakdown`,
            parentId: name,
            value: `${name}Breakdown`,
            text: `Breakdown By ${name}`,
          }))

    const directorBreakdown = !config.isDirectorBreakdownVisible
      ? []
      : [
          {
            id: 'director',
            parentId: 'root',
            value: 'director',
            text: `${getBusinessLabel('director')}`,
          },
        ]

    const options = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
      {
        id: 'store',
        parentId: 'root',
        value: 'store',
        text: `All ${getBusinessLabel('store')}s`,
      },
      ...(isBrandVisible
        ? [
            {
              id: 'brand',
              parentId: 'root',
              value: 'Brand',
              text: 'Company',
            },
          ]
        : []),
      ...locationGroupTypes,
      ...locationGroupBreakdown,
      ...directorBreakdown,
    ]

    if (!config.shouldHideGroups) {
      for (const group of locationGroups) {
        const type = group.type
        const id = `${group.id}`
        const name = group.name

        if (
          type === 'null' ||
          type === 'Brand' ||
          !config.locationGroupTypes.some((t) => t.name === type)
        ) {
          continue
        }

        options.push({
          id,
          parentId: `${type}`,
          value: id,
          text: name,
        })
      }
    }

    return options
  }, [locationGroups])

  return {
    groupBy,
    groupByType: !/Breakdown$/.test(groupBy[1] || '') ? 'store' : groupBy[0],
    locationGroupId: groupBy[1],
    setGroupBy,
    options: locationGroupOptions,
  }
}

export default useGroupSelector
