import { corporateGroupTableConfigs } from '../../../variables'
import buildMetricValuesHook, { calc } from '../utils/buildMetricValuesHook'

export const bbbFlashConfigs = {
  ...corporateGroupTableConfigs,
  totalSales: 'price',
  totalSalesBudget: 'price',
  totalSalesBudgetVariance: 'percent',
  pyNetSales: 'price',
  pyNetSalesPercent: 'percent',
  onlinePercent: 'percent',
  deliveryPercent: 'percent',
  catering: 'price',
  checkCount: 'number',
  yoyCheckCountGrowth: 'percent',
  checkAverage: 'price',
  yoyCheckAverage: 'price',
  checkAverageSssPercent: 'percent',
  discount: 'price',
  discountPercent: 'percent',
  employeeDiscount: 'price',
  voids: 'price',
  cashOverOrShort: 'price',
  hourlyLaborHours: 'number',
  overtimeHours: 'number',
  salesPerLaborHour: 'price',
  laborCostPercentage: 'percent',
  cookiesPer100Orders: 'number',
  avocadoPer100Orders: 'number',
  customerRating: 'number',
  numberOfComplaints: 'number',
  bonus: 'price',
} as const

const useBbbFlash = buildMetricValuesHook(
  [
    'total_sales',
    'total_sales_budget',
    'py_net_sales',
    'py_net_sales_percent',
    'online_percent',
    'delivery_percent',
    'catering',
    'check_count',
    'yoy_check_count_growth',
    'check_average',
    { key: 'check_average', type: 'yoy' },
    'discount',
    'discount_percent',
    'employee_discount',
    'voids',
    'cash_over_or_short',
    'hourly_labor_hours',
    'overtime_hours',
    'sales_per_labor_hour',
    'labor_cost_percentage',
    'cookies_per_100_orders',
    'avocado_per_100_orders',
    'bonus',
  ],
  (data) => ({
    ...data,
    totalSalesBudgetVariance: calc(
      calc(
        calc(data.totalSales, '-', data.totalSalesBudget),
        '/',
        data.totalSalesBudget,
      ),
      '*',
      100,
    ),
    checkAverageSssPercent: calc(
      calc(
        calc(data.checkAverage, '-', data.yoyCheckAverage),
        '/',
        data.yoyCheckAverage,
      ),
      '*',
      100,
    ),
  }),
  true,
)

export default useBbbFlash
