export interface IDayPartSequence {
  [dayPartName: string]: number
}

export function getIsQsr(brand: string): boolean {
  switch (brand) {
    case 'sg': // SweetGreen
    case 'qsr_shack': // The QSR Shack
      return true
    default:
      return false
  }
}

export function getIsLegacyBrand(brand: string): boolean {
  switch (brand) {
    case 'rmg': // Romano's Macaroni Grill
    case 'rmg_nso': // RMG NSO
    case 'sullivans': // Sullivan's
    case 'sullivans_nso': // Sullivan's NSO
    case 'eddiemerlots': // Eddie Merlot's
      return true
    default:
      return false
  }
}

export function getIsProductionBrand(brand: string): boolean {
  switch (brand) {
    case 'rmg': // Romano's Macaroni Grill
    case 'rmg_nso': // RMG NSO
    case 'sullivans': // Sullivan's
    case 'sullivans_nso': // Sullivan's NSO
    case 'eddiemerlots': // Eddie Merlot's
    case 'cava':
    case 'irmg_bk': // IRMG Burger King
    case 'irmg_pop':
    case 'supreme_pop':
    case 'viking_bk': // Viking Burger King
    case 'jsc_bk': // JSC Burger King
    case 'jsc_bk_nso':
    case 'supreme_bk': // Supreme Foods Burger King
    case 'timoney_bk': // Timoney Burger King
    case 'mbn_bk': // MBN Buger King
    case 'ghai_pop': // GHAI Popeyes
    case 'jitb':
    // Local Favorite
    case 'lfr':
    case 'lfr_ef':
    case 'lfr_tr':
    case 'lfr_sn':
    case 'lfr_lv':
    case 'lfr_vb':
    case 'lfr_tu':
    case 'lfr_ws':
    case 'lfr_jn':
    case 'lfr_mm':
    case 'lfr_ol':
    case 'lfr_dt':
    case 'lfr_uv':
    case 'lfr_bth':
    case 'wingitnorth':
    case 'mwb':
    case 'burgerworks':
    case 'bibibop':
    case 'fw_wingstop':
    case 'ace_pop':
      return true
    default:
      return false
  }
}

export function getDayPartSequence(brand: string): IDayPartSequence {
  switch (brand) {
    case 'rmg': // Romano's Macaroni Grill
    case 'rmg_nso': // RMG NSO
    case 'sullivans': // Sullivan's
    case 'sullivans_nso': // Sullivan's NSO
    case 'eddiemerlots': // Eddie Merlot's
      return { Lunch: 1, Dinner: 2, _default_: 999999 }
    default:
      return { Lunch: 1, Dinner: 2, _default_: 999999 }
  }
}

export function getLocationGroups(brand: string) {
  switch (brand) {
    case 'cava':
      return ['Multi-Unit', 'Garden', 'RD']
    case 'supreme_bk':
      return ['Division']
    case 'supreme_pop':
      return ['Region']
    case 'mbn_bk':
      return ['DO']
    case 'jsc_bk':
    case 'jsc_bk_nso':
      return ['Comp or Non-Comp', 'Region']
    case 'irmg_pop':
    case 'demo_pop':
    case 'ghai_pop':
      return ['Area Director']
    default:
      return []
  }
}
