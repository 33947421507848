import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType, ISelectDataType } from '../types'
import { useBbbPnlCorporateWithoutBreakdownFilter } from './useBbbCorporateWithoutBreakdownFilter'
import { useCorporateGroupFilterQuery } from './useCorporateFilter'

const useBbbCompanyFilter = () => {
  const { brand } = useBrands()
  const directorLabel = getBusinessLabel('director')
  const { startDate, endDate } = useDateFilter()
  const {
    data: newData,
    loading,
    previousData,
  } = useCorporateGroupFilterQuery(
    {
      iBrandId: BRAND_ID[brand],
      iFilter: {
        // Fixed company location group id
        location_group_ids: [37, 38],
        brand_ids: [BRAND_ID[brand]],
      },
      iStartDate: startDate,
      iEndDate: endDate,
    },
    !startDate || !endDate,
  )
  const {
    data: corporateGroupFilterWithoutBreakdownData,
    loading: corporateGroupFilterWithoutBreakdownLoading,
  } = useBbbPnlCorporateWithoutBreakdownFilter()
  const data = newData || previousData

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const locationGroups = (
        data?.getLocationAccessGroupsForBrand?.nodes || []
      ).filter((d) =>
        [
          'Brand',
          'Company',
          'Corporate Departments',
          'District Manager',
          'Legal Entity',
        ].includes(d.type),
      )
      const companies = data.listLocationDetails.nodes.map((l) => {
        const director = data.listDirectors.nodes.find((d) =>
          l.locationGroups.some((lg) => lg.id === d.locationGroupId),
        )
        const locationGroup = locationGroups.find(
          (lg) => lg.id === director?.locationGroupId,
        )

        return {
          id: l.id,
          name: `${l.code} - ${l.name}`,
          header: 'Stores',
          link: `/${brand}/store_detail?store=${l.id}`,
          groupBy: director && {
            id: director.employeeId,
            header: locationGroup?.type || directorLabel,
            name: `${director.firstName} ${director.lastName}`,
          },
        }
      })
      const values = [
        {
          id: 'company',
          parentId: 'root',
          ids: locationGroups
            .filter((d) => d.type === 'Company')
            .map((d) => d.id),
          label: 'Company',
          list: companies,
        },
        {
          id: 'legal-entity',
          parentId: 'root',
          label: 'Legal Entity',
        },
        ...(locationGroups
          .filter((lg) => lg.type === 'Legal Entity')
          .map((lg) => ({
            id: lg.id.toString(),
            parentId: 'legal-entity',
            ids: [lg.id],
            label: lg.name,
            list: data.listLocationDetails.nodes
              .filter((l) =>
                l.locationGroups.some((lg) => lg.type === 'Legal Entity'),
              )
              .map((l) => companies.find((c) => c.id === l.id)),
          })) as ISelectDataType[]),
        {
          id: 'corporate-departments',
          parentId: 'root',
          label: 'Corporate Departments',
          ids: locationGroups
            .filter((d) => d.name === 'Corporate Departments')
            .map((d) => d.id),
          list: data.listLocationDetails.nodes
            .filter((l) =>
              l.locationGroups.some(
                (lg) => lg.name === 'Corporate Departments',
              ),
            )
            .map((l) => companies.find((c) => c.id === l.id)),
        },
        {
          id: 'all-corporate-departments',
          parentId: 'corporate-departments',
          ids: locationGroups
            .filter((d) => d.name === 'Corporate Departments')
            .map((d) => d.id),
          label: 'All Corporate Departments',
          list: data.listLocationDetails.nodes
            .filter((l) =>
              l.locationGroups.some(
                (lg) => lg.name === 'Corporate Departments',
              ),
            )
            .map((l) => companies.find((c) => c.id === l.id)),
        },
        ...(data.listLocationDetails.nodes
          .filter((l) =>
            l.locationGroups.some((lg) => lg.name === 'Corporate Departments'),
          )
          .map((l) => {
            const company = companies.find((c) => c.id === l.id)

            if (!company) return null

            return {
              id: company.id.toString(),
              parentId: 'corporate-departments',
              ids: [company.id],
              label: company.name,
            }
          }) as ISelectDataType[]),
        {
          ...corporateGroupFilterWithoutBreakdownData?.values.find(
            (v) => v.id === 'all-stores',
          ),
          id: 'stores',
          parentId: 'root',
          label: 'Stores',
        },
        ...(corporateGroupFilterWithoutBreakdownData?.values || []).map(
          ({ parentId, ...d }) => ({
            ...d,
            parentId: parentId === 'root' ? 'stores' : parentId,
          }),
        ),
      ]

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data, brand, directorLabel, corporateGroupFilterWithoutBreakdownData]),
    loading: loading || corporateGroupFilterWithoutBreakdownLoading,
  }
}

export default useBbbCompanyFilter
