import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'

type IDataType = Record<
  | 'listLocationGroupMetricValues'
  | 'priorListLocationGroupMetricValues'
  | 'yoyListLocationGroupMetricValues',
  {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
>

const query = gql`
  query listLocationGroupMetricValues(
    $iCurrentStartDate: Date!
    $iCurrentEndDate: Date!
    $iPriorStartDate: Date!
    $iPriorEndDate: Date!
    $iYoyStartDate: Date!
    $iYoyEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iCurrentStartDate
      iEndDate: $iCurrentEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        metricData
      }
    }

    priorListLocationGroupMetricValues: listLocationGroupMetricValues(
      iStartDate: $iPriorStartDate
      iEndDate: $iPriorEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        metricData
      }
    }

    yoyListLocationGroupMetricValues: listLocationGroupMetricValues(
      iStartDate: $iYoyStartDate
      iEndDate: $iYoyEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        metricData
      }
    }
  }
`

const useFetchGroupMetricValue = (metrics: string[]) => {
  const { variables } = useVariables()

  const currentPeriod = variables.date?.getInfo(0)
  const priorPeriod = variables.date?.getInfo(-1)
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iCurrentStartDate: currentPeriod?.dateRange.startDateStr,
      iCurrentEndDate: currentPeriod?.dateRange.endDateStr,
      iPriorStartDate: priorPeriod?.dateRange.startDateStr,
      iPriorEndDate: priorPeriod?.dateRange.endDateStr,
      iYoyStartDate: currentPeriod?.yoy?.dateRange?.startDateStr,
      iYoyEndDate: currentPeriod?.yoy?.dateRange?.endDateStr,
      iLocationGroupIds:
        variables.allStores?.locationGroupIds || groupFilter?.ids,
      iMetricCodes: metrics,
    },
    skip:
      !currentPeriod ||
      !priorPeriod ||
      !(variables.allStores?.locationGroupIds || groupFilter?.ids),
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      return [
        ...metrics,
        ...metrics.map((m) => `prior_${m}`),
        ...metrics.map((m) => `yoy_${m}`),
      ].reduce((result, metricCode) => {
        const type = (() => {
          if (/^yoy_/.test(metricCode)) return 'yoy' as const
          if (/^prior_/.test(metricCode)) return 'prior' as const
          return 'current' as const
        })()
        const dataKey =
          type === 'current'
            ? ('listLocationGroupMetricValues' as const)
            : (`${type}ListLocationGroupMetricValues` as const)
        const key = metricCode.replace(/^(yoy|prior)_/, '')
        let value = data?.[dataKey]?.nodes
          .map((m) => m.metricData[key]?.value)
          .filter(Boolean)
          .reduce((result, num) => result + num, 0)
        const unit = data?.[dataKey]?.nodes[0]?.metricData?.[key]?.unit || null

        if (unit === 'CENT') {
          value *= 0.01
        } else if (unit === 'PERCENTAGE') {
          value *= 100
        }

        return {
          ...result,
          [metricCode]: value,
        }
      }, {} as Record<string, number>)
    }, [data, metrics]),
    loading,
  }
}

export default useFetchGroupMetricValue
