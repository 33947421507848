import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface IPurchaseConfig {
  mainKpi: 'COGS%' | 'PLV%'
  customHeaders?: {
    [key: string]: string
  }
  isVendorVisible?: boolean
  isSummaryHidden?: boolean
  hasSummaryCategories?: boolean
}

const defaultConfig = {
  mainKpi: 'COGS%' as const,
}

const useConfig = (): IPurchaseConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'ghai_pop':
        return {
          ...defaultConfig,
          hasSummaryCategories: true,
        }
      case 'viking_bk':
        return {
          mainKpi: 'COGS%' as const,
          isVendorVisible: true,
          isSummaryHidden: true,
        }
      case 'supreme_bk':
        return {
          mainKpi: 'PLV%' as const,
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          mainKpi: 'COGS%' as const,
          customHeaders: {
            cost: 'Purchases/ Transfers',
          },
        }
      case 'bibibop':
        return {
          mainKpi: 'F&P%' as const,
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useConfig
